import BuiltSuggestion from "./BuiltSuggestion"
import EntitySearchResponse, { CompaniesResult, PeopleResult } from "./EntitySearchResponse"
import axios from '@/axios'
import { AxiosResponse } from "axios"
import { store } from "@/assets/js/store"
import { DAWAAutocompleteSuggestion } from "./DAWAAutocomplete"
import buildQueryString from "@/util/buildQueryString"
import Actions, { Action } from "./Actions"
import { DropdownMenuItem } from "@/models/dropdown-menu-items"

type Type<T, SuggestionType> = {
  name: string
  getSuggestions: (ctx: { inputText: string, caretPos: number | null, query?: { [key: string]: string }, page?: number}) => T | Promise<T | null> | null
  suggestionBuilder: (suggestion: SuggestionType) => string | BuiltSuggestion
}

export type Filter = {
  type: 'query' | 'append',
  key: string,
  label: string,
  possibleValues: { label: string, value: string | undefined, default: boolean, clearOnChange?: boolean }[]
  defaultLabel?: string
  selectType: 'radio' | 'checkbox'
  getValue: (values: string[]) => string
}

export type Types = {
  address: Type<{
    page?: number,
    totalPages?: number,
    hasNextPage?: boolean,
    results: DAWAAutocompleteSuggestion[]
  }, DAWAAutocompleteSuggestion>,
  company: Type<{
    continuationToken: string | null,
    page: number,
    totalPages: number,
    hasNextPage: boolean,
    results: CompaniesResult[]
  }, CompaniesResult>,
  person: Type<{
    continuationToken: string | null,
    page: number,
    totalPages: number,
    hasNextPage: boolean,
    results: PeopleResult[]
  }, PeopleResult>,
  companyperson: Type<{
    continuationToken: string | null,
    page: number,
    totalPages: number,
    hasNextPage: boolean,
    results: (CompaniesResult | PeopleResult)[]
  }, (CompaniesResult | PeopleResult)>,
}

export const types: Types = {
  companyperson: {
    name: 'Firma & Person',
    getSuggestions: (ctx) => searchEntities(ctx.inputText, ctx.page ?? 1, ctx.query,  'all'),
    suggestionBuilder: (suggestion) => (suggestion.entityType === 'Company' ? { text: suggestion.name, subtext: suggestion.lassoId.substring(6) } : suggestion.name)
  },
  address: {
    name: 'Adresse',
    getSuggestions: (ctx) => searchAddress(ctx),
    suggestionBuilder: (suggestion) => ({ text: suggestion.tekst, leadingIcon: 'map' })
  },
  company: {
    name: 'Firma',
    getSuggestions: (ctx) => searchEntities(ctx.inputText, ctx.page ?? 1, ctx.query,  'companies'),
    suggestionBuilder: (suggestion) => ({ text: suggestion.name, subtext: suggestion.lassoId.substring(6), leadingIcon: 'company_rounded' })
  },
  person: {
    name: 'Person',
    getSuggestions: (ctx) => searchEntities(ctx.inputText, ctx.page ?? 1, ctx.query, 'people'),
    suggestionBuilder: (suggestion) => suggestion.name
  },
}

export const searchTypes: Record<keyof Types, {
  type: Types[keyof Types],
  filters: Filter[]
}> = {
  companyperson: {
    type: types.companyperson,
    filters: [
      {
        type: 'query',
        key: 'companyStatus',
        label: 'Firmastatus',
        defaultLabel: 'Kun aktive',
        selectType: 'radio',
        possibleValues: [
          { label: 'Kun aktive', value: 'active', default: true },
          { label: 'Aktive og inaktive', value: 'all', default: false },
        ],
        getValue (values) {
          return values.join(',')
        }
      },
      {
        type: 'query',
        key: 'personStatus',
        label: 'Personstatus',
        defaultLabel: 'Kun aktive',
        selectType: 'radio',
        possibleValues: [
          { label: 'Aktive og inaktive', value: 'all', default: true },
          { label: 'Kun aktive', value: 'active', default: false },
        ],
        getValue (values) {
          return values.join(',')
        }
      },
    ]
  },
  company: {
    type: types.company,
    filters: [
      {
        type: 'query',
        key: 'companyStatus',
        label: 'Status',
        defaultLabel: 'Kun aktive',
        selectType: 'radio',
        possibleValues: [
          { label: 'Kun aktive', value: 'active', default: true },
          { label: 'Aktive og inaktive', value: 'all', default: false },
        ],
        getValue (values) {
          return values.join(',')
        }
      },
      {
        type: 'append',
        key: 'companyform',
        label: 'Virksomhedsform',
        selectType: 'checkbox',
        possibleValues: [
          { label: 'Alle', value: undefined, default: true, clearOnChange: true },
          { label: 'Aktieselskab (A/S)', value: '60', default: false, clearOnChange: false },
          { label: 'Anpartsselskab (ApS)', value: '80', default: false, clearOnChange: false },
          { label: 'Enkeltmandsvirksomhed (ENK)', value: '10', default: false, clearOnChange: false },
          { label: 'Fonde', value: '100,90', default: false, clearOnChange: false },
          { label: 'Foreninger', value: '110,150,152,115', default: false, clearOnChange: false },
          { label: 'Interessentskaber (I/S)', value: '30', default: false, clearOnChange: false },
          { label: 'Kommanditaktieselskab / Partnerselskab (K/S)', value: '70', default: false, clearOnChange: false },
          { label: 'Kommanditselskab (K/S)', value: '40', default: false, clearOnChange: false },
          { label: 'Personligt mindre Ejet Virksomhed (PMV)', value: '15', default: false, clearOnChange: false },
        ],
        getValue (values) {
          return 'form:' + values.join(',')
        }
      }
    ]
  },
  person: {
    type: types.person,
    filters: [
      {
        type: 'query',
        key: 'personStatus',
        label: 'Status',
        defaultLabel: 'Kun aktive',
        selectType: 'radio',
        possibleValues: [
          { label: 'Aktive og inaktive', value: 'all', default: true },
          { label: 'Kun aktive', value: 'active', default: false },
        ],
        getValue (values) {
          return values.join(',')
        }
      },
    ]
  },
  address: {
    type: types.address,
    filters: [

    ]
  },
}

const addressCache = new Map<string, Promise<{ results: DAWAAutocompleteSuggestion[] }>>()

export const searchAddress = async function (options: { inputText: string, caretPos: number | null, accessAddressId?: string }) {
  // const accessAddressId: string | null = store.state.accessAddressId as (string | null)
  const accessAddressId = options.accessAddressId ?? null
  const searchString = options.inputText
  const caretPos = options.caretPos ?? 0


  const cacheKey = `${searchString}_${caretPos}_${accessAddressId}`

  let promise = addressCache.get(cacheKey)

  if (!promise) {
    promise = fetch('https://api.dataforsyningen.dk/autocomplete' + buildQueryString([
      [ 'type', 'adresse' ],
      [ 'q', searchString ],
      [ 'caretpos', caretPos ],
      [ 'adgangsadresseid', accessAddressId ],
    ])).then(response => response.json().then(value => ({ results: value })))

    addressCache.set(cacheKey, promise)
  }

  return promise
}

export const searchEntities = async function<T = (CompaniesResult[] | PeopleResult[] | (CompaniesResult | PeopleResult)[])> (searchString: string, page: number,  query?: { [key: string]: string }, type: 'companies' | 'people' | 'all' = 'companies'): Promise<{ continuationToken: string | null, page: number, hasNextPage: boolean, totalPages: number, results: T }> {
  const filterType = type === 'companies' ? 'company' : type === 'all' ? 'all' : 'person'

  const resp = await axios.get<EntitySearchResponse>('/data/cvr/search?' + (new URLSearchParams({
    ...{
      pageSize: '20',
      type: filterType,
      extended: 'false',
      lookupWebsites: 'true',
      query: searchString
    },
    ...query,
    page: page?.toString(),
  }).toString())).then(response => {
    return response.data
})
  if (type === 'all') {
    const results = [ ...resp.companies.results, ...resp.people.results ]
      .sort((a,b) => b.score - a.score) as unknown as T
    return {
      continuationToken: resp.companies.continuationToken,
      page: resp.companies.page,
      totalPages: Math.max(resp.companies.totalPages, resp.people.totalPages),
      hasNextPage: resp.companies.hasNextPage || resp.people.hasNextPage,
      results,
    }
  } else {
    const results = resp[type]?.results as T
    return {
      continuationToken: resp[type].continuationToken,
      page: resp[type].page,
      totalPages: resp[type].totalPages,
      hasNextPage: resp[type].hasNextPage,
      results
    }
  }
}
