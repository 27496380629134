<script>
import Story from '@/components/news/Story.vue'
import SocialButtons from '@/components/SocialButtons'
export default {
  name: 'StoryModal',
  props: {
    story: { type: Object, required: true },
    entity: { type: Object, required: true },
    config: { type: Object, required: true }
  },
  data () {
    return {
      statstidende: []
    }
  },
  computed: {
    monitorName () {
      return this.config.notification ? this.config.notification.meta.monitorName : null
    }
  },
  components: {
    Story,
    SocialButtons
  },
  beforeMount: function () {
    this.statstidende = []
    if ([ '8.1', '8.3' ].indexOf(this.story.storyId) > -1) {
      this.$http.get(`apps/news/statstidende/${this.story.mainEntity.lassoId}`).then(res => {
        this.statstidende = res.body
      })
    }
  }
}
</script>

<template>
  <div id="storyModal">
    <div class="modal-center">
      <story
        :story="story"
        :entity="entity"
        :statstidende="statstidende"
        :monitorName="monitorName"
      />
    </div>
    <div class="modal-bottom">
      <div class="left padding-1">
        <div>
          <h4
            class="margin-bottom-1"
            v-lang.share-with-others
          />
          <social-buttons
            :url="story.url"
            :title="story.prettyHeadline"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  #storyModal {
    h4 {
      font-size: 13px;
    }
    .story-content {
      line-height: 26px;
      color: #222;
      blockquote {
        font-size: 15px !important;
        margin: 15px 0 !important;
      }
      span.statstidende {
        font-size: 15px!important;
        margin: 15px 0!important;
        padding: 10px 20px;
        border-left: 5px solid #eee;
        display: block;
      }
    }
  }
</style>
