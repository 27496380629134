<script>
import spinner from '@oldShared/lasso/vue-components/loaders/spinner.vue'
import ACLUserListItem from './ACLUserListItem.vue'
import ACLUserDropdown from './ACLUserDropdown.vue'
import BaseButton from './BaseButton.vue'
import { Users, Tags } from '@/assets/js/helpers.js'
export default {
  props: {
    tag: { type: Object },
    tagAccess: { type: Object, required: true },
    currentUserId: { type: String, required: true },
  },
  data () {
    return {
      results: [],
      isFocused: false,
      showResults: false,
      searchIsActive: false,
      loadingResults: false,
      searchValue: ''
    }
  },
  computed: {
    users () {
      return this.tagAccess.users
    },
    usersById () {
      const ids = {}
      for (const user of this.tagAccess.users) ids[user.lassoUserId] = user
      return ids
    },
    ownedByOtherUser () {
      return this.currentUserId !== this.tag.owner
    },
    tagOwnerName () {
      const ownerArray = this.tagAccess.users.filter(u => this.tag.owner === u.lassoUserId)
      return ownerArray.length === 1 ? ownerArray[0].name : this.translate('another-user')
    },
    isOwner () {
      return this.currentUserId === this.tag.owner
    },
    isSharedWithUsers () {
      return this.tagAccess.users.length > 1
    },
    getSharedSentenceOwner () {
      let value = "Listen er delt med"
      value += ` ${this.users.length - 1}`
      return this.users.length === 2 ? value + ' anden' : value + ' andre'
    },
  },
  watch: {
    isFocused: function () {
      if (this.isFocused) {
        this.showResults = !!this.searchValue
      } else {
        requestAnimationFrame(() => {
          if (!this.isFocused) {
            this.showResults = false
          }
        })
      }
    },
  },
  methods: {
    getSharedSentence (tagOwnerName) {
      let value = `${tagOwnerName} har delt denne liste med dig og`
      value += ` ${this.users.length - 1}`
      return this.users.length === 2 ? value + ' anden' : value + ' andre'
    },
    onInputElFocus () {
      if (this.searchValue) this.showResults = true
    },
    onSearchInput () {
      if (this.searchValue === '') {
        this.loadingResults = false
        this.showResults = false
        return
      }

      this.loadingResults = true
      this.showResults = true

      const searchValue = this.searchValue

      Users.search(searchValue).then(results => {
        if (this.searchValue !== searchValue) return

        this.results = []

        results.results.forEach(user => {
          if (this.results.length === 4) return

          if (!user.name) return

          const getAlreadyOnList = () => user.id in this.usersById

          this.results.push({
            id: user.id,
            name: user.name,
            email: user.email,
            get alreadyOnList () { return getAlreadyOnList() },
          })
        })

        this.loadingResults = false
      })
    },
    async addUserToACL (user) {
      if (user.alreadyOnList) return
      await Tags.updateUserACL(this.tag.id, user.id, { canView: true })
      this.$emit('tagAccessChanged')
    }
  },
  components: {
    spinner,
    ACLUserListItem,
    ACLUserDropdown,
    BaseButton,
  }
}
</script>

<template>
  <div v-if="!tagAccess">
    <spinner />
  </div>
  <div
    v-else
    class="tag-share-settings"
  >
    <!-- <div class="dropdown-description" v-lang.share-tag-title></div> -->
    <!-- eller til offentligheden via <a href="https://lasso.dk" target="_blank">lasso.dk</a> -->
    <!-- <span class="dropdown-divider"></span> -->
    <div class="setting-toggle">
      <div class="row mb-4">
        <div class="col">
          <div
            class="text-semibold"
            v-lang.share-with-organization
          />
          <span
            class="text-muted"
            v-lang.list-visible
          />
        </div>
        <div class="col-auto d-flex align-items-center">
          <ACLUserDropdown
            :tag="tag"
            type="organization"
            :tagAccess="tagAccess"
            :currentUserId="currentUserId"
            @tagAccessChanged="$emit('tagAccessChanged')"
          />
        </div>
      </div>

      <div
        class="mb-2"
        v-if="tag.canEdit"
      >
        <div class="text-semibold mb-2">
          Del med enkelte brugere
        </div>

        <div
          class="search-box mb-2"
          tabindex="-1"
          @focusin="isFocused = true"
          @focusout="isFocused = false"
        >
          <input
            ref="searchInputEl"
            type="search"
            class="input search-users-input"
            id="searchOrgUsers"
            placeholder="Tilføj personer til listen"
            autocomplete="off"
            v-model="searchValue"
            @input="onSearchInput"
            @keydown.esc="$event.target.blur()"
            @focus="onInputElFocus()"
          >

          <div
            ref="searchResultContainer"
            v-show="showResults"
            class="search-dropdown-results dropdown"
          >
            <div
              v-if="loadingResults"
              class="result static"
            >
              Søger...
            </div>

            <div
              v-else-if="results.length === 0"
              class="result static"
            >
              Ingen resultater.
            </div>

            <BaseButton
              v-for="result in results"
              :key="result.id"
              class="result"
              :disabled="result.alreadyOnList"
              @click="() => {
                $refs.searchInputEl.focus()
                showResults = false
                addUserToACL(result)
                searchValue = ''
              }"
            >
              <span class="result-name">{{ result.name }}</span>
              <span class="result-email">{{ result.alreadyOnList ? 'Allerede på listen' : result.email }}</span>
            </BaseButton>
          </div>
        </div>

        <div class="user-access-list">
          <ACLUserListItem
            v-for="user in users"
            :key="user.id"
            :tag="tag"
            :currentUserId="currentUserId"
            :user="user"
            @tagAccessChanged="$emit('tagAccessChanged')"
          />
        </div>
      </div>

      <div class="description text-muted row align-items-center">
        <i
          class="fa fa-info-circle col-auto"
          style="padding-right: 0"
        />
        <span class="col">
          <span v-if="tag.owner === currentUserId && tagAccess.organization.canView">Listen er delt med organisationen</span>
          <span v-else-if="tag.owner === currentUserId && isSharedWithUsers"><span>{{ getSharedSentenceOwner }}</span></span>
          <span v-else-if="isSharedWithUsers"><span>{{ getSharedSentence(tagOwnerName) }}</span></span>
          <span v-else-if="ownedByOtherUser"><span v-lang.shared-by-other-user="{name: tagOwnerName}" /></span>
          <span v-else>{{ translate('visible-to-you') }}</span>
        </span>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  * {
    font-size: 12px;
    line-height: 16px;
  }
  .tag-share-settings {
    .setting-toggle {
      padding: 10px 20px;

      .description {
        margin-top: 15px;
        line-height: 22px;
        font-style: italic;
      }
    }
  }

  .search-box {
    position: relative;

    .input {
      position: relative;
      width: 100%;
      height: 32px;
      padding: 8px 12px;
      border: none;
      border-radius: 2px;
      box-shadow: inset 0 0 0 1px rgba(black, 0.12);
      transition: box-shadow 100ms;

      &:focus {
        box-shadow: inset 0 0 0 2px rgba(black, 0.6);
      }
    }

    .search-dropdown-results {
      position: absolute;
      left: 0;
      top: 100%;
      max-height: 200px;
      overflow-x: hidden;
      overflow-y: auto;
      background: white;
      width: 100%;
      border-radius: 2px;
      box-shadow: 0px 2px 5px 0px rgba(0,0,0,.25);
      z-index: 2;

      .result {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 10px 12px;
        white-space: normal;

        &.static,
        .result-email {
          color: #9E9E9E;
          font-style: italic;
        }

        .result-email {
          padding-left: 16px;
        }
      }
    }
  }

  .user-access-list {
    max-height: 300px;
    padding: 0 20px;
    margin: 0 -20px;
    overflow-x: hidden;
    overflow-y: auto;
  }
</style>
