<script lang="ts">
import { Vue, Component, ProvideReactive, Prop, Inject } from 'vue-property-decorator'
import Icon from '@/components/Icon.vue'
import IconButton from '@/components/IconButton.vue'
import EntitySearchBar from '@/components/search/EntitySearchBar.vue'
import SearchBar from '@/components/search/SearchBar.vue'
import ToggleButton from '@oldShared/lasso/vue-components/ToggleButton.vue'
import BaseButton from '@/components/BaseButton.vue'
import TabView from './TabView.vue'
import { DAWAAutocompleteSuggestion, DAWAAutocompleteAddressSuggestion, createAddressFromAddressSuggestion } from '@/types/DAWAAutocomplete'
import { CompaniesResult, PeopleResult } from '@/types/EntitySearchResponse'
import { EventBus, Tags } from '@/assets/js/helpers'
import Results from '@/components/results/Results.vue'
import { types, searchTypes, Filter, Types } from '@/types/SearchTypes'
import Deferred from '@/util/deferred'
import TimeoutPromise from '@/util/timeout-promise'
import ResultType from '@/types/ResultType'
import { isDAWAResult, isCompaniesResult, isPeopleResult } from '@/util/type-guards'
import Loading from '@/components/LoadingSpinner.vue'
import DropdownButton from '@/components/dropdown/DropdownButton.vue'
import LassoSettings, { type SelectedFilter } from './util/LassoSettings'
import router from '@/assets/js/router'
import { DropdownMenuItems, DropdownMenuItem } from '@/models/dropdown-menu-items'
import App from '@/types/App'
import { actions } from '@/types/Actions'
import ActionsDialog from '@/components/actions/ActionsDialog.vue'
import { Item } from '@/models/draggable-items'
import logoSvg from '@/assets/img/svg/lasso_logo.svg?inline'
import buildQueryString from '../util/buildQueryString'
import Tab from '@/types/Tab.ts'

type GroupedActions = Record<'company' | 'person' | 'address', { id: 'visible' | 'dropdown' | 'hidden', text: 'Synlige genveje' | 'Genveje i drop-down' | 'Skjulte genveje', items: Item[] }[]>

@Component({
  components: {
    Icon,
    IconButton,
    ToggleButton,
    BaseButton,
    DropdownButton,
    EntitySearchBar,
    Loading,
    SearchBar,
    Results,
    ActionsDialog,
  }
})

export default class SearchView extends Vue {
  @ProvideReactive('lassoSettings')
  get lassoSettings (): LassoSettings { return this.$store.state.lassoSettings }

  private refs: {
    stickySearchBar: SearchBar<ResultType>
    searchBar: SearchBar<ResultType>
    stickyHeaderTriggerEl: HTMLElement
    mockResults: Results
    results: Results
  } | null = null

  //#region data
  resizeObserver: ResizeObserver | null = null
  width = 0

  logoSvg = logoSvg

  showStickyHeader = false
  stickyHeaderTriggerIntersectionObserver: IntersectionObserver | null = null

  actionsResizeObserver: ResizeObserver | null = null
  actionsElWidth = 0

  showActionsDialog = false

  privateInputText = ''
  get inputText () { return this.privateInputText }
  set inputText (value: string) { this.privateInputText = value }

  includeInactive = false

  oldSelectedFilters: Record<keyof Types, SelectedFilter[]> | null = null

  currentSelectedType: typeof searchTypes[keyof typeof searchTypes] = searchTypes.companyperson
  currentSelectedTypeKey: keyof Types = 'companyperson'

  prevResults: (CompaniesResult | PeopleResult | DAWAAutocompleteSuggestion)[] | null = null
  results: (CompaniesResult | PeopleResult | DAWAAutocompleteSuggestion)[] | null = null
  resultsPromise: Promise<void> | null = null

  lastVisitedSubResults = false

  types = searchTypes

  enabledFilters: Filter[] = []
  oldEnabledFilters: Filter[] = []

  private fields = {
    activeResultIndex: 0
  }

  subResultsCtx: { inputText: string, caretPos: number | null } | null = null

  resultPage = 0
  totalResultPages: null | number = null
  hasNextPage = false
  cToken: null | string = null
  gettingSuggestions = false
  gettingMoreSuggestions = false
  //#endregion

  //#region computed
  get size () {
    return this.width > 678 ? '678+' : '678'
  }

  get isAllowedToUseProperties () {
    return this.$store.state.apps?.find((app: any) => app.uniqueName === 'properties') !== undefined
  }

  get useLastVisited () {
    return !(this.gettingSuggestions || this.results)
  }

  get selectedFilters () {
    return this.lassoSettings.settings.portal.search.filters
  }
  set selectedFilters (value: Record<keyof Types, SelectedFilter[]>) {
    this.lassoSettings.settings.portal.search.filters = value
  }

  get resultsToShow () {
    return this.useLastVisited ? this.lastVisitedResults : this.results
  }

  get enabledFiltersIndexes () {
    return new Map(this.enabledFilters.map((item, i) => [ item, i ]))
  }

  get draggableActions () {
    const result: Record<string, any> = {}
    for (const type of [ 'company', 'person', 'address' ] as const) {

      const items: GroupedActions[typeof type] = []
      for (const category of [ { id: 'visible', text: 'Synlige genveje' }, { id: 'dropdown', text: 'Genveje i drop-down' }, { id: 'hidden', text: 'Skjulte genveje' } ] as const) {
        const draggableItems: Item[] = []
        for (const slug of this.lassoSettings.settings.portal.actionsOrder[type][category.id]) {
          const action = actions.get(slug)
          if (!action) continue
          let icon = action.icon
          if (typeof icon !== 'string') {
            icon = icon.company
          }
          draggableItems.push({ id: action.slug, text: action.name, icon, deletable: false })
        }
        items.push({ ...category, items: draggableItems })
      }

      result[type] = items
    }
    return result
  }

  get subResults () {
    return this.$store.state.subResults
  }

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  set subResults (value: boolean) {
    this.$store.state.subResults = value
  }

  get subResultsType () {
    return this.$store.state.subResultsType
  }

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  set subResultsType (value: 'company' | 'person' | 'address' | null) {
    this.$store.state.subResultsType = value
  }

  get activeResultIndex () {
    return this.fields.activeResultIndex
  }

  set activeResultIndex (value: number) {
    const min = 0
    const max = this.results ? Math.max((this.results?.length ?? 0) - 1, 0) : Math.max((this.lastVisitedResults.length ?? 0) - 1, 0)

    // if (value < min) value = max
    // if (value > max) value = min

    value = Math.min(Math.max(value, min), max)

    this.fields.activeResultIndex = value
  }

  get activeResult () {
    return this.results ? this.results[this.activeResultIndex] : this.lastVisitedResults[this.activeResultIndex]
  }

  get typesForMenu () {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const vm = this
    const result: DropdownMenuItem[] = []

    const filteredTypes: {
      [K in keyof SearchView['types']]: SearchView['types'][K] | null
    } = { ...this.types }

    // if we do not possess the properties app, remove address from types
    if (!this.isAllowedToUseProperties && !this.$store.getters.standaloneApps.find((a: App) => (
      a.uniqueName === 'properties'
      || a.uniqueName === 'riskassessment'
    ))) {
      filteredTypes.address = null
    }

    Object.entries(filteredTypes).forEach(([ k, v ]) => {
      if (!v) return

      result.push({
        label: v.type.name,
        get icon () { return vm.currentSelectedTypeKey === k ? 'check': ' ' },
        onClick: () => {
          this.onSubResultsDisabled()

          this.prevResults = null
          this.results = null
          this.resultsPromise = null
          this.onSubResultsDisabled()

          this.resultPage = 2
          this.totalResultPages = null
          this.cToken = null
          this.gettingSuggestions = false
          this.gettingMoreSuggestions = false

          this.currentSelectedTypeKey = k as ('company' | 'person' | 'address')
          this.currentSelectedType = this.types[k as ('company' | 'person' | 'address')]

          const inputEl = this.showStickyHeader
            ? this.refs?.stickySearchBar.refs?.inputEl
            : this.refs?.searchBar.refs?.inputEl

          inputEl?.focus()

          if (this.inputText) {
            this.customSearch({ inputText: this.inputText, caretPos: null }, k as ('company' | 'person' | 'address'))
          }
        }
      })
    })
    return result
  }

  get filtersForMenu () {
    const result: DropdownMenuItem[] = []

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(this.filters).forEach(([ _, v ]) => {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this
      result.push({
        label: v.label,
        get icon () {
          if (that.enabledFiltersIndexes.has(v)) return 'check_box_outline_rounded'
          return 'check_box_outline_blank_outline_rounded'
        },
        onClick: () => {
          // enable filter
          const index = this.enabledFiltersIndexes.get(v)
          if (index !== undefined) {
            this.enabledFilters.splice(index, 1)
            return
          }
          this.enabledFilters.push(v)
        },
      })
    })
    return result
  }

  get dropdownLabel () {
    return this.currentSelectedType.type.name || ''
  }

  @Inject()
  readonly tab!: Tab

  get route () { return this.tab.route }

  get suggestionBuilder () { return this.currentSelectedType.type.suggestionBuilder }

  get lastVisitedResults () {
    if (!this.lassoSettings || !this.lassoSettings.settings.portal?.lastVisited) return []
    return this.lassoSettings.settings.portal.lastVisited
  }

  get hasFilters () {
    return this.filters.length > 0
  }

  get filters () {
    return this.currentSelectedType.filters
  }
  // #endregion

  //#region methods
  async customSearch (ctx: { inputText: string, caretPos: number | null, query?: { [key: string]: string } }, type: keyof Types, changeVisibleType?: boolean) {
    this.prevResults = this.results
    this.results = null
    if (changeVisibleType) {
      this.currentSelectedTypeKey = type
      this.currentSelectedType = this.types[type]
    }
    await this.onInputChanged(ctx, type)
  }

  updateRefs () {
    this.refs = {
      stickySearchBar: this.$refs.stickySearchBar as SearchBar<ResultType>,
      searchBar: this.$refs.searchBar as SearchBar<ResultType>,
      stickyHeaderTriggerEl: this.$refs.stickyHeaderTriggerEl as HTMLElement,
      mockResults: this.$refs.mockResults as Results,
      results: this.$refs.results as Results,
    }
  }

  addDefaultFilters () {
    const hasDefaultValue = (filterValue: SelectedFilter | undefined): filterValue is SelectedFilter => {
      return !!filterValue
    }

    const value: Record<keyof Types, SelectedFilter[]> = {
      company: [],
      companyperson: [],
      person: [],
      address: [],
    }

    if (this.lassoSettings.settings.portal.search.filters) {
      for (const type of Object.keys(value) as Array<keyof Types>) {
        value[type] = this.lassoSettings.settings.portal.search.filters[type]
      }
    }

    for (const type of Object.keys(value) as Array<keyof Types>) {
      const filters = this.types[type].filters
      if (value[type].length) continue
      const defaultFilterValues = filters.filter(filter => filter.possibleValues.find(pV => pV.default)).map(filter => {
        const defaultValue = filter.possibleValues.find(value => value.default)
        if (!defaultValue) return
        const filterObj: SelectedFilter = {
          key: filter.key,
          value: defaultValue.value,
          type: filter.type,
          clearOnChange: defaultValue.clearOnChange ?? false
        }
        return filterObj
      }).filter(hasDefaultValue)

      value[type].push(...defaultFilterValues)
    }
    this.selectedFilters = value
  }

  getFilterLabel (filter: Filter): string {
    const filtered = this.selectedFilters[this.currentSelectedTypeKey].filter(i => i.key === filter.key)

    if (!filtered.length) return filter.defaultLabel ?? 'Ingen'

    if (filtered.length === 1) {
      const value = filtered[0].value
      return filter.possibleValues.find(pv => pv.value === value)?.label ?? '1 valgt'
    }

    return `${filtered.length} valgt`
  }

  filterToMenuItems (filter: Filter): DropdownMenuItems {
    const labelBuilder = (v: { label: string, value: string | undefined }) => {
      return v.label
    }
    const itemOnClick = (v: Filter['possibleValues'][number]) => {
      const filterObj = { key: filter.key, value: v.value, type: filter.type, clearOnChange: v.clearOnChange ?? false }
      const foundIdx = this.selectedFilters[this.currentSelectedTypeKey].findIndex(f => f.key === filterObj.key && f.value === filterObj.value && f.type === filterObj.type)
      const valueIdxToClear = this.selectedFilters[this.currentSelectedTypeKey].findIndex(sF => sF.clearOnChange && sF.type === filterObj.type)
      if (foundIdx !== -1) {
        this.selectedFilters[this.currentSelectedTypeKey].splice(foundIdx, 1)
        if (this.selectedFilters[this.currentSelectedTypeKey].filter(v => v.key === filter.key).length === 0) {
          const defaultValueToAdd = filter.possibleValues.find(v => v.default)
          if (defaultValueToAdd) {
            this.selectedFilters[this.currentSelectedTypeKey].push({ key: filter.key, value: defaultValueToAdd.value, type: filter.type, clearOnChange: defaultValueToAdd.clearOnChange ?? false })
          }
        }
        return
      }
      if (v.clearOnChange) {
        this.selectedFilters[this.currentSelectedTypeKey] = this.selectedFilters[this.currentSelectedTypeKey].filter(f => f.type !== filter.type)
      } else if (valueIdxToClear !== -1) {
        this.selectedFilters[this.currentSelectedTypeKey].splice(valueIdxToClear, 1)
      }
      if (filter.selectType === 'radio') {
        this.selectedFilters[this.currentSelectedTypeKey] = this.selectedFilters[this.currentSelectedTypeKey].filter(sF => sF.key !== filter.key)
      }
      this.selectedFilters[this.currentSelectedTypeKey].push(filterObj)
    }

    const that = this

    return filter.possibleValues.map(v => ({
      label: labelBuilder(v),
      get disabled () {
        if (that.currentSelectedTypeKey && that.selectedFilters[that.currentSelectedTypeKey].find(f => v.clearOnChange && f.value === v.value && f.type === filter.type)) return true
        return false
      },
      get icon () {
        if (filter.selectType === 'radio') {
          if (that.currentSelectedTypeKey && that.selectedFilters[that.currentSelectedTypeKey].find(sF => sF.value === v.value && sF.key === filter.key)) return 'radio_button_checked_outline_rounded'
          return 'radio_button_unchecked_outline_rounded'
        } else {
          if (that.currentSelectedTypeKey && that.selectedFilters[that.currentSelectedTypeKey].find(sF => sF.value === v.value && sF.key === filter.key)) return 'check_box_outline_rounded'
          return 'check_box_outline_blank_outline_rounded'
        }
      },
      closeMenuOnClick: false,
      onClick: () => itemOnClick(v),
    }))
  }

  getFilterQuery (type?: keyof Types) {
    const queryObj: { [key: string]: string } = {}
    const appendArr: string[] = []
    const filterType = type ? type : this.currentSelectedTypeKey

    this.selectedFilters[filterType].filter(sF => sF.type === 'query').forEach(f => { if (f.value) queryObj[f.key] = f.value })
    for (const filter of this.filters) {
      const values = this.selectedFilters[filterType].filter(sF => sF.type === 'append' && sF.key === filter.key).filter(sF => sF.value !== undefined).map(f => f.value)
      if (values.length === 0) continue
      appendArr.push(filter.getValue(values as string[]))
    }
    return { queryObj, appendArr }
  }

  async getTags (results: (PeopleResult | CompaniesResult)[], forceRefresh?: boolean) {
    const lassoIdsToCheck = []

    for (const result of results) {
      const lassoId = result.lassoId.toLowerCase()
      if (!(lassoId in this.$store.state.resultsTags)) {
        this.$set(this.$store.state.resultsTags, lassoId, [])
        lassoIdsToCheck.push(lassoId)
      }
    }

    if (forceRefresh) {
      lassoIdsToCheck.push(...results.map(r => r.lassoId))
    }

    if (!lassoIdsToCheck.length) return

    const lookupResult = await Tags.lookup(lassoIdsToCheck)

    type LookupResponseTags = { type: string, id: string, count: number | null, name: string, owner: string, monitors: any[], followTag: boolean }

    for (const entries of Object.entries<LookupResponseTags[]>(lookupResult)) {
      const lassoId = entries[0].toLowerCase()
      const tags = entries[1]

      const mappedTags = tags.map(t => ({ id: t.id, name: (t.followTag ? 'Overvåger' : t.name), followTag: t.followTag }))

      // take lookup response, join with existing tags and remove duplicates
      this.$store.state.resultsTags[lassoId] = mappedTags

      // this.$store.state.resultsTags.set(lassoId.toLowerCase(),
      //   [
      //     ...(this.$store.state.resultsTags.get(lassoId.toLowerCase()) ?? []),
      //     ...mappedTags
      //   ].reduce((unique, o) => {
      //     if (!unique.some((obj: LookupResponseTags) => obj.id === o.id))
      //       unique.push(o)
      //       return unique
      //     }, []
      //   )
      // )
    }

    this.$store.state.resultsTagsChangeTracker++
  }

  reloadTags () {
    const results = this.resultsToShow
    if (results) {
      this.getTags(results.filter((r): r is (CompaniesResult | PeopleResult) => 'lassoId' in r), true)
    }
  }

  /**
   * Scroll the active result into view if necessary.
   */
  scrollActiveResultIntoView () {
    if (!this.refs) return

    const scrollEl = this.$el as HTMLElement

    const { resultEls } = this.refs.results.getRefs()

    const scrollElRect = scrollEl.getBoundingClientRect()
    const scrollElTop = scrollElRect.top + 64
    const scrollElBottom = scrollElRect.bottom

    const resultEl = resultEls[this.activeResultIndex]
    const { top: resultElTop, bottom: resultElBottom } = resultEl.getBoundingClientRect()

    if (resultElTop < scrollElTop) {
      scrollEl.scrollTop -= scrollElTop - resultElTop
    } else if (resultElBottom > scrollElBottom) {
      scrollEl.scrollTop += resultElBottom - scrollElBottom
    }
  }
  //#endregion

  //#region hooks
  created () {
    this.$watch(
      () => this.resultsToShow,
      () => {
        if (this.resultsToShow) this.getTags(this.resultsToShow.filter((r): r is (CompaniesResult | PeopleResult) => 'lassoId' in r))
      },
      { immediate: true },
    )

    this.actionsResizeObserver = new ResizeObserver(entries => {
      if (entries.length) this.actionsElWidth = entries[0].contentRect.width
    })

    // When the sticky header is shown / hidden,
    // focus the appropriate search bar, if the other search bar is focused.
    this.$watch(
      () => this.showStickyHeader,
      showStickyHeader => {
        if (!this.refs) return

        const [ newSearchBar, oldSearchBar ] = (() => {
          const searchBars = [
            this.refs.searchBar,
            this.refs.stickySearchBar,
          ]

          if (showStickyHeader) searchBars.reverse()

          return searchBars
        })()

        // Stop if oldSearchBar isn't focused, or we don't have refs
        if (!oldSearchBar.focusedInput || !newSearchBar.refs || !oldSearchBar.refs) return

        const newInputEl = newSearchBar.refs.inputEl
        const oldInputEl = oldSearchBar.refs.inputEl

        // Remember selection range
        const {
          selectionStart,
          selectionEnd,
          selectionDirection,
        } = oldInputEl

        // Remember scroll position
        const { scrollTop } = this.$el

        this.$nextTick(() => {
          // Focus new input
          newInputEl.focus()

          // Apply selection range
          newInputEl.setSelectionRange(
            selectionStart,
            selectionEnd,
            selectionDirection ?? undefined,
          )

          // Apply scroll position
          this.$el.scrollTop = scrollTop
        })
      },
    )

    if(this.route?.params?.address) {
      this.inputText = this.route?.params?.address
      this.customSearch({ inputText: this.inputText, caretPos: null }, 'company')
    }
  }

  mounted () {
    // Watch width
    this.resizeObserver = new ResizeObserver(entries => {
      if (entries.length) this.width = entries[0].contentRect.width
    })

    this.resizeObserver.observe(this.$el)

    // Show / hide sticky header based on if stickyHeaderTriggerEl is in view
    this.stickyHeaderTriggerIntersectionObserver = new IntersectionObserver(entries => {
      if (!entries.length) return

      const entry = entries[0]

      // Trigger is on screen, hide sticky header.
      if (entry.isIntersecting) {
        this.showStickyHeader = false
        return
      }

      // Trigger is below, hide sticky header.
      if (entry.rootBounds && entry.boundingClientRect.bottom >= entry.rootBounds.bottom) {
        this.showStickyHeader = false
        return
      }

      this.showStickyHeader = true
    }, {
      root: this.$el,
    })

    this.$watch(
      () => this.refs?.stickyHeaderTriggerEl,
      (el, oldEl) => {
        if (el === oldEl) return

        if (oldEl) {
          this.stickyHeaderTriggerIntersectionObserver?.unobserve(oldEl)
        }

        if (el) {
          this.stickyHeaderTriggerIntersectionObserver?.observe(el)
        }
      },
    )

    this.$watch(
      () => this.refs?.mockResults.refs?.firstResultActionsEl,
      (actionsEl, oldActionsEl) => {
        if (actionsEl === oldActionsEl) return

        if (oldActionsEl) {
          this.actionsResizeObserver?.unobserve(oldActionsEl)
        }

        if (actionsEl) {
          this.actionsResizeObserver?.observe(actionsEl)
        }
      },
    )

    this.updateRefs()

    this.addDefaultFilters()

    this.$watch(() => this.selectedFilters, async () => {
      const selectedFilters = { ...this.selectedFilters }
      const oldSelectedFilters = { ...this.oldSelectedFilters }
      let hasChanged = false

      if (!oldSelectedFilters) {
        hasChanged = true
        this.oldSelectedFilters = selectedFilters
        return
      }

      for (const type of Object.keys(selectedFilters) as (keyof typeof selectedFilters)[]) {
        const arr = selectedFilters[type]
        const oldArr = oldSelectedFilters[type]
        if (arr.filter(i => !!oldArr?.find(value => value.key === i.key && value.value === i.value)).length !== oldArr?.length
          || oldArr?.filter(i => !!arr?.find(value => value.key === i.key && value.value === i.value)).length !== arr.length) hasChanged = true
      }

      if (!hasChanged) return

      const { queryObj } = this.getFilterQuery()

      this.customSearch({ inputText: this.inputText, caretPos: null, query: queryObj }, this.currentSelectedTypeKey)
      this.oldSelectedFilters = { ...this.selectedFilters }

      await this.lassoSettings.updateSettings()
    }, { deep: true })

    this.$watch(() => this.subResults, (n) => {
      if (n) return
      this.onSubResultsDisabled()
    })

    EventBus.$on('search:reloadTags', this.reloadTags)
    EventBus.$on('subResultsEnabled', this.onSubResultsEnabled)
    EventBus.$on('updateLastVisited', this.updateLastVisited)
    EventBus.$on('search', this.customSearch)
  }

  updated () {
    this.updateRefs()
  }

  beforeDestroy () {
    this.resizeObserver?.disconnect()
    this.stickyHeaderTriggerIntersectionObserver?.disconnect()
    this.actionsResizeObserver?.disconnect()

    EventBus.$off('search:reloadTags', this.reloadTags)
    EventBus.$off('subResultsEnabled', this.onSubResultsEnabled)
    EventBus.$off('updateLastVisited', this.updateLastVisited)
    EventBus.$off('search', this.customSearch)
  }
  //#endregion

  //#region event handlers
  onScroll () {
    const { scrollTop, scrollHeight, clientHeight } = this.$el
    if (scrollTop >= scrollHeight - clientHeight - 1) this.onLoadMoreResults()
  }

  onKeyDown (event: KeyboardEvent) {
    if (!(event instanceof KeyboardEvent)) return

    if (this.results?.length === 0) return

    const key = event.key || event.code

    switch (key) {
      case 'ArrowUp' :
      case 'Up' :
      case 'ArrowDown' :
      case 'Down' : {
        event.preventDefault()
        this.activeResultIndex += key.endsWith('Up') ? -1 : 1
        this.$nextTick(() => {
          this.scrollActiveResultIntoView()
        })
        return
      }

      case 'Enter' : {
        if (!this.activeResult || this.gettingSuggestions || this.gettingMoreSuggestions) return

        event.preventDefault()
        this.onResultSelected(this.activeResult)
        return
      }
    }
  }

  updateLastVisited (result: ResultType, remove?: boolean) {
    if (!isDAWAResult(result) || result.type === 'adresse') {
      if (!this.lassoSettings) return
      const lastVisited = this.lassoSettings.settings.portal.lastVisited

      const findResult = (resultObj: ResultType) => {
        if (isDAWAResult(resultObj) && isDAWAResult(result)) return resultObj.type === 'adresse' && resultObj.data.id === result.data.id
        if ((isCompaniesResult(resultObj) || isPeopleResult(resultObj)) && (isCompaniesResult(result) || isPeopleResult(result))) return resultObj.lassoId === result.lassoId
        return false
      }
      const excludeResult = (resultObj: ResultType) => {
        if (isDAWAResult(resultObj) && isDAWAResult(result)) return resultObj.type === 'adresse' && resultObj.data.id !== result.data.id
        if ((isCompaniesResult(resultObj) || isPeopleResult(resultObj)) && (isCompaniesResult(result) || isPeopleResult(result))) return resultObj.lassoId !== result.lassoId
        return false
      }

      if (!remove) {
        if (!lastVisited.find(findResult)) {
          lastVisited.unshift(result)
          lastVisited.splice(10)

          this.lassoSettings.settings.portal.lastVisited = lastVisited
          this.lassoSettings.updateSettings()
        } else {
          const temp = lastVisited.filter(excludeResult)
          temp.unshift(result)
          this.lassoSettings.settings.portal.lastVisited = temp
          this.lassoSettings.updateSettings()
        }
      } else {
        const index = lastVisited.findIndex(findResult)
        if (index > -1) {
          lastVisited.splice(index, 1)
          this.lassoSettings.settings.portal.lastVisited = lastVisited
          this.lassoSettings.updateSettings()
        }
      }
    }
  }

  onResultSelected (result: ResultType) {
    this.activeResultIndex = 0

    if (isDAWAResult(result)) {
      if (result.type === 'adresse') {
        let appToOpen = null
        if (this.$store.getters.standaloneApps.find((a: App) => a.uniqueName === 'properties') || this.isAllowedToUseProperties) {
          appToOpen = 'properties'
        } else if (this.$store.getters.standaloneApps.find((a: App) => a.uniqueName === 'riskassessment')) {
          appToOpen = 'riskassessment'
        } else return

        this.updateLastVisited(result)
        const address = createAddressFromAddressSuggestion(result)

        if (this.lastVisitedResults.length && !this.results?.length) {
          this.$store.state.activeTab.pushRoute(router.resolve({ name: 'app', params: { appParams: JSON.stringify({ address: address }), appName: appToOpen }, query: this.route?.query }).route)
        } else {
          this.$store.dispatch('openNewTabRelatively', router.resolve({ name: 'app', params: { appParams: JSON.stringify({ address: address }), appName: appToOpen }, query: this.route?.query }).route)
        }
        return
      }

      this.refs?.stickySearchBar?.updateInput(result)
      this.refs?.searchBar?.updateInput(result)
      return
    }

    if (isCompaniesResult(result) || isPeopleResult(result)) {
      this.$store.commit('addEntity', result)
      this.updateLastVisited(result)
      if (this.lastVisitedResults.length && !this.results?.length) {
        this.$store.state.activeTab.pushRoute(this.$router.resolve({ name: 'entity', params: { lassoId: result.lassoId }, query: this.route?.query }).route)
      } else {
        this.$store.dispatch('openNewTabRelatively', this.$router.resolve({ name: 'entity', params: { lassoId: result.lassoId }, query: this.route?.query }).route)
      }
    }
  }

  onSubResultsDisabled () {
    this.subResults = false
    this.subResultsType = null
    if (!this.prevResults || this.lastVisitedSubResults) {
      this.lastVisitedSubResults = false
      this.results = null
      return
    }
    this.results = this.prevResults
    this.prevResults = this.results
    this.subResultsCtx = null
  }

  onSubResultsEnabled (entity: DAWAAutocompleteAddressSuggestion) {
    if (!this.subResultsType) return

    if (!this.results || this.results.length === 0) this.lastVisitedSubResults = true

    const searchParams = []

    if (entity.data.vejnavn) searchParams.push(`street:${entity.data.vejnavn}`)
    if (entity.data.husnr) searchParams.push(`streetno:${entity.data.husnr}`)
    if (entity.data.etage) searchParams.push(`floor:${entity.data.etage}`)
    if (entity.data.dør) searchParams.push(`side:${entity.data.dør}`)
    if (entity.data.postnr) searchParams.push(`postalcode:${entity.data.postnr}`)

    const ctx = {
      inputText: searchParams.join(' '),
      caretPos: null,
    }

    this.subResultsCtx = ctx

    this.customSearch(ctx, this.subResultsType)
  }

  async onLoadMoreResults () {
    if (!this.hasNextPage || this.gettingMoreSuggestions) return

    this.gettingMoreSuggestions = true

    const { queryObj, appendArr } = this.getFilterQuery()

    if (!this.inputText && !this.subResults) {
      this.results = null
      this.gettingMoreSuggestions = false
      this.resultsPromise = null
      return
    }

    let ctx: {
      inputText: string
      caretPos: number | null
      query?: {
        [key: string]: string
      } | undefined
      page?: number
    }

    if (this.subResultsCtx) {
      ctx = {
        inputText: this.subResultsCtx.inputText,
        caretPos: this.subResultsCtx.caretPos,
        page: this.resultPage ?? 2
      }
    } else {
      ctx = {
        inputText: this.inputText,
        caretPos: null
      }
      ctx.page = this.resultPage ?? 2
      ctx.inputText = ctx.inputText + ' ' + appendArr.join(' ')
      ctx.query = queryObj
    }

    const { promise, resolve } = Deferred()

    this.resultsPromise = promise

    await TimeoutPromise(100)
    if (this.resultsPromise !== promise) return

    const entityType: (CompaniesResult | PeopleResult)['entityType'] = this.currentSelectedTypeKey === 'company'
      ? 'Company'
      : this.currentSelectedTypeKey === 'person'
        ? 'Person'
        : 'Company'

    for (let i = 0; i <= 20; i++) {
      this.results?.push({
        entityType,
      } as (CompaniesResult | PeopleResult))
    }

    const results = this.subResults && this.subResultsType ? await this.types[this.subResultsType].type.getSuggestions(ctx) : await this.currentSelectedType.type.getSuggestions(ctx)
    if (!results) {
      this.resultsPromise = null
      resolve()
      return
    }

    if (this.resultsPromise !== promise) return

    this.resultsPromise = null

    this.results = this.results?.filter(r => ('lassoId' in r)) ?? null

    this.results?.push(...results.results)
    this.resultPage++
    this.hasNextPage = results.hasNextPage === undefined ? false : results.hasNextPage

    this.gettingMoreSuggestions = false

    resolve()
  }

  async onInputChanged (data: { inputText: string, caretPos: number | null, accessAddressId?: string }, type?: keyof typeof types) {
    if (!type && this.subResults) {
      this.onSubResultsDisabled()
    }
    const { queryObj, appendArr } = type ? this.getFilterQuery(type) : this.getFilterQuery()

    if (!data.inputText) {
      this.results = null
      this.prevResults = null
      this.subResults = false
      this.subResultsType = null
      this.resultPage = 2
      this.gettingSuggestions = false
      this.resultsPromise = null
      return
    }

    const ctx: {
      inputText: string
      caretPos: number | null
      query?: {
        [key: string]: string
      } | undefined
    } = data
    ctx.inputText = ctx.inputText + ' ' + appendArr.join(' ')
    ctx.query = queryObj

    const { promise, resolve } = Deferred()

    this.resultsPromise = promise
    this.gettingSuggestions = true

    await TimeoutPromise(150)
    if (this.resultsPromise !== promise) return
    const results = type ? await this.types[type].type.getSuggestions(data) : await this.currentSelectedType.type.getSuggestions(data)

    if (!results) {
      this.resultsPromise = null
      this.results = []
      this.gettingSuggestions = false
      resolve()
      return
    }

    if (this.resultsPromise !== promise) return

    this.resultPage = 2
    this.totalResultPages = results.totalPages ?? null
    this.hasNextPage = results.hasNextPage === undefined ? false : results.hasNextPage

    this.results = results.results
    this.gettingSuggestions = false
    resolve()
    this.resultsPromise = null
  }

  onRemoveResult (result: ResultType) {
    const index = this.lastVisitedResults.findIndex((lVr => lVr === result))
    if (index > -1) this.updateLastVisited(result, true)
  }
  //#endregion
}
</script>

<template>
  <div
    :class="[
      'lassox-portal__SearchView',
      `lassox-portal__SearchView--size-${size}`,
    ]"
    tabindex="-1"
    @scroll="onScroll"
    @keydown="onKeyDown"
  >
    <div class="lassox-portal__SearchView-sticky-header-wrapper">
      <div
        :class="[
          'lassox-portal__SearchView-sticky-header',
          `lassox-portal__SearchView-sticky-header--${showStickyHeader ? 'visible' : 'hidden'}`,
        ]"
      >
        <div class="lassox-portal__SearchView-sticky-header-col-1">
          <img
            :src="logoSvg"
            alt="Lasso X Logo"
          >
        </div>

        <div class="lassox-portal__SearchView-sticky-header-col-2">
          <SearchBar
            ref="stickySearchBar"
            v-model="inputText"
            @input="onInputChanged"
          >
            <template #leading-action>
              <DropdownButton
                :label="dropdownLabel"
                :menuItems="typesForMenu"
                preferHAlign="right"
                vAlign="below"
                :borderRadiusEffect="false"
                type="flat"
              />
            </template>
          </SearchBar>
        </div>

        <div class="lassox-portal__SearchView-sticky-header-col-3" />
      </div>
    </div>

    <div
      ref="headerEl"
      :class="[
        'lassox-portal__SearchView-header',
        !(!!lastVisitedResults.length || inputText !== '') && 'lassox-portal__SearchView-header--expanded',
      ]"
    >
      <div class="lassox-portal__SearchView-header-container">
        <div class="lassox-portal__SearchView-header-logo">
          <img
            :src="logoSvg"
            alt="Lasso X Logo"
          >
        </div>

        <SearchBar
          ref="searchBar"
          autofocus
          v-model="inputText"
          @input="onInputChanged"
        >
          <template #leading-action>
            <DropdownButton
              :label="dropdownLabel"
              :menuItems="typesForMenu"
              preferHAlign="right"
              vAlign="below"
              :borderRadiusEffect="false"
              type="flat"
            />
          </template>
        </SearchBar>

        <div ref="stickyHeaderTriggerEl" />

        <div class="lassox-portal__SearchView-advancedSearch">
          <div
            v-for="(filter, idx) in filters"
            :key="idx"
            class="lassox-portal__SearchView-advancedSearch-filter"
          >
            <div
              class="lassox-portal__SearchView-advancedSearch-filter-label"
              v-text="`${filter.label}:`"
            />

            <DropdownButton
              class="lassox-portal__SearchView-advancedSearch-filter-button"
              :menuItems="filterToMenuItems(filter)"
              :label="getFilterLabel(filter)"
              type="flat"
              :borderRadiusEffect="false"
            />
          </div>
        </div>
      </div>
    </div>

    <Results
      ref="mockResults"
      :results="[ 'mock' ]"
      style="position: absolute; top: 0; left: 0; right: 0; visibility: hidden;"
    />

    <Results
      ref="results"
      :title="!results && !gettingSuggestions ? 'Seneste søgninger' : undefined"
      :results="resultsToShow"
      :expanded="!!lastVisitedResults.length || inputText !== ''"
      :loading="gettingSuggestions"
      :presetActionsDialogActiveTab="currentSelectedTypeKey"
      :activeResultIndex="activeResultIndex"
      :actionsElWidth="actionsElWidth"
      :isDeletable="useLastVisited"
      :resultType="currentSelectedTypeKey"
      @removeResult="onRemoveResult"
      @headerButtonClicked="showActionsDialog = true"
      @resultSelected="onResultSelected"
      @resultHovered="index => activeResultIndex = index"
      @changeSearchType="type => customSearch({ inputText: this.inputText }, type, true)"
    />

    <ActionsDialog
      :show="showActionsDialog"
      :groupedActions="draggableActions"
      :presetActiveTab="currentSelectedTypeKey !== 'companyperson' ? currentSelectedTypeKey : 'company'"
      @close="showActionsDialog = false"
    />
  </div>
</template>

<style lang="scss">
.lassox-portal__SearchView {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: #fff;

  &-sticky-header-wrapper {
    position: sticky;
    z-index: 1;
    top: 0;
    width: 100%;
    height: 0;
  }

  &-sticky-header {
    display: flex;
    align-items: center;
    gap: 32px;
    width: 100%;
    height: 64px;
    padding: 0 (24px + 24px);
    background-color: white;
    overflow: hidden;
    transition-property: box-shadow, transform;
    transition-duration: 150ms;
    transition-timing-function: ease-out;

    &--hidden {
      transform: translateY(-100%);
      visibility: hidden;
      transition-property: box-shadow, transform, visibility;
      transition-timing-function: ease-in, ease-in, step-end;
    }

    &--visible {
      box-shadow: 0 0 10px 0 rgba(black, 0.1);
    }

    &-col-1,
    &-col-3 {
      width: 100px;
      flex-grow: 1;
      flex-shrink: 0;
    }

    &-col-1 img {
      display: block;
      width: auto;
      height: 24px;
      margin-top: -8px;
      pointer-events: none;
    }

    &-col-2 {
      width: 100%;
      max-width: 650px;
      flex-grow: 1;
      flex-shrink: 1;
    }
  }

  &-header {
    position: relative;
    z-index: 0;
    flex-direction: column;
    width: 100%;
    flex-shrink: 0;
    flex-grow: 0;
    padding: (48px) (24px + 24px);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: flex-grow 500ms cubic-bezier(0.2, 0, 0, 1);

    &--expanded {
      flex-grow: 1;
    }

    &-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 650px;
    }

    &-logo {
      width: 240px;
      height: 60px + 32px;
      padding-bottom: 32px;
      overflow: hidden;
      pointer-events: none;

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }

  &-advancedSearch {
    display: flex;
    justify-content: center;
    gap: 16px;
    width: 100%;
    height: 12px;
    margin-top: 32px;

    &-filter {
      display: flex;
      // justify-content: center;
      align-items: center;
      min-width: 0;
      flex-grow: 0;
      flex-shrink: 1;
      flex-basis: 1;

      &-label {
        margin-right: 4px;
        color: #B1B1B1;
        font-size: 12px;
        line-height: 12px;
      }

      &-button {
        width: auto;
        min-width: 0;
        flex-grow: 0;
        flex-shrink: 1;
        margin: (12px - 32px) 0;
      }
    }
  }

  &--size-678 {
    .lassox-portal__SearchView {
      &-sticky-header {
        padding: 0 (16px + 16px);

        &-col-1,
        &-col-3 {
          display: none;
        }
      }

      &-header {
        padding: 16px + 16px;

        &-logo {
          width: 160px;
          height: (160px / 4) + 32px;
          padding-bottom: 32px;
        }
      }
    }
  }
}
</style>
