import SocialSharer from 'sharer.npm.js'
import Clipboard from 'clipboard'
import { EventBus } from '@/assets/js/helpers.js'
export default {
  focus: {
    inserted: function (el) {
      el.focus()
    }
  },
  socialSharer: {
    bind: function (el, binding) {
      el.clickHandler = function () {
        if (binding.value.sharer) el.setAttribute('data-sharer', binding.value.sharer)
        if (binding.value.title) el.setAttribute('data-title', binding.value.title)
        else el.setAttribute('data-title', document.title)
        if (binding.value.url) el.setAttribute('data-url', binding.value.url)
        else el.setAttribute('data-url', window.location.href)
        if (binding.value.subject) el.setAttribute('data-url', binding.value.subject)
        const sharer = new SocialSharer(el)
        sharer.share()
      }
      el.addEventListener('click', el.clickHandler)
    },
    unbind: function (el) {
      el.removeEventListener('click', el.clickHandler)
    }
  },
  // iframeRefresher: {
  // 	bind: function (element, binding) {
  // 		var frame = element
    //         var reload = function () {
    //             frame.src = frame.src
    //         }
    //         var interval = window.setTimeout(reload, 2000);
    //         angular.element(frame).on('load', function () {
    //             window.clearTimeout(interval);
    //         });

  // 	},
  // 	unbind: function (el) {
  // 		el.removeEventListener('click', el.clickHandler)
  // 	}
  // },
  customEvent: {
    params: [ 'label' ],
    bind: function (el, binding) {
      el.clickHandler = function () {
        EventBus.$emit('trackEvent', { event: 'customEvent', eventCategory: binding.value.category || '', eventAction: binding.value.action || '', eventLabel: binding.value.label || '', isNonInteraction: false })
      }
      el.addEventListener('click', el.clickHandler)
    },
    unbind: function (el) {
      el.removeEventListener('click', el.clickHandler)
    }
  },
  clipboard: {
    bind: function (el, binding) {
      el.clipboard = new Clipboard(el, {
        text: function () { return el.getAttribute('data-clipboard-text') }
      })
    },
    unbind: function (el) {
      el.clipboard.destroy()
      el.removeEventListener('click', el.clickHandler)
    }
  },
  eventOnVisible: {
    bind: function isElementVisible (el, binding) {
      var once = binding.modifiers.once
      var activations = 0
      var onVisibilityChanged = function () {
        if (once && activations > 0) return
        var rect = el.getBoundingClientRect()

        if (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        ) {
          activations++
          EventBus.$emit(binding.arg)
        }
      }
      if (binding.arg) {
        EventBus.$on('scrolled', onVisibilityChanged)
      }
      if (binding.modifiers.triggerOnBind) onVisibilityChanged()
    },
    unbind: function (el) {
      EventBus.$off('scrolled')
    }
  }
}
