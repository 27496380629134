<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },

    /**
     * xs/small/normal/large/larger
     */
    size: {
      type: String,
      default: 'normal',
    },

    hoverable: Boolean,
  },

  computed: {
    svg () {
      const svg = require(`@shared/icons/${this.icon}.svg`)
      if (svg.default) return svg.default
      return svg
    },
  },
}
</script>

<template>
  <div
    :class="`md-icon is-${size} ${hoverable ? 'hoverable' : ''}`"
    v-html="svg"
  />
</template>

<style lang="scss" scoped>
@import "@shared/style/global.scss";

$sizes: (
  xs: 14px,
  small: 18px,
  smallish: 20px,
  normal: 24px,
  large: 36px,
  larger: 48px,
);

.md-icon {
  display: inline-block;
  color: getTextColor('medium');
  vertical-align: top;

  &.hoverable:hover {
    color: getTextColor('light');
  }

  &::v-deep > svg {
    vertical-align: top;
    fill: currentColor;
  }

  @each $name, $size in $sizes {
    &.is-#{$name} {
      line-height: $size;

      &::v-deep > svg {
        width: $size;
        height: $size;
      }
    }
  }
}
</style>
