<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import Result from './Result.vue'
import Button from '@/components/Button.vue'
import IconButton from '@/components/IconButton.vue'
import Icon from '@/components/Icon.vue'
import ResultType from '@/types/ResultType'
import Dialog from '@/components/Dialog.vue'
import Draggables from '@/components/Draggables.vue'
import TabBar from '@/components/TabBar.vue'
import Loading from '@/components/LoadingSpinner.vue'
import { PeopleResult } from '@/types/EntitySearchResponse'

type GroupedActions = Record<'company' | 'person' | 'address', { id: 'visible' | 'dropdown' | 'hidden', text: 'Synlige genveje' | 'Genveje i drop-down' | 'Skjulte genveje', items: { id: string, text: string, tag: 'icon' | 'span' | { template: string, data?: () => Record<string, any> }, tagAttrs?: Record<string, any>, deletable: false }[] }[]>

@Component({
  components: {
    Result,
    Button,
    Icon,
    IconButton,
    Dialog,
    Draggables,
    TabBar,
    Loading,
  }
})
export default class Results extends Vue {
  @Prop({ required: true })
  readonly results!: (ResultType | 'mock')[] | null

  @Prop({ type: Boolean, default: false })
  readonly loading!: boolean

  @Prop(String)
  readonly title?: string

  @Prop({ type: Boolean, default: false })
  readonly isDeletable!: boolean

  @Prop(Boolean)
  readonly expanded?: boolean

  @Prop({ type: Boolean, default: false })
  readonly enableTransition!: boolean

  @Prop({ type: Number, default: 0 })
  readonly activeResultIndex!: number

  @Prop({ default: () => null })
  readonly actionsElWidth!: number | null

  @Prop(String)
  readonly resultType?: string

  //#region data
  refs: {
    resultsEl: HTMLElement
    resultEls: HTMLElement[]
    firstResultActionsEl: HTMLElement | null
  } | null = null

  resultActionsElWidth = 0

  sortedActions: Record<'company' | 'person' | 'address', Record<'visible' | 'dropdown' | 'hidden', string[]>> = {
    company: {
      visible: [],
      dropdown: [],
      hidden: [],
    },
    person: {
      visible: [],
      dropdown: [],
      hidden: [],
    },
    address: {
      visible: [],
      dropdown: [],
      hidden: [],
    }
  }
  groupedActions: GroupedActions | null = null

  isDraggableHovered = false
  //#endregion

  //#region computed
  get maxVisibleActions () {
    const width = this.actionsElWidth
    if (width === null) return 1

    const actionButtonWidth = 36
    const actionButtonSpacing = 16

    let maxVisibleActions = 1
    let neededWidth = actionButtonWidth
    while (width - neededWidth >= (actionButtonWidth + actionButtonSpacing)) {
      maxVisibleActions++
      neededWidth += actionButtonWidth + actionButtonSpacing
    }

    return maxVisibleActions
  }

  get subResults () {
    return this.$store.state.subResults
  }

  set subResults (value: boolean) {
    this.$store.state.subResults = value
  }

  get subResultsType () {
    return this.$store.state.subResultsType
  }

  get activeResult () {
    return this.results && this.results[this.activeResultIndex] || null
  }
  //#endregion

  //#region methods
  getRefs () {
    if (!this.refs) throw Error('getRefs() was called before refs were set!')
    return this.refs
  }

  updateRefs () {
    const resultsEl = this.$refs.resultsEl as HTMLElement
    const resultEls = [ ...resultsEl.querySelectorAll<HTMLElement>('.lassox-portal__Result') ]
    const firstResultActionsEl = resultsEl.querySelector<HTMLElement>('.lassox-portal__Result .lassox-portal__Result-actions')

    this.refs = {
      resultsEl,
      resultEls,
      firstResultActionsEl,
    }
  }

  selectResult (result: ResultType) {
    this.$emit('resultSelected', result)
  }
  //#endregion

  updated () {
    this.updateRefs()
  }

  async mounted () {
    this.updateRefs()
  }

  get introText () {
    return this.$store.state.user?.settings?.questionaire?.isSalesy
      ? null
      : this.$store.getters.isFreemium
        ? 'Tilpas Lasso til dit behov: Vælg og juster løbende på, hvilke genveje der er de rigtige for dig.'
        : 'Gør platformen til din egen ved at vælge de genveje, der er vigtigst for dig at have lige ved hånden.'
  }
}
</script>

<template>
  <div
    :class="[
      'lassox-portal__Results',
      expanded && 'lassox-portal__Results-has-results',
    ]"
  >
    <div
      v-if="!(!loading && (results && results.length === 0)) || subResults"
      class="lassox-portal__Results-header"
    >
      <span
        v-if="subResults"
        style="display: flex; align-items: center; gap: 16px"
      >
        <IconButton
          class="lassox-portal__Results-header-back-button"
          icon="arrow_back_rounded"
          size="small"
          @click="subResults = false"
        />

        <template>{{ subResultsType === 'company' ? 'Firmaer på adressen' : subResultsType === 'person' ? 'Personer på adressen' : 'Søgning på adressen' }}</template>
      </span>

      <template v-else>
        <span v-text="title || 'Resultater'" />

        <Button
          v-if="!$store.state.readOnly"
          class="lassox-portal__Results-header-button"
          type="outlined-primary"
          label="Genveje"
          data-title="Tilpas genveje"
          data-step="3"
          data-position="left"
          :data-intro="introText"
          @click="() => $emit('headerButtonClicked')"
        />
      </template>
    </div>

    <div
      ref="resultsEl"
      class="lassox-portal__Results-wrapper"
      :class="{
        'lassox-portal__Results-wrapper--loading': loading && (results && results.length),
        'lassox-portal__Results-wrapper--no-results': results && results.length === 0,
      }"
    >
      <div
        v-if="(!results || results.length === 0) && loading"
        class="lassox-portal__Results-wrapper-info"
      >
        <Loading size="56" />
        <!-- <div style="display: block; margin-top: 12px;">Henter resultater...</div> -->
      </div>

      <template v-if="!loading && expanded && results && results.length === 0">
        <div class="lassox-portal__Results-wrapper-info">
          <div class="lassox-portal__Results-wrapper-info-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 96 960 960"
              width="24"
            >
              <linearGradient
                id="lassoGradient"
                x1="0"
                y1="0.5"
                x2="1"
                y2="0.5"
              >
                <stop
                  offset="0%"
                  stop-color="#ff8191"
                />
                <stop
                  offset="33.33%"
                  stop-color="#ff8c89"
                />
                <stop
                  offset="100%"
                  stop-color="#ffa07a"
                />
              </linearGradient>
              <path
                fill="url('#lassoGradient')"
                d="M756 908 532 684q-30 24-69 38t-83 14q-109 0-184.5-75.5T120 476q0-109 75.5-184.5T380 216q109 0 184.5 75.5T640 476q0 44-14 83t-38 69l225 225q11 11 11 27t-12 28q-11 11-28 11t-28-11ZM380 656q75 0 127.5-52.5T560 476q0-75-52.5-127.5T380 296q-75 0-127.5 52.5T200 476q0 75 52.5 127.5T380 656Z"
              />
            </svg>
          </div>

          <div class="lassox-portal__Results-wrapper-info-title">Beklager, vi kunne ikke finde nogen resultater</div>

          <div class="lassox-portal__Results-wrapper-info-subtitle">
            <template v-if="resultType === 'company'">Ledte du efter en person? <a href="#" @click.stop.prevent="$emit('changeSearchType', 'person')">Klik her</a></template>
            <template v-else-if="resultType === 'person'">Ledte du efter et firma? <a href="#" @click.stop.prevent="$emit('changeSearchType', 'company')">Klik her</a></template>
            <template v-else>Prøv at søge igen</template>
          </div>
        </div>
      </template>

      <template v-if="results">
        <Result
          v-for="(result, index) in results"
          :key="index"
          :class="result === activeResult && 'lassox-portal__Result--is-active'"
          :isActive="result === activeResult"
          :result="result"
          :deletable="isDeletable"
          :maxVisibleActions="maxVisibleActions"
          @mousemove="$emit('resultHovered', index)"
          @resultSelected="selectResult"
          @onClickRemove="(result) => $emit('removeResult', result)"
        />
      </template>
    </div>
  </div>
</template>

<style lang="scss">
.lassox-portal__Results {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 0;
  min-height: 0;
  flex-shrink: 0;
  flex-grow: 0;
  opacity: 0;
  transition: opacity 250ms ease-in-out, flex-grow 500ms cubic-bezier(0.2, 0, 0, 1);

  &-header {
    flex-grow: 0;
    flex-shrink: 1;
    display: flex;
    justify-content: space-between;
    padding: 0 (24px + 24px);
    padding-bottom: 16px;
    align-items: center;

    > span {
      color: #262626;
      font-size: 17px;
      line-height: 20px;
      font-weight: 500;
    }

    &-back-button {
      transition: color 150ms ease-in-out;
      background-color: transparent;
      outline: none;
      border: none;

      &:hover {
        color: #FF8E61;
        cursor: pointer;
      }
    }

    &-button {
      // margin: (16px - 32px) (-16px);
      margin: (16px - 32px) 0;

      .lassox-portal__BaseButton_label {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }

  &-has-results {
    flex-grow: 1;
    opacity: 1;
  }

  &-wrapper {
    flex-grow: 1;
    flex-shrink: 1;
    padding: 0 24px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    transition: opacity 250ms ease-in-out;

    &--loading {
      opacity: 0.5;
    }

    &-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-bottom: 48px;
      flex-grow: 1;
      text-align: center;
      pointer-events: none;
      // animation: breathing 1s ease-in-out infinite;

      &-icon {
        display: flex;
        background-color: #F1F1F1;
        border-radius: 16px;
        padding: 8px;
        margin-bottom: 32px;

        > svg {
          width: 76px;
          height: 76px;
        }
      }

      &-title,
      &-subtitle {
        margin: -2px 0;
        font-size: 22px;
        line-height: 28px;
        a {
          text-decoration: underline;
          pointer-events: all;
        }
      }

      &-title {
        font-weight: 500;
      }

      &-title + .lassox-portal__Results-wrapper-info-subtitle {
        margin-top: 16px - 2px;
      }

      // &-subtitle {}
    }
  }
}

.lassox-portal__SearchView--size-678 .lassox-portal__Results {
  &-header {
    padding: 0 (16px + 16px);
    padding-bottom: 16px;

    &-button {
      display: none;
    }
  }

  &-wrapper {
    padding: 0 16px;

    &-info {
      padding-bottom: 16px;

      &-icon > svg {
        width: 56px;
        height: 56px;
      }

      &-title,
      &-subtitle {
        margin: -4px 0;
        font-size: 17px;
        line-height: 24px;
      }

      &-title + .lassox-portal__Results-wrapper-info-subtitle {
        margin-top: 8px - 4px;
      }
    }
  }
}

@keyframes breathing {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
</style>
