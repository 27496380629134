<script lang="ts">
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import Dialog from '@/components/Dialog.vue'
import Icon from '@/components/Icon.vue'
import TextInput from '@shared/vue-lasso-core/components/TextInput.vue'
import Button from '@/components/Button.vue'
import OnboardingQuestions from '@/components/OnboardingQuestions.vue'
import { EventBus, Users } from '@/assets/js/helpers'
import { store } from '@/assets/js/store'
import logoSvg from '@/assets/img/svg/lasso_logo.svg?inline'
import windowSize from '@/window-size'

@Component({
  components: {
    Dialog,
    Button,
    Icon,
    OnboardingQuestions,
    TextInput,
  }
})
export default class OnboardingQuestionaireModals extends Vue {
  @Prop({ type: String, required: true })
  readonly mode!: 'new-user' | 'existing-user'

  @Prop({ type: Boolean, required: true })
  readonly shouldAskUser!: boolean

  goodLead = false

  questionaireFinished = false

  phoneNumber: number | null = null

  windowSize = windowSize

  logoSvg = logoSvg

  isSalesy = false

  savePhonenumber () {
    const userId = (store.state.user as unknown as { id: string}).id
    EventBus.$emit('june:identifyUser', { id: userId, traits: { 'interestedPhoneNumber': this.phoneNumber }})
    this.$emit('hideQuestionaire')
  }

  finishQuestionaire(result: { goodLead: boolean, askForNumber: boolean }) {
    EventBus.$emit('june:trackEvent', { event: 'Q&A completed' })

    this.questionaireFinished = true
    this.goodLead = result.goodLead && result.askForNumber
    this.$emit('hideQuestionaire')
  }
}
</script>

<template>
  <div class="lassox-portal__OnboardingQuestionaireModals">
    <Dialog
      v-if="shouldAskUser"
      class="lassox-portal__OnboardingQuestionaireModals-dialog"
      size="3x"
      :dismissible="false"
    >
      <div class="lassox-portal__OnboardingQuestionaireModals-dialog-content">
        <Icon
          icon="emoji_events_outline_rounded"
          size="large"
        />
        <h1 class="title">
          Få mere ud af lasso
        </h1>
        <p vh>
          Svar på to hurtige spørgsmål og tilpas<br> Lasso til dine behov.
        </p>
        <div class="lassox-portal__OnboardingQuestionaireModals-dialog-content-actions">
          <Button
            type="flat"
            label="Ikke lige nu"
            @click="() => $emit('hideQuestionaire')"
          />
          <Button
            type="primary"
            label="Ja tak"
            @click="() => $emit('continueQuestionaire')"
          />
        </div>
      </div>
    </Dialog>

    <div
      v-else-if="!questionaireFinished"
      class="lassox-portal__OnboardingQuestionaireModals-intro"
      :class="[(mode === 'new-user' || windowSize.isMobile) && 'lassox-portal__OnboardingQuestionaireModals-intro--fullscreen' ]"
    >
      <template v-if="mode === 'new-user' || windowSize.isMobile">
        <div class="lassox-portal__Lassox-logo">
          <img
            :src="logoSvg"
            alt="Lasso X Logo"
          >
        </div>

        <OnboardingQuestions
          @finish="finishQuestionaire"
          :mode="mode"
        />
      </template>
      <Dialog
        v-else
        :dismissible="false"
        :size="800"
        class="lassox-portal__OnboardingQuestionaireModals-intro-dialog"
      >
        <template #content>
          <OnboardingQuestions
            @finish="finishQuestionaire"
            :mode="mode"
            v-model="isSalesy"
          />
        </template>
      </Dialog>
    </div>
  </div>
</template>

<style lang="scss">
.lassox-portal__OnboardingQuestionaireModals {
  &-dialog {

    &.lassox-portal__Dialog-box {
      border-radius: 16px;
    }

    &-content {
      margin: 32px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .md-icon { margin-bottom: 16px; }
      .title {
        font-size: 24px;
        line-height: 32px;
        font-weight: 500;
        color: #262626;
        margin-bottom: 28px;
        text-align: center;
      }
      p { text-align: center; }

      &-actions {
        margin-top: 32px;
        display: flex;
        gap: 8px;
      }
    }

    .lassox-portal__OnboardingQuestionaireModals-phone-number-input {
      width: 100%;
      margin-top: 32px;
      margin-bottom: 32px;
      border-radius: 8px;
      box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.06);
      height: 40px;

      .label {
        display: none;
      }

      &.is-focused .input-box {
        box-shadow: inset 0 0 0 1px #FF8E61;
      }
      .input-box{
        border-radius: 8px;
        height: 40px;
        .input, .floating-label {
          padding-top: 8px;
          padding-bottom: 8px;
          height: 24px;
        }
        .input {
          height: 24px;
          min-height: 40px;
        }
      }
    }

    .lassox-portal__OnboardingQuestionaireModals-good-lead-buttons {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      gap: 8px;
    }
  }

  .lassox-portal__OnboardingQuestionaireModals-intro--fullscreen {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: #F9F9F9;
    z-index: 99999;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;

    @media (min-width: 1280px) {
      padding: 32px 16px 64px 16px;
    }

    & > div {
      width: 730px;
      max-width: 100%;
      // flex: 1 1 auto;
      // @media (max-width: 730px) {
      //   width: 100%;
      // }
    }

    .lassox-portal__Lassox-logo {
      width: 120px;
      flex: 1 1 150px;

      padding-bottom: 16px;
      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }

}
.lassox-portal__OnboardingQuestionaireModals-intro-dialog {
 .lassox-portal__Dialog-content-inner {
    padding: 40px ;
  }
}
</style>
