import { EventBus, Tags } from '@/assets/js/helpers'
import router from '@/assets/js/router'
import { store } from '@/assets/js/store'
import copyToClipboard from '@/util/copyToClipboard'
import { isCompaniesResult, isDAWAResult, isPeopleResult } from '@/util/type-guards'
import normalizeUrl from 'normalize-url'
import { createAddressFromAddressSuggestion, DAWAAutocompleteSuggestion } from './DAWAAutocomplete'
import { CompaniesResult, PeopleResult } from './EntitySearchResponse'
import ResultType from './ResultType'
import Tab from './Tab'
import getAppIcon from '@shared/types/IconsMap'
import getAppContexts from '@shared/types/AppModuleContexts'
import App from './App'
import LassoSettings from '@/components/util/LassoSettings'
import { Store } from 'vuex'

const actionsConfig: ActionsConfig = {
  // list of appUniqueName that has standalone view types to be shown as an action
  featuredStandaloneApps: [
    'riskassessment',
    'properties',
    'benchmarking',
  ],
  // list of appUniqueName to be disabled as an action
  disabledApps: [
    'NPSToday',
    'truepropertyowners',
    'observations',
    'housingcoop',
    'cvrhistory',
    'BiQTimeline',
    'Economic',
  ],
}

const cachedActions: Map<string, Action> = new Map()

export function initializeActions () {
  const actions: Map<string, Action> = new Map()

  const standaloneApps: App[] = store.getters.standaloneApps.filter((app: App) => actionsConfig.featuredStandaloneApps.includes(app.uniqueName) && app.visible)
  const entityApps: App[] = store.getters.apps.filter((app: App) => (
    !actionsConfig.disabledApps.includes(app.uniqueName)
    && app.views.filter(v => v.type === 'entityview').length > 0
    && app.views.filter(v => v.type === 'standalone').length === 0
  ))

  standaloneApps.forEach(app => actions.set(app.uniqueName, buildAppAction(app)))
  entityApps.forEach(app => actions.set(app.uniqueName, buildAppAction(app)))

  customActions.forEach(action => { if (!action.isHidden?.()) actions.set(action.slug, action) })

  for (const [ slug, action ] of actions) {
    cachedActions.set(slug, action)
  }
}

export { cachedActions as actions }

function buildAppAction (app: App): Action {
  // get icon and context
  const icon = getAppIcon(app.uniqueName)
  const contexts = getAppContexts(app.uniqueName)
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  let handle = (entity: ResultType) => {  }

  // create tab matcher and handler function depending on contexts
  handle = (entity: ResultType) => {
    let matcher = (tab: Tab) => false
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    let handler = (tab: Tab) => { }
    if (isCompaniesResult(entity) || isPeopleResult(entity)) {
      matcher = (tab: Tab) => false
      handler = (tab: Tab) => {
        if (tab) {
          (store.state.activeTab as Tab | null) = tab
        } else {
          const appEntityView = app.views.find(aV => aV.type === 'entityview')
          let routeName = 'app'
          let params: {
            appName: string,
            appParams: string
          } | {
            lassoId: string,
            appName: string,
          } = {
            appParams: JSON.stringify({ lassoId: entity.lassoId }),
            appName: app.uniqueName,
          }
          if (appEntityView?.contexts.includes('company') || appEntityView?.contexts.includes('person')) {
            routeName = 'entity'
            params = {
              lassoId: entity.lassoId,
              appName: app.uniqueName
            }
          }
          store.dispatch('openNewTabRelatively', router.resolve({ name: routeName, params }).route)
        }
      }
    } else {
      if (entity.type === 'adresse') {
        const address = createAddressFromAddressSuggestion(entity)
        matcher = (tab: Tab) => false
        handler = (tab: Tab) => {
          if (tab) {
            (store.state.activeTab as Tab | null) = tab
          } else {
            let routeName = 'app'
            if (app.views.filter(aV => aV.type === 'standalone').length === 0) {
              routeName = 'entity'
            }
            store.dispatch('openNewTabRelatively', router.resolve({ name: routeName, params: { appParams: JSON.stringify({ address: address }), appName: app.uniqueName } }).route)
          }
        }
      }
    }
    store.dispatch('findMatchingTab', matcher).then(handler)

    if (!isDAWAResult(entity) || entity.type === 'adresse') {
      const lassoSettings = store.state.lassoSettings as LassoSettings | null
      if (!lassoSettings) return
      const lastVisited = lassoSettings.settings.portal.lastVisited

      const findResult = (resultObj: ResultType) => {
        if (isDAWAResult(resultObj) && isDAWAResult(entity)) return resultObj.type === 'adresse' && resultObj.data.id === entity.data.id
        if ((isCompaniesResult(resultObj) || isPeopleResult(resultObj)) && (isCompaniesResult(entity) || isPeopleResult(entity))) return resultObj.lassoId === entity.lassoId
        return false
      }

      if (!lastVisited.find(findResult)) {
        lastVisited.unshift(entity)
        lastVisited.splice(10)

        lassoSettings.settings.portal.lastVisited = lastVisited
        lassoSettings.updateSettings()
      }
    }

    if (!isCompaniesResult(entity) && !isPeopleResult(entity) && !isDAWAResult(entity)) {
      // eslint-disable-next-line no-console
      console.warn('Unknown entity type... no action taken')
    }
  }

  const appViewContexts = app.views.map(v => v.contexts).flat().filter(Boolean)
  const tags = []

  if (contexts && contexts.length > appViewContexts.length) {
    tags.push(...contexts)
  } else {
    tags.push(...appViewContexts)
  }

  return {
    name: app.name,
    getTitle: () => `Åbn i ${app.name}`,
    slug: app.uniqueName,
    type: 'app',
    icon,
    getIcon (entity: ResultType) {
      const icon = getAppIcon(app.uniqueName)
      if (isDAWAResult(entity)) return (typeof icon === 'string' ? icon : icon.company)
      return (typeof icon === 'string' ? icon : icon[entity.entityType.toLowerCase() as ('company' | 'person')])
    },
    tags: new Set(tags),
    handle: handle
  }
}

export type Action = {
  name: string
  getTitle: (entity: any) => string
  getIcon?: (entity: any) => string
  slug: string
  icon: string | { company: string, person: string }
  dropdownIcon?: string
  tags: Set<'company' | 'person' | 'address'>
  type: 'app' | 'function'
  getBadge?: (param: any) => string | null
  handle: (entity: any) => void
  isDisabled?: (entity: any) => boolean
  isHidden?: () => boolean
}

const customActions: Action[] = [
  {
    name: 'Overvåg',
    getTitle: (entity: CompaniesResult | PeopleResult) => `${store.state.followed && (store.state.followed as { name: string, lassoId: string }[]).find(i => i.lassoId === entity.lassoId) ? 'Stop overvågning af' : 'Overvåg'} ændringer på ${(entity.lassoId ? (entity.lassoId.startsWith('CVR-1-') ? 'firmaet' : 'personen') : 'enheden')}`,
    slug: 'monitor',
    icon: 'notification_outline_rounded',
    getIcon: (entity: CompaniesResult | PeopleResult) => {
      return (store.state.followed as { name: string, lassoId: string }[] | null)?.find(i => i.lassoId === entity.lassoId)
        ? 'notification_rounded'
        : 'notification_outline_rounded'
    },
    type: 'function',
    tags: new Set([ 'company', 'person' ]),
    handle<T extends { name: string, lassoId: string }> (entity: T) {
      if ((store.state.followed as { name: string, lassoId: string }[] | null)?.find(i => i.lassoId === entity.lassoId)) {
        store.dispatch('unfollowEntity', { lassoId: entity.lassoId, name: entity.name })
      } else {
        store.dispatch('followEntity', { lassoId: entity.lassoId, name: entity.name })
      }
    },
    isHidden: () => store.state.readOnly
  },
  {
    name: 'Kopiér CVR',
    getTitle: () => 'Kopiér CVR nummeret til udklipsholderen',
    slug: 'copy-id',
    type: 'function',
    icon: 'content_copy_rounded',
    dropdownIcon: 'content_copy_rounded',
    // content: 'CVR',
    tags: new Set([ 'company' ]),
    handle<T extends { lassoId: string }> (entity: T) {
      copyToClipboard(entity.lassoId.substring(6)).then(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (store as Store<any> & { _vm: any })._vm.$notify('CVR-nummer kopieret til udklipsholderen!')
      })
    },
  },
  {
    name: 'Åbn Hjemmeside',
    getTitle: (entity: CompaniesResult) => entity.websites?.length ? `Åbn '${normalizeUrl(entity.websites[0], { stripProtocol: true, stripWWW: true, stripHash: true })}'` : 'Ingen hjemmeside',
    slug: 'website',
    type: 'function',
    icon: 'open_in_new_rounded',
    tags: new Set([ 'company' ]),
    handle<T extends { websites: string[] | null }> (entity: T) {
      if (!entity.websites?.length) return
      window.open(normalizeUrl(entity.websites[0]), '_blank')
    },
    isDisabled<T extends { websites: string[] | null }> (entity: T) {
      return entity.websites?.length === 0
    }
  },
  {
    name: 'Personer på adressen',
    getTitle: () => 'Personer på adressen',
    slug: 'show-people',
    type: 'function',
    icon: 'person_location_outline_rounded',
    tags: new Set([ 'address' ]),
    handle<T extends DAWAAutocompleteSuggestion> (entity: T) {
      store.state.subResults = true
      ;(store.state.subResultsType as 'person' | 'company' | null) = 'person'
      EventBus.$emit('subResultsEnabled', entity)
    },
  },
  {
    name: 'Firmaer på adressen',
    getTitle: () => 'Firmaer på adressen',
    slug: 'show-companies',
    type: 'function',
    icon: 'company_location_outline_rounded',
    tags: new Set([ 'address' ]),
    handle<T extends DAWAAutocompleteSuggestion> (entity: T) {
      store.state.subResults = true
      ;(store.state.subResultsType as 'person' | 'company' | null) = 'company'
      EventBus.$emit('subResultsEnabled', entity)
    },
  },
  {
    name: 'Tilføj til lister',
    getTitle<T extends (CompaniesResult | PeopleResult)>(entity: T) {
      const resultsTags = store.state.resultsTags as Record<string, { id: string, name: string, followTag: boolean }[]>

      if (!(entity.lassoId.toLowerCase() in resultsTags) || resultsTags[entity.lassoId.toLowerCase()].length === 0) {
        return 'Findes ikke på nogen lister'
      }

      const tags = resultsTags[entity.lassoId.toLowerCase()]?.map(t => t.name)

      if (!tags) {
        return 'Kunne ikke hente lister'
      }

      const mentioned = tags.slice(0, 2).map(name => `'${name}'`)
      const theRest = tags.slice(2)
      const theRestPhrasing = theRest.length === 1 ? 'mere' : 'andre'

      return 'Findes i ' + (theRest.length === 0 ? mentioned.join(' og ') : mentioned.join(', ') + ` og ${theRest.length} ${theRestPhrasing}` )
    },
    slug: 'add-tags',
    type: 'function',
    icon: 'list_alt',
    tags: new Set([ 'company', 'person' ]),
    getBadge (entity: CompaniesResult | PeopleResult) {
      const resultsTags = store.state.resultsTags as Record<string, { id: string, name: string, followTag: boolean }[]>
      const tags = resultsTags[entity.lassoId.toLowerCase()]

      if (!tags || resultsTags[entity.lassoId.toLowerCase()].length === 0) {
        return null
      }

      return tags.length <= 9 ? tags.length.toString() : '9+'
    },
    handle<T extends (CompaniesResult | PeopleResult)>(entity: T) {
      Tags.getAll().then(() => {
        EventBus.$emit('modal', {
          modalType: 'tagManager',
          tagType: entity.entityType,
          entities: [ { lassoId: entity.lassoId, entityType: entity.entityType } ],
          onDone: () => { EventBus.$emit('search:reloadTags') },
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onCancel: () => {  }
        })
      })
    },
    isHidden: () => store.state.readOnly
  },
]
// export default Actions
export default []

// export const actionsMap = new Map(Actions.map(action => [action.slug, action]))
export const actionsMap = new Map()

type ActionsConfig = {
  featuredStandaloneApps: string[],
  disabledApps: string[],
}
