import Vue from 'vue'
import Router from 'vue-router'
import Toolbar from '@/components/Toolbar'
import Sidebar from '@/components/Sidebar'
import Home from '@/components/Home'
import HomeSimple from '@/components/HomeSimple'
import SearchView from '@/components/SearchView'
import List from '@/components/List'
import {store} from '@/assets/js/store.js'

const router = new Router({
  mode: 'abstract',
  routes: [
    {
      path: '/news',
      name: 'news',
      get components () {
        return { sidebar: Sidebar, main: Home }
      }
    },
    {
      path: '/',
      name: 'search',
      get components () {
        return { sidebar: Sidebar, main: SearchView }
      }
    },
    {
      path: '/:lassoId([a-zA-Z]+-\\d{1,2}-[^/.]+)/:appName?',
      name: 'entity',
      components: {
        sidebar: Sidebar,
      },
      beforeEnter: async (to, from, next) => {
        if (store.state.promises.initialize) await store.state.promises.initialize
        store.dispatch('getEntity', { lassoId: to.params.lassoId }).then(entity => next(), () => {
          router.app.$notify({ title: router.app.$options.filters.capitalize(router.app.translate('could-not-be-found', { entity: router.app.translate(to.params.lassoId.indexOf('CVR-1') === 0 ? 'the-company' : 'the-person') })) })
          next({ name: 'search', query: to.query }) // make sure we pass the potential query parameters along
        })
      }
    },
    {
      path: '/list/:listId',
      name: 'list',
      components: {
        sidebar: Sidebar,
        main: List
      }
    },
    {
      path: '/app/:appName/:viewName?',
      name: 'app',
      props: true,
      components: {
        sidebar: Sidebar,
      }
    },
    {
      path: '/start-trial',
      name: 'start-trial',
      components: {
        sidebar: Sidebar,
      }
    },
    {
      path: '*', redirect: '/'
    }
  ]
})

export default router
