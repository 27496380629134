<script>
export default {
  name: 'TagNameModal',
  props: {
    tag: { type: Object, required: true }
  },
  data () {
    return {
      tagName: '',
      saving: false,
      error: false
    }
  },
  methods: {
    onSave () {
      var originalName = this.tag.name
      // eslint-disable-next-line vue/no-mutating-props
      this.tag.name = this.tagName
      this.saving = true
      this.$store.dispatch('updateTag', this.tag).then(() => {
        this.$notify({ title: this.translate('list-renamed') })
        this.$emit('done')
      }, () => {
        // eslint-disable-next-line vue/no-mutating-props
        this.tag.name = originalName
        this.error = true
        this.saving = false
      })
    }
  },
  mounted () {
    this.tagName = this.tag.name
  }
}
</script>

<template>
  <div id="tagNameModal">
    <div class="modal-top">
      <h1 v-lang.edit-list-name />
      <p v-lang.edit-list-name-description />
    </div>
    <div class="modal-center">
      <div class="input-group">
        <label v-lang.list-name />
        <input
          v-focus
          v-model="tagName"
          :placeholder="translate('enter-a-name')"
        >
      </div>
    </div>
    <div class="modal-bottom right">
      <button
        class="btn btn-default"
        :disabled="saving"
        @click="$emit('cancel')"
        v-lang.cancel
      />
      <button
        class="btn btn-primary"
        :disabled="saving || tagName.length === 0 || tagName === tag.name"
        @click="onSave"
        v-lang.save
      />
    </div>
    <!-- <div class="header">{{confirmText}}</div>
    <div class="button" @click="$emit('confirm')">{{confirmButtonText}}</div>
    <div class="button" @click="$emit('cancel')" v-if="showCancelButton">{{cancelButtonText}}</div> -->
  </div>
</template>

<style lang="less" scoped>
  #tagNameModal {
  }
</style>
