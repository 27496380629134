<template>
	<dropdown :disabled="disabled" :conditionalToggle="conditionalToggle" min-width="100%" no-padding>
		<button :disabled="disabled" class="dropdown-button btn btn-outline-secondary w-100" slot="toggle" @click="refreshScrollbar"><span class="ellipsis">{{events.length === availableEvents.length ? 'Alle' : events.length == 0 ? 'Vælg' : events.length}} {{events.length == 1 ? 'hændelse' : 'hændelser'}}</span><i class="fa fa-angle-down"></i></button>
		<div v-bar class="dark vuebar" ref="scrollbar">
			<div style="max-height: 200px">
				<div style="padding: 10px 15px; white-space: nowrap; font-size: 13px;">
          <div v-if="filteredEvents.length">
            <div v-if="filteredEvents.length > 1" class="custom-control custom-checkbox" style="line-height: 2em;">
              <input :checked="allEventsAreSelected" @change="onToggleAll" type="checkbox" class="custom-control-input" :id="_uid + 'toggleAll'">
              <label class="custom-control-label text-semibold" :for="_uid + 'toggleAll'">Alle</label>
            </div>
            <div v-for="(eventGroup, i) in groupedEvents" :key="eventGroup[0].name" class="custom-control custom-checkbox padding-left-0 ellipsis" style="line-height: 2em; margin-top: 3px">
              <p :class="i == 0 ? 'margin-top-0' : 'margin-top-1'" class="margin-bottom-0 text-bold">{{eventGroups[eventGroup[0].group].title}}</p>
              <div v-for="event in eventGroup" :key="event.name" class="custom-checkbox custom-control ellipsis" style="line-height: 2em; margin-top: 3px;" @click="($event) => onEventClicked($event, event)">
                <input :disabled="disabled || (isFreemiumUser && event.isPremium)" :checked="events.indexOf(event.name) > -1" @change="() => onToggleCheckbox(event.name)" type="checkbox" class="custom-control-input" :id="_uid + event.name">
                <label class="custom-control-label" :for="_uid + event.name">{{event.title}}</label>
              </div>
            </div>
          </div>
          <span v-else>Ingen tilgængelig hændelser</span>
        </div>
			</div>
		</div>
	</dropdown>
</template>

<script>
import { groupBy, orderBy } from 'lodash'
import Dropdown from '@oldShared/lasso/vue-components/Dropdown'

export default {
	props: {
		disabled: Boolean,
    freemium: Boolean,
    conditionalToggle: { type: Boolean, default: true },
		events: {
			type: Array,
      required: true
    },
    actionType: { type: String, required: true },
    monitorType: { type: String, required: true },
    entityType: { type: String, required: true },
    availableEvents: { type: Array, required: true },
    allowedEvents: { type: Object, required: true },
  },
  data () {
    return {
      eventGroups: {
        'basicInfo': { title: 'Stamdata', order: 1 },
        'ownership': { title: 'Ejerskab', order: 2 },
        'management': { title: 'Personkreds', order: 3 },
        'employees': { title: 'Antal ansatte', order: 4 },
        'financial': { title: 'Regnskab', order: 5 },
        'news': { title: 'Nyheder', order: 6 },
        'productionUnits': { title: 'Produktionsenheder', order: 7 },
        'crawler': { title: 'Hjemmesidescanner', order: 8 },
        'properties': { title: 'Ejendomme', order: 9},
        'other': { title: 'Andet', order: 10 },
      },
    }
  },
  computed: {
    filteredEvents () {
      let events = this.availableEvents.filter(ae => this.allowedEvents[this.monitorType][this.actionType].indexOf(ae.name) > -1)
      return events.filter(e => e.supportedEntities.indexOf(this.entityType.toLowerCase()) > -1)
    },
    groupedEvents () {
      let groups = groupBy(this.filteredEvents, 'group')
      return orderBy(Object.keys(groups), g => this.eventGroups[g].order).map(k => groups[k])
    },
    allEventsAreSelected () {
      return this.filteredEvents.map(fe => fe.name.toLowerCase()).every(fe => this.events.map(e => e.toLowerCase()).indexOf(fe) > -1)
    },
    isFreemiumUser () {
      if(this.$store)
        return this.$store.getters.isFreemium
      return false
    }
  },
	methods: {
    onEventClicked (clickEvent, event) {
      if (this.isFreemiumUser && event.isPremium) this.$emit('premiumEventClicked', clickEvent)
    },
		onToggleCheckbox (eventType) {
			if (this.events.indexOf(eventType) > -1) this.events.splice(this.events.indexOf(eventType), 1)
      else this.events.push(eventType)
      this.$emit('events-updated', this.events)
		},
		refreshScrollbar () {
			window.setTimeout(() => this.$vuebar.refreshScrollbar(this.$refs.scrollbar), 0)
    },
    onToggleAll () {
      if (this.allEventsAreSelected) this.deselectAll()
      else this.selectAll()
    },
    selectAll () {
      if (this.isFreemiumUser) {
        this.events.splice(0, this.events.length, ...this.filteredEvents.filter(fe => !fe.isPremium).map(fe => fe.name))
      } else {
        this.events.splice(0, this.events.length, ...this.filteredEvents.map(fe => fe.name))
      }
      this.$emit('events-updated', this.events)
    },
    deselectAll () {
      this.events.splice(0, this.events.length)
      this.$emit('events-updated', this.events)
    }
	},
	components: {
		Dropdown
	}
}
</script>

<style lang="less">
  .notification-event-picker {
    flex: 0 1 250px;
  }
	.dropdown-button {
		background-color: #fff;
	}

	.vuebar {
    .dropdown .menu {
      max-width: 100%;
    }

    &.dragger-wide {
      >.vb-dragger {
        width: 15px;
      }
    }

    .vb-content {
      -webkit-overflow-scrolling: touch;
    }

    &.dark {
      &:hover {
        .vb-dragger-styler {
          background-color: #dfdfdf;
        }
      }

      &.vb-dragging-phantom {
        >.vb-dragger {
          >.vb-dragger-styler {
            background-color: #dfdfdf;
          }
        }
      }
    }

    &:hover {
      >.vb-dragger-styler {
        margin: 0px;
        height: 100%;
      }
    }

    >.vb-dragger {
      z-index: 5;
      width: 6px;
      right: 0;

      >.vb-dragger-styler {
        height: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transform: rotate3d(0, 0, 0, 0);
        transform: rotate3d(0, 0, 0, 0);
        -webkit-transition: background-color 100ms ease-out,
        margin 100ms ease-out,
        height 100ms ease-out;
        transition: background-color 100ms ease-out,
        margin 100ms ease-out,
        height 100ms ease-out;
        background-color: rgba(255, 255, 255, 0);
      }
    }
  }

  .premium-event {
    display: flex;
    position: absolute;
    align-items: center;
    overflow: visible;
    width: 120px;
    cursor: pointer;

    .lock-label {
      margin-left: 5px;
    }
  }
</style>
