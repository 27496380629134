<script>
import Vue from 'vue'
import windowSize from '@/window-size.ts'

export default {
  name: 'GenericNotificationModal',
  data () {
    return {}
  },
  props: {
    config: {type: Object, required: true},
  },
  computed: {
    isMobile () {
      return windowSize.width < windowSize.sizes.sm
    },
    taglineComponent: function () {
      return this.config.notification.message.tagLine && {
        name: 'news',
        template: '<span>' + Vue.filter('linkify')(this.config.notification.message.tagLine, false) + '</span>',
      }
    },
  },
}
</script>

<template>
  <div
    id="genericNotification"
    :class="{ 'isMobile': isMobile }"
  >
    <div class="modal-top">
      <p class="text-muted">
        <template
          v-if="config.notification.meta && config.notification.meta.monitorId && config.notification.meta.monitorName"
        >
          {{ config.notification.meta.monitorName }},
        </template>
        <template>{{ config.notification.timestamp | date('%d.%m.%Y kl. %H:%M') }}</template>
      </p>
      <h1 v-html="config.notification.message.headline" />
      <h2
        class="tagline padding-top-1"
        v-if="taglineComponent"
      >
        <dynamic
          :comps="[taglineComponent]"
          :emptyView="{template: '<span></span>'}"
        />
      </h2>
    </div>
    <div class="modal-center margin-top-1 text-semimuted">
      <p
        v-show="config.notification.message.content"
        v-html="config.notification.message.content"
      />
      <!-- <a v-if="config.notification.message.url" :href="config.notification.message.url">Åbn</a> -->
    </div>
  </div>
</template>

<style lang="less" scoped>
#genericNotification {
  text-align: left;

  .modal-top {
    padding-bottom: 0px;

    > p.text-muted {
      margin: 0 0 15px 0;
      font-size: 14px;
      line-height: 23px;
    }

    > h1 {
      font-size: 22px;
    }

    > h2.tagline {
      font-size: 16px;
      font-weight: 500;
      line-height: 26px;
    }

    .isMobile {
      .modal-top h1 {
        font-size: 24px !important;
      }
    }

    i {
      margin-bottom: 25px;
      font-size: 36px;
      color: #ffa07a;
    }
  }
}
</style>
