<script lang="ts">
import Vue from 'vue'
import { Component, Watch, Prop, Ref } from 'vue-property-decorator'
import Button from '@/components/Button.vue'
import IconButton from '@/components/IconButton.vue'
import Icon from '@/components/Icon.vue'
import BaseButton from '@/components/BaseButton.vue'
import { EventBus, Users } from '@/assets/js/helpers'
import { store } from '@/assets/js/store'
import TextInput from '@shared/vue-lasso-core/components/TextInput.vue'
import { AnswerButton, useCaseButtons, userTypeButtons } from '@/types/QuestionaireAnswers'

@Component({
  components: {
    Button,
    Icon,
    IconButton,
    BaseButton,
    TextInput,
  }
})

export default class OnboardingQuestions extends Vue {

  @Prop({ type: String, required: true })
  readonly mode!: 'new-user' | 'existing-user'

  @Ref('meetingContainerEl')
  readonly meetingContainerEl!: HTMLDivElement

  useCaseButtons = useCaseButtons
  userTypeButtons = userTypeButtons


  step = 1
  maxSteps = 2 // Why yes, a two-step process does feel slightly silly
  get clampStep () {
    return Math.max(1, Math.min(this.step, this.maxSteps))
  }

  goodLeadAnswers = Array(2).fill(false)
  saleslyAnswers = Array(2).fill(false)
  isSalesy = false
  bookDemoOpened = false

  showOtherInput = false

  otherAnswer = ''

  $refs!: {
    otherAnswerInput1: TextInput
    otherAnswerInput2: TextInput
  }

  clickAnswer (answerButton: AnswerButton, trackingTraitName: 'useCase' | 'userType',) {
    if (answerButton.showOtherInput) {
      this.showOtherInput = true
      this.$nextTick(() => {
        const textInput = this.step === 1 ? this.$refs.otherAnswerInput1 : this.$refs.otherAnswerInput2
        if (textInput && textInput instanceof TextInput)
          textInput.input.focus()
      })
    }
    else
      this.submitAnswer(answerButton.trackName || answerButton.label, answerButton.goodLeadAnswer, trackingTraitName, answerButton.salesy)
  }

  submitAnswer (answer: string, goodLeadAnswer: boolean, trackingTraitName: 'useCase' | 'userType', salesy?: boolean, inputAnswer?: string) {

    this.goodLeadAnswers[this.step - 1] = goodLeadAnswer
    this.saleslyAnswers[this.step - 1] = salesy ?? false

    const userId = (store.state.user as unknown as { id: string }).id
    const finalAnswer = inputAnswer ? 'Andet: ' + inputAnswer : answer
    EventBus.$emit('june:identifyUser', { id: userId, traits: { [trackingTraitName]: finalAnswer } })
    this.$store.dispatch('updateOnboardingQuestionaire', { [trackingTraitName]: answer })

    this.step++
  }

  openOneToOnePopup () {
    window.open('https://meetings-eu1.hubspot.com/kristine-lindorff/lasso-lite?embed=true', '_blank', 'popup=yes,width=880,height=700,left=200,top=5,scrollbars,toolbar=0,resizable=false')
    this.bookDemoOpened = true
    this.step++
  }

  @Watch('step')
  async onStepChanged () {
    if (this.mode === 'new-user' && this.saleslyAnswers.every(answer => answer) && !this.bookDemoOpened) {
      this.isSalesy = true
      this.maxSteps++

      this.$emit('isSalesy', this.isSalesy)
      this.$store.dispatch('updateOnboardingQuestionaire', { isSalesy: this.isSalesy, goodLead: true })
    }

    if (this.step > this.maxSteps) {
      const goodLead = this.goodLeadAnswers.every(answer => answer)
      this.isSalesy = this.saleslyAnswers.every(answer => answer)
      this.$store.dispatch('updateOnboardingQuestionaire', { goodLead, isSalesy: this.isSalesy })

      this.$emit('isSalesy', this.isSalesy)
      this.$emit('finish', { goodLead, askForNumber: !this.bookDemoOpened })
    }

    this.showOtherInput = false
    this.otherAnswer = ''
  }
}
</script>

<template>
  <div class="lassox-portal__OnboardingQuestions">
    <div style="width: 100%;">
      <div v-if="step === 1">
        <h1>
          {{ mode === 'new-user'
            ? 'Hvad regner du primært med at bruge Lasso til?'
            : 'Hvad bruger du primært Lasso til?'
          }}
        </h1>
        <div class="lassox-portal__OnboardingQuestions-long-button-container">
          <BaseButton
            v-for="answerButton in useCaseButtons"
            class="lassox-portal__OnboardingQuestions-long-button"
            :class="['showOtherInput' in answerButton && showOtherInput && 'button-selected']"
            @click="() => clickAnswer(answerButton, 'useCase')"
            :key="answerButton.label"
          >
            <Icon
              :icon="answerButton.icon"
              size="large"
              style="margin-right: 16px;"
            />
            <div>
              <p>{{ answerButton.label }}</p>
            </div>
          </BaseButton>
        </div>
        <span
          v-show="showOtherInput"
          style="margin-top: 16px;"
          class="lassox-portal__OnboardingQuestionaire-other-answer-container"
        >
          <TextInput
            ref="otherAnswerInput1"
            :value="otherAnswer"
            @input="(otherValue) => otherAnswer = otherValue"
            placeholder="Indtast"
            :floatingLabel="false"
          />
          <Button
            @click="submitAnswer('Andet: ', false, 'useCase', false, otherAnswer)"
            label="Næste"
            type="primary"
          />
        </span>
      </div>


      <template v-else-if="step === 2 || step === 3 && !isSalesy">
        <h1>{{ mode === 'new-user' ? 'Hvad beskriver bedst den sammenhæng, du skal bruge Lasso i?' : 'Hvad beskriver bedst den sammenhæng, du oftest bruger Lasso i?' }}</h1>
        <div class="lassox-portal__OnboardingQuestions-squareish-button-container">
          <BaseButton
            v-for="answerButton in userTypeButtons"
            class="lassox-portal__OnboardingQuestions-squareish-button"
            :class="['showOtherInput' in answerButton && showOtherInput && 'button-selected']"
            @click="() => clickAnswer(answerButton, 'userType')"
            :key="answerButton.label"
          >
            <Icon
              :icon="answerButton.icon"
            />
            <p>
              {{ answerButton.label }}
            </p>
          </BaseButton>
        </div>
        <span
          v-show="showOtherInput"
          class="lassox-portal__OnboardingQuestionaire-other-answer-container"
          style="margin-top: 32px;"
        >
          <TextInput
            ref="otherAnswerInput2"
            :value="otherAnswer"
            @input="(otherValue) => otherAnswer = otherValue"
            placeholder="Indtast"
            :floatingLabel="false"
          />
          <Button
            @click="submitAnswer('Andet: ', false, 'userType', false, otherAnswer)"
            label="Afslut"
            type="primary"
          />
        </span>
      </template>

      <div v-else-if="step === 3 && isSalesy">
        <div class="lassox-portal__OnboardingQuestions-step-explainer-container">
          <div class="lassox-portal__OnboardingQuestions-step-text">
            <h1>Få en personlig intro</h1>
            <br>
            <p>
              Med en personlig gennemgang af Lasso er du sikker på ikke at gå glip af nogen muligheder.
            </p>
            <br>
            <p>
              Lær blandt andet, hvordan du kan finde nye leads på tre forskellige måder, og hvordan du får fat i den rigtige kontaktinfo.
            </p>
            <br>
            <div style="display: flex; align-items: center; gap: 8px;">
              <Button
                @click="openOneToOnePopup"
                label="Book en personlig demo"
                type="primary"
              />
              <Button
                @click="() => $emit('finish', { goodLead: goodLeadAnswers.every(answer => answer), askForNumber: false })"
                label="Ikke lige nu"
                type="outlined"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="lassox-portal__OnboardingQuestions-progress-container">
      <div class="lassox-portal__OnboardingQuestions-progress-background">
        <div
          class="lassox-portal__OnboardingQuestions-progress-bar"
          :style="{ width: `${(clampStep / maxSteps) * 100}%` }"
        />
      </div>
      <p class="lassox-portal__OnboardingQuestions-progress-number">
        {{ clampStep }} af {{ maxSteps }} gennemført
      </p>
    </div>
  </div>
</template>

<style lang="scss">
@import '@shared/style/global.scss';

.lassox-portal__OnboardingQuestions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  align-items: center;

  @media (min-width: 768px) {
    text-align: left;
  }

  height: 100%;

  h1 {
    @include typography('title');
    font-size: 22px;
    margin-bottom: 24px;
  }

  .lassox-portal__OnboardingQuestions-long-button,
  .lassox-portal__OnboardingQuestions-squareish-button {
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
    justify-content: left;
    border-radius: 8px;
    background-color: white;
    text-align: left;

    white-space: wrap;
    word-wrap: break-word;

    .lassox-portal__BaseButton_leading-icon {
      width: 24px;
      height: 24px;
      color: inherit;
    }

    .lassox-portal__BaseButton_label {
      // font-size: 16px;
      text-overflow: ellipsis;
      white-space: wrap;
      text-align: center;
      margin-top: 12px;
      line-height: 20px;
    }

    .lassox-portal__BaseButton_outline {
      color: #E2E2E2;
    }

    .lassox-portal__BaseButton_overlay {
      background-color: #E2E2E2;
    }

    &:hover {
      color: #FF8E61;
      border-color: #FF8E61;

      .lassox-portal__BaseButton_outline {
        color: #FF8E61;
      }

      .lassox-portal__BaseButton_overlay {
        background-color: #FF8E61;
      }

      .md-icon {
        color: inherit;
      }
    }

    &:active .lassox-portal__BaseButton_overlay {
      opacity: 60%;
    }

    &.button-selected {
      cursor: auto;
      color: #FF8E61;
      border-color: #FF8E61;

      .lassox-portal__BaseButton_outline {
        color: #FF8E61;
      }

      .lassox-portal__BaseButton_overlay {
        background-color: #FF8E61;
      }

      .md-icon {
        color: inherit;
      }
    }
  }

  .lassox-portal__OnboardingQuestions-long-button {
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    padding: 16px;

    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0px;
    }

  }

  .lassox-portal__OnboardingQuestions-squareish-button-container {
    width: 100%;
    display: grid;
    grid-template-columns: 125px 125px;
    justify-content: center;

    @media (min-width: 768px) {
      grid-template-columns: 170px 170px 170px 170px;
    }

    gap: 16px 16px;

    @media (min-width: 768px) {
      .md-icon {
        margin-bottom: 16px;
      }
    }

    .lassox-portal__OnboardingQuestions-squareish-button {
      aspect-ratio: 1;
      padding: 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media (min-width: 768px) {
        display: block;
        padding: 32px 12px 24px 12px;
      }

      .md-icon {
        line-height: 48px;

        &>svg {
          width: 48px;
          height: 48px;
        }
      }

      @media (min-width: 768px) {
        .md-icon {
          line-height: 54px;

          &>svg {
            width: 54px;
            height: 54px;
          }
        }
      }

      // font-size: 13px;
      display: flex;
      flex-direction: column;
      text-align: center;
    }
  }


  .lassox-portal__OnboardingQuestionaire-other-answer-container {
    align-items: center;
    justify-content: center;
    gap: 32px;
    width: 100%;
    text-align: left;
    display: flex;


    .input-base {
      border-radius: 8px;
      box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.06);
      width: 100%;

      .label {
        display: none;
      }

      ::placeholder {
        color: #B7B7B7;
      }

      &.is-focused .input-box {
        box-shadow: inset 0 0 0 1px #FF8E61;
      }

      .input-box {
        border-radius: 8px;
        height: 40px;

        .input,
        .floating-label {
          padding-top: 8px;
          padding-bottom: 8px;
        }

        .input {
          min-height: 40px;
          height: 24px;
        }
      }
    }

    button {
      min-width: min-content;
    }
  }

  .lassox-portal__OnboardingQuestions-step-explainer-container {
    border-radius: 8px;
    border: 1px solid #E2E2E2;
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.08);
    background-color: #FFF;
    padding: 32px;

    &>p {
      @include typography('body');
    }
  }

  .lassox-portal__OnboardingQuestions-progress-container {
    width: 100%;
    padding-top: 16px;

    @media (min-width: 1024px) {
      padding-top: 40px;
    }

    .lassox-portal__OnboardingQuestions-progress-background {
      position: relative;
      background-color: #F1F1F1;
      height: 8px;
      border-radius: 1000px;
    }

    .lassox-portal__OnboardingQuestions-progress-bar {
      position: absolute;
      height: 8px;
      background: linear-gradient(90deg, #FF8191 0%, #FFA07A 100%);
      border-radius: 1000px;
    }

    .lassox-portal__OnboardingQuestions-progress-number {
      margin-top: 10px;
      color: #B7B7B7;
      font-size: 11px;
      line-height: 16px;
      text-align: right;
    }
  }
}
</style>