<script lang="ts">
import { Vue, Component, Prop, Ref, InjectReactive } from 'vue-property-decorator'
import Icon from '@/components/Icon.vue'
import DropdownButton from '@/components/dropdown/DropdownButton.vue'
import ResultType from '@/types/ResultType'
import axios from '@/axios'
import { isCompaniesResult, isDAWAResult, isPeopleResult } from '@/util/type-guards'
import LassoSettings from '../util/LassoSettings'
import normalizeUrl from 'normalize-url'
import { capitalize } from 'lodash'
import App from '@/types/App'
import Actions from '@/components/actions/Actions.vue'
import { apiUrl } from '@/assets/js/helpers'
import { CompaniesResult } from '@/types/EntitySearchResponse'
import IconButton from '../IconButton.vue'
import WebsiteFavicons from '@/util/website-favicons'

@Component({
  components: {
    Icon,
    DropdownButton,
    Actions,
    IconButton,
  }
})
export default class Result extends Vue {
  @Prop({ required: true })
  readonly result!: ResultType | 'mock'

  @Prop(Boolean)
  readonly isActive!: boolean

  @Prop({ type: Number, default: 0 })
  readonly maxVisibleActions!: number

  @Prop({ type: Boolean, default: false })
  readonly deletable!: boolean

  @Ref('resultIcon')
  readonly resultIcon!: HTMLImageElement

  @Ref('actionsEl')
  readonly actionsEl!: HTMLDivElement

  @InjectReactive()
  readonly lassoSettings!: LassoSettings

  get finalResult (): ResultType {
    if (this.result === 'mock') {
      const mockCompany: CompaniesResult = {
        address1: '',
        city: null,
        country: '',
        email: null,
        entityType: 'Company',
        lassoId: '',
        name: '',
        phoneNumber: null,
        postalCode: null,
        score: 0,
        status: 'Aktiv',
        website: null,
      }
      return mockCompany
    }

    return this.result
  }

  get companiesResult () { return isCompaniesResult(this.finalResult) ? this.finalResult : null }
  get peopleResult () { return !this.companiesResult && isPeopleResult(this.finalResult) ? this.finalResult : null }
  get DAWAResult () { return !this.peopleResult && isDAWAResult(this.finalResult) ? this.finalResult : null }

  hasImage = false
  gettingImage = false
  showImage = true
  hovered = false
  leadingIconHovered = false
  imgSrc: string | null = null

  get showActions () {
    // return false
    return this.DAWAResult ? this.DAWAResult.type === 'adresse' : true
  }

  get showArrow () {
    // return !(isDAWAResult(this.result) ? this.result.type === 'adresse' : true)
    return !this.showActions
  }

  get type () {
    if (this.companiesResult) return 'company'
    if (this.peopleResult) return 'person'
    if (this.DAWAResult) return 'address'
    return null
  }

  get title () {
    if (this.companiesResult) return this.companiesResult.name ?? 'Enhed uden navn'
    if (this.peopleResult) return this.peopleResult.name ?? 'Enhed uden navn'
    if (this.DAWAResult) return this.DAWAResult.type === 'adgangsadresse' ? this.DAWAResult.forslagstekst : this.DAWAResult.tekst
    return 'Ingen titel'
  }

  get loading () {
    const result = this.companiesResult || this.peopleResult
    if (result) {
      return !('lassoId' in result)
    }
    return false
  }

  get icon () {
    const result: { tag: 'icon' | 'img', attrs: Record<string, string> } = {
      tag: 'icon',
      attrs: { icon: 'search', size: 'large' }
    }

    if (this.DAWAResult) {
      result.attrs = {
        icon: 'place_rounded',
        size: 'large'
      }
    } else if (this.companiesResult) {
      if (!this.gettingImage && this.hasImage && this.imgSrc) {
        result.tag = 'img'
        result.attrs = {
          src: this.imgSrc,
          alt: `${this.companiesResult.name} logo`
        }
      } else {
        result.attrs = {
          icon: 'work_outline_rounded',
          size: 'large'
        }
      }
    } else if (this.peopleResult) {
      result.attrs = {
        icon: 'person_rounded',
        size: 'large'
      }
    }

    return result
  }

  get subtext () {
    if (this.DAWAResult) return capitalize(this.DAWAResult.type)

    const companyOrPersonResult = this.companiesResult || this.peopleResult
    if (companyOrPersonResult && companyOrPersonResult.address1) {
      const validFromIndex = companyOrPersonResult.address1.indexOf('Valid from:')
      const {
        address1,
        postalCode,
        city,
      } = companyOrPersonResult

      let address = validFromIndex > -1
        ? address1.substring(0, validFromIndex - 1)
        : address1
      address = address.trim()
      address = address.replaceAll(/\r?\n/g, ', ')
      address = address.replaceAll(/((^|,)[\s]*null([\s]+|(?=,|$)))/gm, '')
      if (postalCode) address = address + `, ${postalCode}`
      if (city) address = address + ` ${city}`

      return address
    }

    // return `ID: ${this.result.lassoId.substring(6)}`
    return 'Ingen adresse i CVR'
  }

  get isClickable () {
    if (this.loading) return false
    if (this.DAWAResult) {
      if (!this.$store.getters.standaloneApps.find((a: App) => (
        a.uniqueName === 'properties'
        || a.uniqueName === 'riskassessment'
      )) && !this.$store.getters.appFromSolution('properties')) return false
    }
    return true
  }

  onClickRemove () {
    this.$emit('onClickRemove', this.result)
  }

  resultClicked () {
    if (!this.isClickable) return
    this.$emit('resultSelected', this.finalResult)
  }

  async getValidImageSrc () {
    if (this.companiesResult?.websites && Array.isArray(this.companiesResult?.websites)) {
      this.gettingImage = true
      const imgSrc = await WebsiteFavicons.getFavicon(this.companiesResult)
      if (imgSrc) {
        this.imgSrc = imgSrc
      } else {
        this.imgSrc = 'undefined'
      }
      this.gettingImage = false
    } else {
      this.imgSrc = 'undefined'
    }
  }

  resultImageFailed () {
    this.showImage = false
  }

  async created () {
    await this.getValidImageSrc()

    this.$watch(() => this.imgSrc, (src) => {
      // todo - we set src to 'undefined' as a string due to `undefined` not being reactive in Vue 2. This logic could be improved
      if (src === 'undefined' || src === null) this.hasImage = false
      else this.hasImage = true
    })

    this.$watch(() => this.companiesResult, async () => {
      await this.getValidImageSrc()
    })
  }
}
</script>

<template>
  <div
    class="lassox-portal__Result"
    :class="{
      'lassox-portal__Result-clickable': isClickable,
      'lassox-portal__Result-loading': loading,
    }"
    @click="() => isClickable && resultClicked()"
    @mousemove="(event) => $emit('mousemove', event)"
    @mouseenter="hovered = true"
    @mouseleave="hovered = false"
  >
    <div
      class="lassox-portal__Result-leading-icon"
    >
      <IconButton
        v-if="deletable && hovered"
        class="lassox-portal__Result-clear-icon"
        icon="close_rounded"
        size="normal"
        buttonColor="peach"
        v-tooltip="'Fjern fra \'sidst besøgt\''"
        @click.stop.prevent="onClickRemove"
      />

      <template v-else>
        <img
          class="lassox-portal__Result-icon"
          v-if="!gettingImage && (imgSrc !== null && imgSrc !== 'undefined')"
          ref="resultIcon"
          :src="imgSrc"
          @error="resultImageFailed"
          alt=""
        >

        <component
          class="lassox-portal__Result-icon"
          v-else
          :is="icon.tag"
          v-bind="icon.attrs"
        />
      </template>
    </div>

    <div class="lassox-portal__Result-content">
      <p
        class="lassox-portal__Result-content-title"
        v-text="title"
      />

      <p
        class="lassox-portal__Result-content-subtitle"
        v-text="subtext"
      />
    </div>

    <div
      v-if="showActions && !loading"
      class="lassox-portal__Result-actions"
    >
      <Actions
        :result="finalResult"
        :type="type"
        :maxVisibleActions="maxVisibleActions"
      />
    </div>

    <div
      v-else-if="showArrow"
      class="lassox-portal__Result-trailing"
    >
      <Icon
        v-show="isActive"
        icon="arrow_forward_ios_outline_rounded"
        size="small"
      />
    </div>
  </div>
</template>

<style lang="scss">
.lassox-portal__Result {
  display: flex;
  align-items: center;
  height: 80px;
  width: 100%;
  padding: 0 24px;
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: 8px;
  overflow: hidden;
  background: white;

  &--is-active {
    // background: #FFECE4;
    background: rgba(255, 160, 122, 0.1);

    .lassox-portal__Result-leading-icon {

      .lassox-portal__IconButton .md-icon,
      .lassox-portal__Result-icon {
        color: #FF8E61;
      }
    }

    .lassox-portal__Result-actions {

      .lassox-portal__Actions-action-badge {
        background: #FF8E61;
      }

      .lassox-portal__Actions-action,
      .lassox-portal__Actions-action-dropdown {
        border: 1px solid #FF8E61;

        .md-icon {
          color: #FF8E61;
        }
      }
    }
  }

  &-clickable {
    cursor: pointer;
  }

  &-loading {
    .lassox-portal__Result-content {
      &-title,
      &-subtitle {
        display: inline-block;
        color: transparent;
        pointer-events: none;
        user-select: none;
        position: relative;
        width: 50%;

        &::after {
          content: '';
          position: absolute;
          display: block;
          top: calc(50% - 0.3em);
          left: 0;
          right: 0;
          height: 100%;
          border-radius: 2px;
          background-image: linear-gradient(
            90deg,
            #eeeeee calc(50% - 16px),
            #e0e0e0 50%,
            #eeeeee calc(50% + 16px)
          );
          background-size: calc(200% + 32px) 100%;
          background-position-x: 0%;
          animation: ghost 1.25s linear infinite;
        }
      }

      &-subtitle {
        width: 35%;
      }
    }
  }

  &-leading-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    flex: 0 0 36px;
    position: relative;

    > * {
      width: 100%;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    flex-wrap: wrap;
    flex-grow: 0;
    width: 60%;
    min-width: 0;
    padding: 0 24px;

    &-title,
    &-subtitle {
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &::before {
        content: '';
      }
    }

    &-title {
      color: #262626;
      font-size: 17px;
      line-height: 20px;
      font-weight: 500;
    }

    &-subtitle {
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
    }

    p {
      margin: 0;

      & + p {
        margin-top: 8px;
      }
    }
  }

  &-actions,
  &-trailing {
    flex: 1 1 auto;
    display: inline-flex;
    justify-content: flex-end;
    min-width: 0;
    width: 40%;
  }

  @keyframes ghost {
    0% {
      background-position-x: 100%
    }
    100% {
      background-position-x: 0%;
    }
  }
}

.lassox-portal__SearchView--size-678 .lassox-portal__Result {
  height: 72px;
  padding: 0 16px;

  &-content {
    flex-grow: 1;
    padding: 0 16px;
  }

  &-actions,
  &-trailing {
    width: 36px;
    flex: 0 0 auto;
  }
}
</style>
