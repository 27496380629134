import Vue from 'vue'
import Vuex from 'vuex'
import appHistory from '@/history.ts'

Vue.use(Vuex)

import { captureEvent, configureScope, captureException } from '@sentry/browser'
import io from 'socket.io-client'
import { Tags, Entities, Users, Story, DataExports, Notifications, EventBus, Organization, PEP, WebsiteScanner, GenerateRandomString, Exports } from '@/assets/js/helpers'
import { orderBy, find, uniqBy } from 'lodash'
import { signOut, isWorkingHours, apiUrl } from './helpers'
import Auth from './auth'
import { createTab } from '@/types/Tab.ts'
import { diffInDays, diffInMinutes } from '@/util/time-utils.ts'
import LassoSettings from '@/components/util/LassoSettings.ts'
import appStore from '@/stores/app'
import EnvironmentUtils from '@shared/utilities/environment-utils.ts'
import router from './router'

const getDefaultState = () => ({
  availableOrganizations: [],
  loading: true,
  loadingMessage: null,
  lastLogin: localStorage.getItem('lastLogin'),
  entities: {},
  tags: null,
  news: {},
  followed: null,
  gettingExports: false,
  gettingNotifications: false,
  noMoreNotifications: false,
  updates: {},
  completedJobs: {},
  websiteScans: {},
  //#region User and claims. Claims are extracted from the user token.
  /** @type {import('../../types/User').User} */
  user: null,
  claims: {},
  readOnly: window.readOnly ? true : false,
  direct: (window.directLink || document.location.search.indexOf('direct') > -1) ? true : false,
  productAccess: window.productAccess ? true : false,
  impersonating: window.impersonating,
  //#endregion
  features: [],
  notifications: [],
  apps: null,
  exports: null,
  socket: null,
  sidebarCollapsed: localStorage.getItem('portal/sidebar/pinned') === 'false' ? true : false,
  sidebarPinned: localStorage.getItem('portal/sidebar/pinned') === 'false' ? false : true,
  modulesOpen: localStorage.getItem('modulesOpen') === 'false' ? false : true,
  uniqueSessionIdentifier: localStorage.getItem('uniqueSessionIdentifier') || GenerateRandomString(),
  userBrowserId: null,
  showingSignedInElsewhereDialogue: false,
  maxListSize: 20000,
  lockedByModal: false,
  pep: {},
  accessAddressId: null,
  promises: {
    initialize: null,
    tags: null,
    exports: null,
    availableOrganizations: null,
    followed: null,
  },
  /** @type {Tab | null} */
  activeTab: null,
  tabs: [],
  /** @type {import('../../components/util/LassoSettings').default | null} */
  lassoSettings: null,
  actionsElWidth: null,
  resultsTags: {},
  resultsTagsChangeTracker: 0,
  subResults: false,
  subResultsType: null
})

export const store = new Vuex.Store({
  state: getDefaultState(),
  getters: {
    actionsElWidth: state => {
      return state.actionsElWidth
    },
    apps: state => {
      if (state.apps) {
        var apps = [...state.apps]
        if (state.user) {
          const lassoAdmin = state.user.roles.indexOf('LassoAdmin') > -1
          const admin = state.user.roles.indexOf('Admin') > -1
          const adminOrg = [
            '2b1dec90-d6b1-4e90-94a4-c8e4a0bdc433', // lasso
            '3074087e-2bae-4cfe-b8c5-35bda1be3626', // lasso salgsteam
            'd3660b80-bb66-43fe-af20-429408608f07', // nps
            '7fd3d598-3aee-4ebd-bfd4-4ebfc664d143', // lasso staging
          ].indexOf(state.user.lassoOrganizationId) > -1
          if (lassoAdmin || (admin && adminOrg)) apps.push({ name: 'Lasso for partnere', uniqueName: 'lassopartner', views: [ { type: 'standalone' } ], visible: true } )
          if (lassoAdmin || (admin && adminOrg)) apps.push({ name: 'Lasso for salg', uniqueName: 'lassosales', views: [ { type: 'standalone' } ], visible: true } )
          apps.push({ name: 'Profil og notifikationer', uniqueName: 'lassousersettings', views: [ { type: '' } ], visible: true } )
          apps.push({ name: 'Kontoindstillinger', uniqueName: 'lassosettings', views: [ { type: '' } ], visible: true } )
        }

        apps = apps.filter(a => a.visible && !a.disabled)

        // hide Risikovurdering if the user does not have the associated role "Risikovurdering"
        if (apps.some(a => a.uniqueName === 'riskassessment') && state.user && !state.user.roles.includes('Risikovurdering')) {
          apps = apps.filter(a => a.uniqueName !== 'riskassessment')
        }
        return orderBy(apps, [ 'name' ])
      } else return []
    },
    standaloneApps (state, getters) {
      return uniqBy(getters.apps.filter((app) => app.views.some(v => v.type === 'standalone')), a => a.uniqueName)
    },
    app (state, getters) {
      return appName => find(getters.apps, a => a.uniqueName.toLowerCase() === appName.toLowerCase())
    },
    appFromSolution (state, getters) {
      return appName => getters.apps.find(version => version.uniqueName.toLowerCase() === appName.toLowerCase())
    },
    tag (state) {
      return tagId => {
        return state.tags ? find(state.tags, t => t.id === tagId) || null : null
      }
    },
    entity (state) {
      return lassoId => {
        return state.entities[lassoId] || null
      }
    },
    tags (state) {
      return orderBy(state.tags, [ tag => tag.name.toLowerCase() ])
    },
    followed (state) {
      return !state.followed ? null : state.followed.map(f => f.lassoId)
    },
    news (state) {
      return cacheKey => {
        return state.news[cacheKey] || null
      }
    },
    notifications (state) {
      var lastCheck = state.user ? (new Date(state.user.settings.lastNotificationCheck || null)) : 0
      return state.notifications.map(n => ({
        ...n,
        unread: Date.parse(n.time) > lastCheck
      }))
    },
    websiteScans (state) {
      var lastCheck = state.user ? (new Date(state.user.settings.lastNotificationCheck || null)) : 0
      return orderBy(Object.values(state.websiteScans).map(scan => ({
        ...scan,
        unread: Date.parse(scan.timestamp) > lastCheck
      })), 'timestamp', 'desc')
    },
    updates (state) {
      var lastCheck = state.user ? (new Date(state.user.settings.lastNotificationCheck || null)) : 0
      return Object.values(state.updates).map(n => ({
        ...n,
        unread: Date.parse(n.time) > lastCheck
      }))
    },
    unreadNotifications (state, getters) {
      return getters.notifications.filter(n => n.unread)
    },
    unreadUpdates (state, getters) {
      return getters.updates.filter(n => n.unread)
    },
    unreadWebsiteScans (state, getters) {
      return getters.websiteScans.filter(n => n.unread)
    },
    isPEP (state) {
      return lassoId => {
        return state.pep[lassoId] || null
      }
    },
    allowMultipleSessions (state) {
      return state.impersonating || state.readOnly || (state.user && state.user.organization.currentSolution.trial) || state.features.indexOf('MultipleSessions') > -1
    },
    allowSidebar (state) {
      return state.user && !state.productAccess && !state.direct
    },
    isAdmin (state) {
      return state.user && state.user.roles.indexOf('Admin') > -1
    },
    isFreemium (state) {
      return state.user ? (state.user.organization.currentSolution.versionUniqueName === 'lasso_freemium' || state.user.organization.currentSolution.versionUniqueName === 'lasso_lite') : false
    },
  },
  mutations: {
    updateUser (state, user) {
      state.user = user
    },
    updateClaims (state, claims = {}) {
      // set claims both as an object and individually on the state object. The object is handy when we pass it to the app iframes.
      Object.keys(claims).map(k => {
        state[k] = claims[k]
      })
      state.claims = claims;
      // having one of three claims means that the user will see a dumbed down version of Lasso; so-called direct link.
      if (claims['ipAccess'] || claims['uak'] || claims['printerUser']) state.direct = true;
      // if the user is a whitelabel user (jylland-posten or finans.dk), change a bunch of functionality by setting the productAccess variable.
      // todo: rename to "whitelabel" everywhere, yes, the naming is confusing..
      if (claims['whitelabel']) state.productAccess = true;
      // set userBrowserId if exists so that we can track the users through IP access
      if (claims['userBrowserId']) state.userBrowserId = claims['userBrowserId'];
    },
    updateApps (state, apps) {
      state.apps = apps
    },
    updateFeatures (state, features = []) {
      state.features = features
    },
    updatePEP (state, pep) {
      state.pep = pep
    },
    setSidebarPinned (state, pinned) {
      Vue.set(state, 'sidebarPinned', pinned)
      localStorage.setItem('portal/sidebar/pinned', pinned ? 'true' : 'false')
    },
    setModulesOpen (state, open) {
      Vue.set(state, 'modulesOpen', open)
      localStorage.setItem('modulesOpen', open ? 'true' : 'false')
    },
    lockedByModal (state, locked) {
      Vue.set(state, 'lockedByModal', locked)
    },
    addEntity (state, entity) {
      Vue.set(state.entities, entity.lassoId, entity)
    },
    setTags (state, tags) {
      if (!state.tags) tags.forEach(t => Vue.set(t, 'invalidated', true)) // tags are invalidated at first, meaning that we'll get the entity list on first fetch.
      // rename "followed" tags for appropriate languages
      tags.filter(t => t.followTag).forEach(t => {
        t.name = this.translate('following')
      })
      state.tags = tags
    },
    updateTag (state, tag) {
      if (tag.followTag) {
        // rename "followed" tags for appropriate languages
        tag.name = this.translate('following')
      }
      var index = state.tags.map(t => t.id).indexOf(tag.id)
      if (index > -1) state.tags.splice(index, 1, tag)
      else state.tags.push(tag)
    },
    removeTag (state, tagId) {
      var index = state.tags.map(t => t.id).indexOf(tagId)
      if (index > -1) state.tags.splice(index, 1)
    },
    invalidateTag (state, tagId) {
      var tag = state.tags ? find(state.tags, t => t.id === tagId) || null : null
      if (tag) Vue.set(tag, 'invalidated', true)
    },
    addNotifications (state, notifications) {
      notifications = notifications.map(n => Notifications.convertNotification(n))
      state.notifications = orderBy(state.notifications.concat(notifications), [ 'time' ], [ 'desc' ])
    },
    addExportUpdate (state, update) {
      if (update.status === 'Completed' || update.status === 'Failed' || update.status === 'Cancelled') {
        Vue.delete(state.updates, update.exportId)
        Vue.set(state.completedJobs, update.exportId, { opened: false }, true)
      } else {
        var current = state.updates[update.exportId]
        var previous = state.completedJobs[update.exportId]
        // ignore if this job is already completed/failed/cancelled. The message is out of sync.
        if (!previous) {
          let title = ''
          switch (update.status) {
            case 'Queued': title = this.translate('export-starting'); break
            case 'Initializing': title = this.translate('export-initializing'); break
            case 'Executing': title = this.translate('export-in-progress'); break
            case 'Finishing': title = this.translate('export-finishing'); break
            case 'Cancelling': title = this.translate('export-cancelling'); break
          }

          Vue.set(state.updates, update.exportId, { id: update.exportId, opened: false, title: title, message: '', time: current ? current.time : new Date(), progress: update.completionPercentage, unread: true, name: update.name })
        }
      }
        },
    addUpdate (state, update) {
      switch (update.type) {
        case 'ExportUpdate':
          store.commit('addExportUpdate', update.message)
          break
        default: break
      }
    },
    addWebsiteScanUpdate (state, update) {
      let key = update.externalId + ',' + update.website
      let scan = state.websiteScans[key]
      if (!scan) scan = Vue.set(state.websiteScans, key, update)
      else scan = Vue.set(state.websiteScans, key, WebsiteScanner.mergePartialResults(scan, update))
      // eslint-disable-next-line no-console
      console.log(`Website scan state after update of type: ${update.type}`, scan)
    },
    setLastNotificationCheck (state) {
      state.user.settings.lastNotificationCheck = Date.now()
    },
    updateLastLogin (state) {
      var timestamp = new Date().toISOString()
      localStorage.setItem('lastLogin', timestamp)
    },
    openNotification (state, notification) {
      Vue.set(state.notifications[state.notifications.map(function (n) { return n.id }).indexOf(notification.id)], 'opened', true)
    },
  },
  actions: {
    getTags ({ state, commit }, forceRefresh) {
      if (state.promises.tags && !forceRefresh) return state.promises.tags
      state.promises.tags = new Promise((resolve, reject) => {
        if (state.tags && !forceRefresh) resolve(state.tags)
        else {
          return Tags.getAll().then(function (tags) {
            commit('setTags', tags)
            resolve(tags)
          })
        }
      })
      return state.promises.tags
    },
    getTag ({ state, commit, dispatch, getters }, { tagId, forceRefresh }) {
      return new Promise((resolve, reject) => {
        dispatch('getTags').then(function (tags) {
          var tag = getters.tag(tagId)
          if (!forceRefresh && tag && !tag.invalidated) resolve(tag)
          else {
            return Tags.get(tagId).then(function (tag) {
              commit('updateTag', tag)
              resolve(tag)
            })
          }
        })
      })
    },
    getTagUser ({ state, commit, getters }, tagId) {
      let tag = getters.tag(tagId)
      Users.get(tag.owner).then(u => Vue.set(tag, 'user', u))
    },
    createTag ({ state, commit }, { tagName, tagType }) {
      return new Promise((resolve, reject) => {
        Tags.create(tagName, tagType).then(newTag => {
          commit('updateTag', newTag)
          commit('invalidateTag', newTag.id)
          resolve(newTag)
        }, reject)
      })
    },
    deleteTag ({ state, commit }, tagId) {
      return new Promise((resolve, reject) => {
        Tags.del(tagId).then(() => {
          commit('removeTag', tagId)
          this._vm.$notify({ title: this.translate('list-deleted') })
          resolve()
        }, reject)
      })
    },
    updateTag ({ state, commit }, tag) {
      return new Promise((resolve, reject) => {
        Tags.update(tag).then(resolve, reject)
      })
    },
    async clearTag ({ state, commit }, tagId) {
      return Tags.clear(tagId)
    },
    updateEntityTags ({ state, getters, commit, dispatch }, changes) {
      return new Promise((resolve, reject) => {
        Tags.updateEntityTags(changes).then(() => {
          var updateFollowed = false
          // invalidate affected tags in order to get newly tagged entities when shown next time. We should be able to just move the entity to the list instead of invalidating it
          changes.tagsToAdd.forEach(t => {
            commit('invalidateTag', t)
            // when adding to a followed lists, make sure we update our combined followed list
            var tag = getters.tag(t)
            if (tag && tag.followTag) updateFollowed = true
          })
          // remove entities from lists that have already been loaded.
          changes.tagsToRemove.forEach(t => {
            var tag = getters.tag(t)
            if (tag && tag.entities) {
              tag.entities = tag.entities.filter(e => changes.entities.every(ent => ent.lassoId === e.lassoId) === false)
            }
            if (tag.followTag) updateFollowed = true
          })
          if (updateFollowed) dispatch('getFollowed', updateFollowed).then(() => resolve())
          else resolve()
        }, reject)
      })
    },
    getTagMonitor ({ state }, tagId) {
      return Tags.getMonitor(tagId)
    },
    updateTagMonitor ({ state, getters }, { tagId, monitor }) {
      // if there are events in any list, then we know that we're following this tag. We can therefore set the Follow property to true at this point
      let followed = (monitor.additionalActions || (monitor.emailEvents || []).length > 0 || (monitor.notificationEvents || []).length > 0)
      Vue.set(getters.tag(tagId), 'followed', followed)

      return Tags.updateMonitor(tagId, monitor)
    },
    updateTagFollowed ({ state, commit, dispatch, getters }, { tagId, followState }) {
      return new Promise((resolve, reject) => {
        var tag = getters.tag(tagId)
        var currenState = tag.followed
        tag.followed = followState
        Tags.updateFollowed(tag.id, followState).then(() => {
          this._vm.$notify({ title: this.translate(followState ? 'now-following-list' : 'no-longer-following-list') })
        }, error => { tag.followed = currenState; reject(error) })
      })
    },
    doExport ({ state, getters, dispatch }, { tagId, exportType }) {
      return new Promise((resolve, reject) => {
        dispatch('getTag', { tagId }).then(tag => {
          Tags.getEntities(tagId, 0, 20000).then(entities => {
            DataExports.schedule({
              lassoIds: entities.results.map(r => r.lassoId),
              type: exportType,
              name: 'Eksport: ' + tag.name,
              notificationEmail: state.user.email // todo: handle custom email
            }).then((result) => {
              this._vm.$notify({
                title: this.translate('export-queued'),
                text: this.translate('export-queued-description'),
                duration: 5000
              })
              resolve(result)
            }, reject)
          })
        })
      })
    },
    getEntity ({ state, commit }, { lassoId }) {
      if (state.entities[lassoId]) return new Promise((resolve, reject) => { resolve(state.entities[lassoId]) })
      else if (state.promises[lassoId]) return state.promises[lassoId]
      else {
        // eslint-disable-next-line no-async-promise-executor
        return Vue.set(state.promises, lassoId, new Promise(async (resolve, reject) => {
          await Entities.get(lassoId).then(entity => {
            commit('addEntity', entity)
            resolve(entity)
          }, error => reject(error))
          Vue.delete(state.promises, lassoId)
        }))
      }
    },
    // get initial notifications and ongoing exports
    async getNotifications ({ commit, dispatch }) {
      const notifications = await Notifications.get()
      const ongoingExports = await Exports.getCurrent()

      // console.debug('Got notifications', notifications, await Notifications.get())
      commit('addNotifications', notifications)
      ongoingExports.forEach(e => {
        commit('addExportUpdate', e)
      })
    },
    async getAvailableOrgs ({ state }, forceRefresh) {
      if (state.promises.availableOrganizations && !forceRefresh) return state.promises.availableOrganizations
      state.promises.availableOrganizations = new Promise((resolve, reject) => {
        if (import.meta.env.DEV) console.debug('Getting available organizations from server?', !state.availableOrganizations || forceRefresh, { availableOrganizations: !!state.availableOrganizations, forceRefresh })
        if (state.availableOrganizations.length && !forceRefresh) resolve(state.availableOrganizations)
        else {
          return Vue.http.get('login/available-organizations', { credentials: true }).then(response => {
            state.availableOrganizations = response.body
            resolve(response.body)
          })
        }
      })
      return state.promises.availableOrganizations
    },
    // initialize the app, including signing in the user (PUID or UAK) and getting user data
    async initialize ({ state, dispatch, commit }, force = false) {
      if (state.promises.initialize && !force) return state.promises.initialize
      state.promises.initialize = new Promise(async (resolve, reject) => {
        try {
          state.loading = true
          // log in in using provided login parameters
          var currentUrl = new URL(document.location.href)
          if (import.meta.env.DEV) console.debug('signing in using alternate method (not from current cookie)', { uak: window.uak, lt: window.lt, puid: window.puid })
          let userDataFromParameters = await Auth.loginWithParameters(currentUrl.searchParams.get('uak'), window.lt, window.puid)
          
          Vue.http.options.root = apiUrl
          Vue.http.interceptors.push((req, next) => {
            const token = Auth.getToken()
            req.headers.set('Authorization', 'Bearer ' + token)
            if (window.userBrowserId) req.headers.set('User-Browser-Id', window.userBrowserId)
            next(res => {
              if (res.status === 401 && res.body.errorCode === 15) {
                document.location.href = EnvironmentUtils.GetAppsUrl() + '/logout?returnUrl=' + encodeURIComponent(document.location.href)
                return
              }
              if (res.status === 401 && res.url.indexOf('login') > -1 && res.url.indexOf('switch') === -1 && !store.state.direct) signOut()
              if (res.status === 500 && res.body.errorCode === 30) {
                return new Promise((resolve, reject) => {
                  EventBus.$emit('modal', {
                    modalType: 'confirm',
                    confirmTitle: 'OBS!',
                    confirmDescription: res.body.errorMessage,
                    confirmButtonText: 'Fortsæt',
                    cancelButtonText: 'Fortryd',
                    onConfirm: () => {
                      req.url = req.url + (req.url.indexOf('?') > -1 ? '&' : '?') + 'ignoreWarnings=true'
                      Vue.http(req).then(resolve, reject)
                    }
                  })
                })
              }
            })
          })

          await dispatch('initializeUser', userDataFromParameters)

          // retrieve required data for app to load
          const requiredTasks = [dispatch('getTags', force)]
          if (!state.direct) {
            requiredTasks.push(dispatch('getFollowed', force))
          }
          await Promise.all(requiredTasks)

            resolve(true)
        } catch (error) {
          if (error.message !== 'Lasso Unauthorized') {
            captureException(error, { extra: { message: 'Failed to initialize app' } })
            reject(error)
          }
        } finally {
          state.loading = false
          state.loadingMessage = null
        }
      })
      return state.promises.initialize
    },
    // set any state related to the user
    async initializeUser ({ state, commit, dispatch, getters }, { user, apps, claims, features, token }) {
      //#region Clean up any usersettings in the old structure
      // check if we're using the deprecated frontEndSettings object. In that case, move props to settings object.
      if (user.settings && user.settings.frontendSettings) {
        Object.assign(user.settings, user.settings.frontendSettings)
        user.settings.frontendSettings = null
        await Users.updateSettings(user.settings)
      }
      //#endregion
    
      if (claims.UAK === true) {
        state.direct = true
        state.readOnly = true
      }

      //#region Update state with user, apps, features and claims
      commit('updateUser', user)
      commit('updateClaims', claims);
      commit('updateApps', apps);
      commit('updateFeatures', features)
      
      state.lassoSettings = new LassoSettings({
        apps: getters.standaloneApps,
        tags: getters.tags,
        readOnly: state.readOnly,
        isFreemium: getters.isFreemium,
      }, user.settings)

      await state.lassoSettings.Ready
      //#endregion

      // configure scope for sentry
      configureScope(function (scope) {
        scope.setUser({"id": state.user.id})
        scope.setExtra("organizationId", state.user.lassoOrganizationId)
      })
      //#endregion
    
      //#region Setup June analytics and Sentry error tracking
      // configure june analytics
      EventBus.$emit('june:identifyUser', { id: user.id, traits: {
        email: state.user.email,
        role: state.user.roles.some(r => r.indexOf('Admin') > -1) ? 'admin' : 'user',
        created: state.user.created
      }})

      let solution = state.user.organization.currentSolution
      EventBus.$emit('june:identifyOrg', { id: state.user.lassoOrganizationId, traits: {
        name: state.user.organization.name,
        plan: !solution || !solution.isActive
          ? 'expired'
          : getters.isFreemium 
            ? 'freemium'
            : solution.trial 
              ? 'trial'
              : 'paid'
        ,
        solution: !solution ? null : solution.versionUniqueName,
        cvr: state.user.organization.lassoId ? state.user.organization.lassoId.substr(6) : null,
        created: state.user.organization.created
      }})


        // UNIX/Epoch timestamp
        const timestamp = Date.now()
        // calculate minutes since between visits to use for various tracking reports. This will be attached to the login event
        const minuteDiffSinceLastVisited = state.user.settings && 'lastVisit' in state.user.settings ? state.user.settings.lastVisit ? diffInMinutes(new Date(state.user.settings.lastVisit), new Date(timestamp)) : null : null
        if (!state.readOnly) {
          // Make sure we don't set firstTimeLogin more than once
          if (!state.user.settings.firstTimeLogin) state.user.settings.firstTimeLogin = timestamp
  
          // Update previousVisit, BEFORE lastVisit is updated
          state.user.settings.previousVisit = state.user.settings.lastVisit || null
  
          // Update lastVisit
          state.user.settings.lastVisit = timestamp
          Users.updateSettings(state.user.settings).catch(reason => console.warn('Failed to update user settings', reason))
        }


      EventBus.$emit('june:trackEvent', {
        event: 'Logged in',
        traits: {
          authType: claims.AuthType,
          isUAK: claims.UAK,
          minutesSinceLastVisited: minuteDiffSinceLastVisited,
          daysSinceCreated: diffInDays(new Date(state.user.created), new Date(timestamp)),
          workingHours: isWorkingHours(new Date(timestamp)) ? 'true' : 'false'
        }
      })
      
      state.socket = io('push.lassox.com')

      //#region Listen for events from the socket connection
      // make sure the uniqueSessionIdentifier is saved, which will help us check if a user is online more than one place at once.
      localStorage.setItem('uniqueSessionIdentifier', state.uniqueSessionIdentifier)

      // 04-09-2024 - prior to Socket.io v3, we needed to listen for all events using the wildcard. This is no longer necessary.
      state.socket.onAny(function (type, data) {
        const dataParsed = JSON.parse(data)
        if (import.meta.env.DEV) console.debug('Received socket event', dataParsed, type)

        EventBus.$emit('push-message', dataParsed)
      })

      if (import.meta.env.DEV) console.debug('Listening for socket events on', [ state.user.id + (state.userBrowserId ? '_' + state.userBrowserId : ''), state.user.lassoOrganizationId ])

      Notifications.listen(state.socket, [ state.user.id + (state.userBrowserId ? '_' + state.userBrowserId : ''), state.user.lassoOrganizationId ], {
        Notification: notification => {
          state.notifications = state.notifications.filter(n => n.id !== notification.message.id)
          if (notification.message.type === 'ExportCompletion') notification.message.opened = false
          commit('addNotifications', [ notification.message ])
        },
        WebsiteCrawler: async update => {
          await dispatch('getEntity', { lassoId: update.message.externalId })
          if (update.message.data && !update.message.data.error) commit('addWebsiteScanUpdate', update.message)
        },
        ExportUpdate: update => {
          commit('addUpdate', update)
        },
        SignedIn: () => dispatch('signedInElsewhere'),
        identifierbroadcast: identifier => {
          if (!getters.allowMultipleSessions && state.uniqueSessionIdentifier !== identifier) {
            dispatch('signedInElsewhere')
          }
        },
        UserSettingsUpdate: (settings) => {
          state.user.settings = settings.message
        },
        // eslint-disable-next-line no-unused-vars
        TagUpdate: update => {
          dispatch('getTags', true)
        },
        refresh: () => { dispatch('reload') }
      })
      //#endregion
    },
    signedInElsewhere ({ state, commit }, clearCookies) {
      captureEvent({ message: 'User signed in elsewhere' })
      state.showingSignedInElsewhereDialogue = true
      Vue.http.get('logout', null, { credentials: true }).then(() => {
        EventBus.$emit('modal', {
          modalType: 'confirm',
          confirmTitle: this.translate('logged-in-elsewhere'),
          confirmDescription: this.translate('logged-in-elsewhere-description'),
          confirmButtonText: this.translate('log-in-again'),
          hideCancelButton: true,
          onConfirm: () => {
            state.showingSignedInElsewhereDialogue = false
            signOut()
          },
          requireAction: true
        })
      })
    },
    reload ({ state, dispatch }, message) {
      return new Promise((resolve, reject) => {
        if (message) appStore.loadingMessage = message
        this.replaceState(getDefaultState())
        dispatch('initialize', true).then(() => {
          EventBus.$emit('initialize')
          EventBus.$emit('createInitialTab')
          resolve()
        }, reject)
      })
    },
    switchOrganization ({ state, dispatch }, organization) {
      return new Promise((resolve, reject) => {
        appStore.loadingMessage = `Skifter til konto "${organization.name}"...`
        Users.switchOrganization(organization.lassoOrganizationId).then(() => {
          state.socket.disconnect()
          if (router.currentRoute.name !== 'search') router.replace({ name: 'search' })
          dispatch('reload', `Skifter til konto "${organization.name}"...`).then(resolve, reject)
        }, reason => {
          // if 2FA is required, we need to redirect to the login page to get the user to authenticate
          if (reason.body.errorCode === 111) {
            Users.switchOrganizationGetToken(organization.lassoOrganizationId).then(token => {
              location.href = EnvironmentUtils.GetAppsUrl() + '/login?returnUrl=' + encodeURIComponent(EnvironmentUtils.GetPortalUrl()) + '&skipIpCheck=true&token=' + encodeURIComponent(token)
            }, reject)
          }
        })
      })
    },
    impersonate ({ state }, user) {
      Vue.http.post(`organizations/${user.lassoOrganizationId ?? 'null'}/users/${user.id}/impersonate`, null, { credentials: true }).then(() => {
        window.location.reload()
      }, error => {
        if (error.errorMessage.indexOf('already') > -1) {
          EventBus.$emit('modal', {
            modalType: 'confirm',
            confirmTitle: 'Ikke tilladt',
            confirmDescription: 'Du er allerede logget ind som en anden bruger end din egen, og kan derfor ikke bruge denne funktionalitet.',
            hideCancelButton: true,
            confirmButtonText: 'Ok'
          })
        }
      })
    },
    broadcastActivity ({ state }) {
      if (state.user) {
        state.socket.emit('broadcastidentifier', { userId: state.user.id, identifier: state.uniqueSessionIdentifier })
      }
    },
    updateApps ({ state, commit }) {
      return Users.getApps().then(apps => { state.apps = apps })
    },
    getListNews ({ state, commit, getters, dispatch }, { cacheKey, forceRefresh, lassoIds = [],listId = null, page = 1, pageSize = 12, types = null }) {
      // should cache
      return new Promise((resolve, reject) => {
        var onDone = (news) => {
          if (page === 1) {
            Vue.set(state.news, cacheKey, { items: news, page: page, endReached: news.length < pageSize })
          } else {
            var existing = getters.news(cacheKey)
            existing.items = existing.items.concat(news)
            existing.page = page
            existing.endReached = news.length < pageSize
          }
          resolve()
        }
        if (cacheKey === 'home') {
          dispatch('getFollowed', forceRefresh).then(followed => {
            if (followed.length) Story.getByLassoIds(followed.map(f => f.lassoId), page, pageSize).then(onDone)
            else Story.getList('forsiden', page, pageSize, 'promoteduntil', types).then(onDone)
          })
        } else if (lassoIds.length > 0) {
          Story.getByLassoIds(lassoIds, page, pageSize).then(onDone)
        } else {
          Tags.getNews(listId, page, pageSize).then(onDone)
        }
      })
    },
    getExports ({ state, commit }, forceRefresh) {
      state.promises.exports = new Promise((resolve, reject) => {
        if (state.promises.exports) state.promises.exports.then(resolve, reject)
        else if (state.exports && !forceRefresh) resolve(state.exports)
        else {
          state.gettingExports = true
          DataExports.get().then(result => {
            state.gettingExports = false
            Vue.set(state, 'exports', result.filter(e => e.hasInput && e.type !== 'CrawlerContacts')) // temporarilly hide crawlercontacts until we know what to do with it here.
            resolve(result)
          }, reject)
        }
      })
      return state.promises.exports
    },
    getMoreNotifications ({ state, commit, getters }) {
      if (!state.noMoreNotifications && !state.gettingNotifications) {
        state.gettingNotifications = true
        Notifications.getBefore(getters.notifications[getters.notifications.length - 1].time, 5).then(notifications => {
          notifications = notifications.filter(n => state.notifications.find(no => no.id === n.id) == null)
          if (notifications.length === 0) state.noMoreNotifications = true
          else commit('addNotifications', notifications)
          state.gettingNotifications = false
        })
      }
    },
    updateLastNotificationCheck ({ state, commit }) {
      if (state.impersonating) return
      commit('setLastNotificationCheck')
      Users.updateSettings({ lastNotificationCheck: state.user.settings.lastNotificationCheck })
    },
    updateOnboardingTours({ state, commit }, tourData) {
      if(state.impersonating) return
      Users.updateSettings({ onboarding: tourData })
    },
    async updateOnboardingQuestionaire({ state, commit }, questionaireData) {
      if (state.impersonating) return
      await Users.updateSettings({ questionaire: questionaireData })
    },
    getFollowed ({ state, commit, getters }, forceRefresh) {
      var promise = state.promises.followed = state.promises.followed || new Promise((resolve, reject) => {
        if (state.followed && !forceRefresh) resolve(state.followed)
        else {
          Users.getFollowed().then(result => {
            Vue.set(state, 'followed', result)
            resolve(result)
          }, reject)
        }
      })
      promise.then(() => Vue.delete(state.promises, 'followed'))
      return promise
    },
    followEntity ({ state, commit, getters, dispatch }, { lassoId, name }) {
      return new Promise((resolve, reject) => {
        var followTag =	find(getters.tags, t => t.type === (lassoId.indexOf('CVR-1') === 0 ? 'Company' : 'Person') && t.followTag)
        state.followed.push({ lassoId, name })
        if (followTag) {
          dispatch('updateEntityTags', { entities: [ {lassoId} ], tagsToAdd: [ followTag.id ], tagsToRemove: [] }).catch(() => {
            this._vm.$notify({ text: 'Der opstod en fejl. Prøv igen' })
          })
          this._vm.$notify({ text: this.translate('now-following', { name }) })
        }
      })
    },
    unfollowEntity ({ state, commit, getters, dispatch }, { lassoId, name }) {
      return new Promise((resolve, reject) => {
        var followTag =	find(getters.tags, t => t.type === (lassoId.indexOf('CVR-1') === 0 ? 'Company' : 'Person') && t.followTag)
        var followedEntityIndex = state.followed.findIndex(i => i.lassoId === lassoId)
        if (followedEntityIndex > -1) {
          state.followed.splice(followedEntityIndex, 1)
        }
        if (followTag) {
          dispatch('updateEntityTags', { entities: [ {lassoId} ], tagsToAdd: [], tagsToRemove: [ followTag.id ] }).catch(() => {
            this._vm.$notify({ text: 'Der opstod en fejl. Prøv igen' })
          })
          this._vm.$notify({ text: `Overvåger ikke længere ${name}` })
        }
      })
    },
    acceptTerms ({ state }) {
      return Organization.acceptTerms()
    },
    stopImpersonating ({ dispatch }) {
      Users.stopImpersonating().then(() => {
        document.location.href = document.location.origin
      })
    },
    changeImpersonation ({ state, dispatch }, user) {
      state.socket.disconnect()
      Users.changeImpersonation(state.user.lassoOrganizationId, user.id).then(() => {
        dispatch('reload', `Skifter til ${user.name}...`)
      })
    },
    getOrganizationUsers ({ state }) {
      return Users.getAll(state.user.lassoOrganizationId)
    },
    getAvailableNewsTypes ({ state }) {
      return new Promise((resolve, reject) => {
        if (state.availableNewsTypes) resolve(state.availableNewsTypes)
        else {
          Users.getAvailableNewsTypes().then(types => {
            Vue.set(state, 'availableNewsTypes', types.body)
            resolve(types.body)
          })
        }
      })
    },
    openNewTab ({ state, dispatch }, route) {
      const tab = createTab({ store, route })
      state.tabs.push(tab)
      dispatch('selectTab', tab)
      return tab
    },
    openNewTabRelatively ({ state, dispatch }, route) {
      const tab = createTab({ store, route })
      const currentTab = state.activeTab
      tab.order = currentTab.order + 0.5
      state.tabs.push(tab)
      dispatch('selectTab', tab)
      return tab
    },
    selectTab ({ state }, tab) {
      state.activeTab = tab
      history.pushState({
        historyId: appHistory.id,
        tabId: tab.id,
      }, '', tab.route.fullPath)
    },
    findMatchingTab ({ state }, matcher) {
      if (state.activeTab && matcher(state.activeTab)) return state.activeTab
      return state.tabs.find(tab => matcher(tab))
    },
  },
})
