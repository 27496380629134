<script>
import { apiUrl } from '@/assets/js/helpers.js'
export default {
  name: 'TermsModal',
  props: {
    termsType: { type: String, required: true }
  },
  data () {
    return {
      accepted: false,
      saving: false,
      loadedCheckInterval: null
    }
  },
  computed: {

    termsUrl () {
      return apiUrl + '/pdf/viewer.html?file=' + apiUrl + '/organizations/' + this.$store.state.user.organization.lassoOrganizationId + '/contracts/data-processing-agreement'
    },
    title () {
      switch (this.termsType) {
        case 'DPA': return this.translate('dpa-title')
        default: return 'UKENDT'
      }
    },
    description () {
      switch (this.termsType) {
        case 'DPA': return this.translate('dpa-description')
        default: return 'UKENDT'
      }
    },
    checkboxLabel () {
      switch (this.termsType) {
        case 'DPA': return this.translate('i-accept-dpa-terms')
        default: return 'UKENDT'
      }
    }
  },
  methods: {
    onSave () {
      this.saving = true
      this.$store.dispatch('acceptTerms', this.termsType).then(() => {
        this.$notify({ title: this.translate('thank-you-terms') })
        this.$emit('done')
      }, () => {
        this.saving = false
      })
    },
    onTermsLoaded (event) {
      window.clearInterval(this.loadedCheckInterval)
    }
  }
}
</script>

<template>
  <div id="termsModal">
    <div class="modal-top">
      <h1>{{ title }}</h1>
      <p v-html="description" />
    </div>
    <div class="modal-center">
      <iframe
        ref="frame"
        frameborder="0"
        :src="termsUrl"
        height="450"
        style="width: 100%"
      />
    </div>
    <div class="modal-bottom right">
      <div class="left">
        <a
          style="color: #ffa07a"
          v-show="!saving"
          @click="$emit('cancel')"
          v-lang.ask-later
        />
      </div>
      <div class="form-check form-check-inline clickable">
        <input
          class="form-check-input clickable"
          type="checkbox"
          id="accept"
          value=""
          v-model="accepted"
        >
        <label
          class="form-check-label clickable"
          for="accept"
        >{{ checkboxLabel }}</label>
      </div>
      <button
        class="btn btn-primary"
        :disabled="!accepted"
        @click="onSave"
        v-lang.save-accept
      />
    </div>
  </div>
</template>

<style lang="less" scoped>
  #termsModal {
  }
</style>
