<script>
import NewsPreview from '@/components/news/NewsPreview'
import DiscoverPreview from '@/components/news/DiscoverPreview'
import FeedList from '@/components/news/FeedList'
import PersonProfile from '@/components/news/PersonProfile'
export default {
  name: 'NewsPreview',
  props: {
    'feedItem': {
      type: Object,
      required: true
    },
    'query-related': {
      type: Boolean,
      required: false,
      default: false
    },
    maxWidth: {
      type: String
    }
  },
  data () {
    return {
    }
  },
  computed: {
  },
  components: {
    'news-preview': NewsPreview,
    'discover-preview': DiscoverPreview,
    'feed-list': FeedList,
    'person-profile': PersonProfile
  }
}
</script>

<template>
  <div v-if="feedItem.type === 'news-preview'">
    <news-preview
      :newsItem="feedItem.item.item"
      :previewType="feedItem.item.previewType"
      :queryRelated="queryRelated"
      :sizing="feedItem.item.sizing"
      :maxWidth="maxWidth"
      :noImage="feedItem.item.noImage"
    />
  </div>
  <div v-else-if="feedItem.type === 'feed-list'">
    <feed-list
      :listType="feedItem.item.listType"
      :count="feedItem.item.count"
    />
  </div>
  <div v-else-if="feedItem.type === 'person-profile'">
    <person-profile />
  </div>
  <div v-else-if="feedItem.type === 'discover-preview'">
    <discover-preview :discoverItem="feedItem.item" />
  </div>
</template>

<style lang="less" scoped>
  
</style>
