<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import Icon from '@/components/Icon.vue'

@Component({
  components: {
    Icon,
  },
})

export default class BaseButton extends Vue {
  @Prop({ type: String })
  readonly label?: string

  @Prop({ type: String })
  readonly icon?: string

  @Prop({ type: String })
  readonly leadingIcon?: string

  @Prop({ type: String })
  readonly trailingIcon?: string

  @Prop({type: String, default: 'default'})
  readonly buttonColor!: 'peach' | 'default' | 'solid-peach'

  get finalLeadingIcon () {
    return this.leadingIcon ?? this.icon ?? ''
  }

  get finalTrailingIcon () {
    return this.trailingIcon ?? ''
  }

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean
}
</script>

<template>
  <button
    :class="[
      'lassox-portal__BaseButton',
      disabled && 'lassox-portal__BaseButton--disabled',
      buttonColor === 'default' && 'lassox-portal__BaseButton-default',
      buttonColor === 'peach' && 'lassox-portal__BaseButton-peach',
    ]"
    :disabled="disabled"
    v-on="{
      ...$listeners,
      click: e => !disabled && $emit('click', e),
    }"
  >
    <slot />

    <template v-if="finalLeadingIcon">
      <Icon
        v-if="finalLeadingIcon !== ' '"
        class="lassox-portal__BaseButton_leading-icon"
        :icon="finalLeadingIcon"
        size="small"
      />

      <div
        v-else
        class="lassox-portal__BaseButton_leading-icon"
      />
    </template>

    <div
      v-if="label"
      class="lassox-portal__BaseButton_label"
      v-text="label"
    />

    <template v-if="finalTrailingIcon">
      <Icon
        v-if="finalTrailingIcon !== ' '"
        class="lassox-portal__BaseButton_trailing-icon"
        :icon="finalTrailingIcon"
        size="small"
      />

      <div
        v-else
        class="lassox-portal__BaseButton_trailing-icon"
      />
    </template>

    <div class="lassox-portal__BaseButton_outline" />

    <div class="lassox-portal__BaseButton_overlay" />
  </button>
</template>

<style lang="scss">
@mixin buttonReset {
  appearance: none;
  padding: 0;
  border: none;
  margin: 0;
  box-sizing: border-box;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  text-align: inherit;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
}

.lassox-portal__BaseButton {
  @include buttonReset;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  opacity: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  user-select: none;
  transition: color 100ms, background-color 100ms, opacity 100ms;

  &-default &_overlay {
    background-color: rgba(black, 0.16);
  }

  &-peach &_overlay {
    background-color: rgba(#ff7138, 0.2);
  }

  & > &_label {
    position: relative;
    z-index: 1;
    font-size: 12px;
    line-height: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .design2024 & > &_label {
    font-family: 'Poppins', 'Roboto', sans-serif;
    font-size: 13px;
  }

  & > &_leading-icon + &_label,
  & > &_label + &_trailing-icon {
    margin-left: 8px;
  }

  & > &_leading-icon,
  & > &_trailing-icon {
    width: 18px;
    height: 18px;
    color: inherit;
  }

  & > &_outline {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    border-radius: inherit;
    color: transparent;
    box-shadow: inset 0 0 0 1px;
    transition: color 100ms, box-shadow 100ms;
  }

  & > &_overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    opacity: 0;
    transition: background-color 100ms, opacity 100ms;
  }

  transition: transform 0.3s;
  :not(.design2024) &:hover > &_overlay {
    opacity: 0.25;
    // background-color: royalblue;
  }

  .design2024 &:hover {
    transform: scale(0.95);
  }

  &:focus {
    outline: none;
  }

  &:focus > &_overlay {
    opacity: 0.5;
  }

  &:active > &_overlay {
    opacity: 1;
  }

  &--disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &--disabled > &_overlay {
    opacity: 0;
  }
}
</style>
