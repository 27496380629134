<script>
import Vue from 'vue'
import { store } from '@/assets/js/store.js'
import { EventBus, Story } from '@/assets/js/helpers.js'
import Directives from '@/assets/js/directives.js'
import Linkify from '@shared/vue-lasso-core/components/Linkify.vue'
import windowSize from '@/window-size.ts'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Story',
  props: {
    story: { type: Object, required: true },
    statstidende: { type: Array, required: false },
    entity: { type: Object, required: true },
    monitorName: { type: String, required: false }
  },
  components: {
    Linkify
  },
  data () {
    return {
      loadingNews: false
    }
  },
  computed: {
    isMobile () {
      return windowSize.width < windowSize.sizes.sm
    },
    publishTime () {
      var timeDiff = Math.abs(new Date().getTime() - new Date(this.story.time).getTime())
      var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))
      return diffDays < 31 ? this.$options.filters.timeSince(this.story.time, this.translate) : this.$options.filters.date(this.story.time, '%d.%m.%Y')
    },
    latestAccount: function () {
      return this.entity.lastAccounts.length ? this.entity.lastAccounts[0] : null
    },
    latestAccountYear: function () {
      return this.latestAccount ? new Date(this.latestAccount.period.to).getFullYear() : ''
    },
    taglineComponent: function () {
      return this.story.tagLine && { name: 'news', template: '<span>' + Vue.filter('linkify')(this.story.tagLine, false) + '</span>' }
    },
    contentComponent: function () {
      return { name: 'news', template: '<span>' + Vue.filter('linkify')(this.story.content, false) + '</span>' }
    },
    htmlContent: function () {
      return Vue.filter('linkify')(this.story.content, false)
    },
    htmlTagline: function () {
      return Vue.filter('linkify')(this.story.tagLine, false)
    },
    backgroundImage: function () {
      switch (windowSize.size) {
        case 'xxs': return 'url(' + this.story.imageId.replace('.jpg', '_480.jpg') + ')'
        case 'xs': return 'url(' + this.story.imageId.replace('.jpg', '_768.jpg') + ')'
        case 'sm': return 'url(' + this.story.imageId.replace('.jpg', '_992.jpg') + ')'
        case 'md': return 'url(' + this.story.imageId.replace('.jpg', '_1200.jpg') + ')'
        case 'lg': return 'url(' + this.story.imageId.replace('.jpg', '_1200.jpg') + ')'
      }
      return true
    },
  },
  methods: {
    openNewTab (entity) {
      this.$store.dispatch('openNewTab', this.$router.resolve({ name: 'entity', params: { lassoId: entity.lassoId } }).route)
    },
    getAccountYear: function (account) {
      return account ? new Date(account.period.to).getFullYear() : ''
    },
    linkify: function (htmlText) {
      return Vue.filter('linkify')(htmlText)
    }
  },
  directives: {
    'clipboard': Directives.clipboard
  }
}
</script>

<template>
  <div
    id="story"
    class="story"
    :class="{ 'isMobile': isMobile }"
  >
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 padding-0">
          <div class="margin-bottom-1">
            {{ monitorName && monitorName + ', ' }}<a
              href=""
              @click.prevent="openNewTab(entity)"
            >{{ entity.name }}</a>, <span class="text-muted">{{ publishTime }}</span>
          </div>
          <h1
            class="headline margin-top-0"
            style="margin-bottom: 8px;"
            v-html="story.prettyHeadline"
          />
          <h2
            class="tagline padding-top-1"
            v-if="taglineComponent"
          >
            <Linkify
              :html="story.tagLine"
              :onClickLink="(obj) => openNewTab(obj)"
            />
          </h2>
          <p class="margin-top-2 margin-bottom-0 text-semimuted story-content">
            <Linkify
              :html="story.content"
              :onClickLink="(obj) => openNewTab(obj)"
            />
          </p>
          <template v-if="statstidende">
            <div
              class="margin-top-1 story-content text-semimuted"
              :key="st"
              v-for="(st, index) in statstidende"
            >
              <p>{{ index === 0 ? 'I Statstidende skrives der:' : 'Derudover skriver Statstidende:' }}</p>
              <span
                class="statstidende text-muted"
                style="white-space: pre-wrap;"
                v-html="st"
              />
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less">
  #story {
    position: relative;

    p {
      margin-bottom: 12px;
    }

    strong {
      font-weight: bolder;
    }

    ol, ul {
      padding-left: 40px;
      list-style: initial;
    }

    .width-constraint {
      margin: 0 auto;
    }

    .status-change {
      border-radius: 2px;
      text-align: center;
      font-size: 11px;
      line-height: 16px;
      font-weight: bold;
      padding: 8px;
      &.danger {
        color: #ffa07a;
        background-color: #fadddd;
      }
      &.warning {
        color: #cc7606;
        background-color: #f7ebda;
      }
    }

    .image {
      background-size: cover;
      min-height: 200px;
    }

    h1.headline {
      font-size: 32px;
      font-weight: 500;
      line-height: 40px;
    }

    &.isMobile {
      h1.headline {
        font-size: 24px;
        line-height: 32px;
      }
    }

    .tagline {
      font-size: 16px;
      font-weight: 500;
      line-height: 26px;
    }

    .story-content {
      line-height: 26px;
      a {
        color: #616161;

        &:hover {
          color: rgba(255, 142, 97, 1);
        }
      }
      ul {
        margin: 10px 0;
      }
      blockquote {
        font-size: 15px !important;
        line-height: 24px;
        margin: 15px 0 !important;
        padding: 10px 20px;
        border-left: 5px solid #eee;
      }
      span.statstidende {
        margin: 15px 0!important;
        padding: 10px 20px;
        border-left: 5px solid #eee;
        display: block;
      }
    }

    .contact-info {
      line-height: 28px;
    }

    .background-image-container {
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      height: 600px;
      max-height: 600px;
      width: 100%;
    }
  }
</style>
