<script>
import { debounce } from 'lodash'
import NewsFeedItem from '@/components/news/NewsFeedItem'
import NewsPreview from '@/components/news/NewsPreview'
import NewsLoadFooter from '@/components/news/NewsLoadFooter'
export default {
  name: 'NewsFeed',
  props: {
    news: {
      type: Object,
      required: true
    },
    'feed-type': {
      type: String,
      required: true
    },
    name: {
      type: String
    },
    loading: {
      type: Boolean,
      required: true
    },
    'hide-footer': {
      type: Boolean
    }
  },
  data () {
    return {
      size: '',
      onResize: debounce(() => { this.size = this.getSize(this.$el) }, 100),
      breakpoints: {
        xs: 480,
        sm: 768,
        md: 992,
        lg: 1200
      }
    }
  },
  computed: {
    items: function () {
      return this.news.items
    },
    // eslint-disable-next-line vue/return-in-computed-property
    newsLayouts: function () {
      switch (this.feedType) {
        case 'entity-related': return this.chunkBy(this.items, 2).map((c) => { return { layoutName: '2-equal', items: c.map((i) => ({ type: 'news-preview', item: { previewType: 'basic', item: i } })) } })
        case 'home': return this.getHomeLayouts(this.items)
        case 'list': return this.getGenericHomeLayouts(this.items)
        case 'discover-overview': return this.items
      }
    },
    // eslint-disable-next-line vue/return-in-computed-property
    isQueryRelated: function () {
      switch (this.feedType) {
        case 'entity-related': return false
        case 'home':
        case 'list': return true
      }
    },
    // eslint-disable-next-line vue/return-in-computed-property
    sizeClass () {
      var width = this.size
      if (width < 480) return 'xxs'
      if (width < 768) return 'xs min-xss min-xs'
      if (width < 992) return 'sm min-xss min-xs min-sm'
      if (width < 1200) return 'md min-xss min-xs min-sm min-md'
      if (width >= 1200) return 'lg min-xss min-xs min-sm min-md'
    }
  },
  methods: {
    chunkBy: function (items, count) {
      var i = 0
      var chunks = []
      while (items.length > i) {
        chunks.push(items.slice(i, i + count))
        i = i + count
      }
      return chunks
    },
    getHomeLayouts: function (items) {
      var layouts = []
      // if (items[0]) layouts.push({ layoutName: 'full-width', items: [{ item: { item: items[0], previewType: 'highlight' }, type: 'news-preview' }] })
      if (items.length > 0) layouts.push({ layoutName: '3-fourths-1-fourth', items: items.slice(0, 2).map((i, index) => ({ type: 'news-preview', item: { item: i, previewType: 'basic', sizing: index ? 'narrow' : 'tall' } })) })
      if (items.length > 2) layouts.push({ layoutName: 'equal-2-rows-1-row', items: [ { type: 'feed-list', item: { listType: 'latest-accounts', count: 3 } }, { type: 'news-preview', item: { previewType: 'basic', item: items[2] } }, { type: 'person-profile' } ] })
      if (items.length > 3) {
        var layout = { layoutName: '2-columns-3-fourths-1-fourth', items: [] }
        if (items[3]) Array.prototype.push.apply(layout.items, items.slice(3, 5).map(i => ({ type: 'news-preview', item: { previewType: 'basic', item: i, sizing: 'tall' } })))
        if (items[5]) Array.prototype.push.apply(layout.items, items.slice(5, 7).map(i => ({ type: 'news-preview', item: { previewType: 'basic', item: i, sizing: 'narrow' } })))
        layouts.push(layout)
      }
      var layout2 = { layoutName: '2-equal', items: [ { type: 'feed-list', item: { listType: 'latest-news', count: 5 } } ] }
      if (items[7]) layout2.items.push({ type: 'news-preview', item: { previewType: 'basic', item: items[7], sizing: 'narrow' } })
      layouts.push(layout2)

      // get generic layouts for the rest
      if (items.length > 8) Array.prototype.push.apply(layouts, this.getGenericHomeLayouts(items.slice(8)))

      return layouts
    },
    getGenericHomeLayouts: function (feedItems) {
      var chunks = this.chunkBy(feedItems, 11)
      var layouts = []
      chunks.map(items => {
        if (items.length > 0) layouts.push({ layoutName: '3-fourths-1-fourth', items: items.slice(0, 2).map((i, index) => ({ type: 'news-preview', item: { item: i, previewType: 'basic', sizing: index ? 'narrow' : 'tall' } })) })
        if (items.length > 2) {
          var layout1 = { layoutName: 'equal-2-rows-1-row', items: [ { type: 'news-preview', item: { previewType: 'basic', item: items[2], noImage: true } } ] }
          if (items[3]) layout1.items.push({ type: 'news-preview', item: { previewType: 'basic', item: items[3] } })
          if (items[4]) layout1.items.push({ type: 'news-preview', item: { previewType: 'basic', item: items[4], sizing: 'tall' } })
          layouts.push(layout1)
        }
        if (items[5]) layouts.push({ layoutName: '2-thirds-1-third', items: items.slice(5, 7).map((i, index) => ({ type: 'news-preview', item: { previewType: 'basic', item: i, sizing: index ? '' : 'tall' } })) })
        if (items[7]) layouts.push({ layoutName: '1-third-2-thirds', items: items.slice(7, 9).map((i, index) => ({ type: 'news-preview', item: { previewType: 'basic', item: i, sizing: index ? 'tall' : '' } })) })
        if (items[9]) layouts.push({ layoutName: '2-equal', items: items.slice(9, 11).map(i => ({ type: 'news-preview', item: { previewType: 'basic', item: i } })) })
      })
      return layouts
    },
    getSize (element) {
      return element.offsetWidth
    }
  },
  mounted () {
    this.size = this.getSize(this.$el)
    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    'news-preview': NewsPreview,
    'news-feed-item': NewsFeedItem,
    'news-load-footer': NewsLoadFooter
  }
}
</script>

<template>
  <div
    class="news-feed container-fluid"
    ref="feed"
    :class="sizeClass"
  >
    <div
      v-if="name"
      class="padding-top-1 padding-bottom-0"
    >
      <h3
        v-if="news.items.length === 0 && news.endReached"
        class="no-news text-center text-thin text-muted margin-top-2"
        v-lang.no-news-about="{name: name}"
      />

      <div
        v-else
        class="padding-bottom-1"
      >
        <h2
          class="text-center margin-top-bottom-2"
          v-lang.news-about="{name: name}"
        />
      </div>
    </div>

    <template v-for="(newsLayout, index) in newsLayouts">
      <div
        v-if="newsLayout.layoutName === 'full-width'"
        class="row"
        :key="index"
      >
        <div class="col-12 padding-left-right-0 news-feed-item-wrapper">
          <news-feed-item
            :feedItem="newsLayout.items[0]"
            :queryRelated="isQueryRelated"
          />
        </div>
      </div>

      <div
        v-else-if="newsLayout.layoutName === '2-equal'"
        class="row"
        :key="index"
      >
        <news-feed-item
          :feedItem="newsLayout.items[0]"
          :queryRelated="isQueryRelated"
          :class="{ 'col-12': size < breakpoints.sm, 'col-6': size >= breakpoints.sm }"
          class="no-side-padding-xxs no-side-padding-xs news-feed-item-wrapper no-padding-left-min-sm"
          maxWidth="xs"
        />

        <news-feed-item
          v-if="newsLayout.items[1]"
          :feedItem="newsLayout.items[1]"
          :queryRelated="isQueryRelated"
          :class="{ 'col-12': size < breakpoints.sm, 'col-6': size >= breakpoints.sm }"
          class="no-side-padding-xxs no-side-padding-xs news-feed-item-wrapper no-padding-right-min-sm"
          maxWidth="xs"
        />
      </div>

      <div
        v-else-if="newsLayout.layoutName === '3-fourths-1-fourth'"
        class="row"
        :key="index"
      >
        <news-feed-item
          :feedItem="newsLayout.items[0]"
          :queryRelated="isQueryRelated"
          :class="{ 'col-12': size < breakpoints.sm, 'col-7': size >= breakpoints.sm && size < breakpoints.md, 'col-9': size >= breakpoints.md }"
          class="no-side-padding-xxs no-side-padding-xs news-feed-item-wrapper no-padding-left-min-sm"
          maxWidth="sm"
        />

        <news-feed-item
          v-if="newsLayout.items[1]"
          :feedItem="newsLayout.items[1]"
          :queryRelated="isQueryRelated"
          :class="{ 'col-12': size < breakpoints.sm, 'col-5': size >= breakpoints.sm && size < breakpoints.md, 'col-3': size >= breakpoints.md }"
          class="no-side-padding-xxs no-side-padding-xs news-feed-item-wrapper no-padding-right-min-sm"
          maxWidth="xs"
        />
      </div>

      <div
        v-else-if="newsLayout.layoutName === '3-equal'"
        class="row"
        :key="index"
      >
        <news-feed-item
          v-for="(item, i) in newsLayout.items"
          :key="i"
          :feedItem="item"
          :queryRelated="isQueryRelated"
          :class="{ 'col-12': size < breakpoints.sm, 'col-4': size >= breakpoints.sm, 'no-padding-right-min-sm': i == 2, 'no-padding-left-min-sm': i == 0 }"
          class="no-side-padding-xxs no-side-padding-xs news-feed-item-wrapper"
          maxWidth="sm"
        />
      </div>

      <div
        v-else-if="newsLayout.layoutName === '2-thirds-1-third'"
        class="row"
        :key="index"
      >
        <news-feed-item
          :feedItem="newsLayout.items[0]"
          :queryRelated="isQueryRelated"
          :class="{ 'col-12': size < breakpoints.sm, 'col-8': size >= breakpoints.sm }"
          class="no-side-padding-xxs no-side-padding-xs news-feed-item-wrapper no-padding-left-min-sm"
          maxWidth="xs"
        />

        <news-feed-item
          v-if="newsLayout.items[1]"
          :feedItem="newsLayout.items[1]"
          :queryRelated="isQueryRelated"
          :class="{ 'col-12': size < breakpoints.sm, 'col-4': size >= breakpoints.sm }"
          class="no-side-padding-xxs no-side-padding-xs news-feed-item-wrapper no-padding-right-min-sm"
          maxWidth="xs"
        />
      </div>

      <div
        v-else-if="newsLayout.layoutName === '1-third-2-thirds'"
        class="row"
        :key="index"
      >
        <news-feed-item
          :feedItem="newsLayout.items[0]"
          :queryRelated="isQueryRelated"
          :class="{ 'col-12': size < breakpoints.sm, 'col-4': size >= breakpoints.sm }"
          class="no-side-padding-xxs no-side-padding-xs news-feed-item-wrapper no-padding-left-min-sm"
          maxWidth="xs"
        />

        <news-feed-item
          v-if="newsLayout.items[1]"
          :feedItem="newsLayout.items[1]"
          :queryRelated="isQueryRelated"
          :class="{ 'col-12': size < breakpoints.sm, 'col-8': size >= breakpoints.sm }"
          class="no-side-padding-xxs no-side-padding-xs news-feed-item-wrapper no-padding-right-min-sm"
          maxWidth="xs"
        />
      </div>

      <div
        v-else-if="newsLayout.layoutName === 'equal-2-rows-1-row'"
        class="row"
        :key="index"
      >
        <div
          :class="{ 'col-12': size < breakpoints.sm, 'col-6': size >= breakpoints.sm }"
          class="no-side-padding-xxs no-side-padding-xs no-padding-left-min-sm"
        >
          <news-feed-item
            :feedItem="newsLayout.items[0]"
            :queryRelated="isQueryRelated"
            class="news-feed-item-wrapper"
            maxWidth="xs"
          />

          <news-feed-item
            v-if="newsLayout.items[1]"
            :feedItem="newsLayout.items[1]"
            :queryRelated="isQueryRelated"
            class="news-feed-item-wrapper"
            maxWidth="xs"
          />
        </div>

        <div
          :class="{ 'col-12': size < breakpoints.sm, 'col-6': size >= breakpoints.sm }"
          class="no-side-padding-xxs no-side-padding-xs no-padding-right-min-sm"
        >
          <news-feed-item
            v-if="newsLayout.items[2]"
            :feedItem="newsLayout.items[2]"
            :queryRelated="isQueryRelated"
            class="news-feed-item-wrapper"
            maxWidth="xs"
          />
        </div>
      </div>

      <div
        v-else-if="newsLayout.layoutName === '2-columns-3-fourths-1-fourth'"
        class="row"
        :key="index"
      >
        <div
          :class="{ 'col-12': size < breakpoints.sm, 'col-7': size >= breakpoints.sm && size < breakpoints.md, 'col-9': size >= breakpoints.md }"
          class="no-side-padding-xxs no-side-padding-xs no-padding-left-min-sm"
        >
          <news-feed-item
            :feedItem="newsLayout.items[0]"
            :queryRelated="isQueryRelated"
            class="news-feed-item-wrapper"
            maxWidth="sm"
          />

          <news-feed-item
            v-if="newsLayout.items[1]"
            :feedItem="newsLayout.items[1]"
            :queryRelated="isQueryRelated"
            class="news-feed-item-wrapper"
            maxWidth="sm"
          />
        </div>

        <div
          :class="{ 'col-12': size < breakpoints.sm, 'col-5': size >= breakpoints.sm && size < breakpoints.md, 'col-3': size >= breakpoints.md }"
          class="no-side-padding-xxs no-side-padding-xs no-padding-right-min-sm"
        >
          <news-feed-item
            v-if="newsLayout.items[2]"
            :feedItem="newsLayout.items[2]"
            :queryRelated="isQueryRelated"
            class="news-feed-item-wrapper"
            maxWidth="xs"
          />

          <news-feed-item
            v-if="newsLayout.items[3]"
            :feedItem="newsLayout.items[3]"
            :queryRelated="isQueryRelated"
            class="news-feed-item-wrapper"
            maxWidth="xs"
          />
        </div>
      </div>
    </template>

    <div
      v-if="!hideFooter"
      class="row"
    >
      <news-load-footer
        v-if="news.items"
        :news="news"
        :loading="loading"
        @load-more="$emit('load-more')"
        class="col"
      />
    </div>
  </div>
</template>

<style lang="less">
  .news-feed-item-wrapper {
    margin-bottom: 30px;
  }

  .news-feed {
    max-width: 1170px;

    &.xxs {
      .no-side-padding-xxs { padding-left: 0; padding-right: 0 }
      .content-box {
        border-radius: 0;
      }
    }

    &.xs {
      .no-side-padding-xs { padding-left: 0; padding-right: 0 }
    }

    &.sm {
      .no-padding-left-sm {
        padding-left: 0;
      }

      .no-padding-right-sm {
        padding-right: 0;
      }

      .no-side-padding-sm { padding-left: 0; padding-right: 0 }
    }

    &.md {
      .no-padding-left-md {
        padding-left: 0;
      }

      .no-padding-right-md {
        padding-right: 0;
      }
    }

    &.lg {
      .no-padding-left-lg {
        padding-left: 0;
      }

      .no-padding-right-lg {
        padding-right: 0;
      }
    }
  }

</style>
