<script>
export default {
  name: 'SelectableTag',
  props: {
    tagGroup: { type: Object }
  },
  data () {
    return {
      test: new Date().getTime()
    }
  },
  methods: {
    toggleTag: function () {
      if (this.tagGroup.tag.canTag) {
        this.$emit('onToggle', this.tagGroup.tag.id)
        this.test = new Date().getTime() // this is a hack to make the UI update
      }
    }
  },
  computed: {
    actionClass: function () {
      this.test // hack to make the ui update due to dependency-change
      switch (this.tagGroup.action) {
        case 'none':
          switch (this.tagGroup.entitiesWithTag) {
            case 'none': return 'none'
            case 'some': return 'partially-selected'
            case 'all': return 'selected'
          }
          throw Error('invalid tag group')
        case 'add': return 'selected'
        case 'remove': return 'none'
      }
      return true
    },
    note () {
      this.test
      switch (this.tagGroup.action) {
        case 'add': return this.translate('adding')
        case 'remove': return this.translate('removing')
        default: return ''
      }
    }
  },
}
</script>

<template>
  <div
    class="selectable-tag ellipsis"
    v-if="tagGroup"
    :class="[actionClass, tagGroup.tag.canTag ? '' : 'disabled']"
    @click="toggleTag"
    :title="tagGroup.tag.canTag ? '' : 'Ejeren af listen tillader ikke ændringer'"
  >
    <div class="action-icon">
      <!-- <b class="minus">&ndash;</b> -->
      <i class="fa fa-check" />
      <div class="check-box" />
    </div>
    <span
      style="flex: 1"
      class="ellipsis"
    >{{ tagGroup.tag.name }}<i
      v-show="tagGroup.tag.followTag"
      class="fa fa-rss"
    /></span><span class="note text-muted">{{ note }}</span>
  </div>
</template>

<style lang="less">
  .selectable-tag {
    border: 1px solid #d1d1d1;
    margin-top: 10px;
    font-size: 15px;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    // line-height: 40px;
    margin-right: 10px;
    cursor: pointer;

    .action-icon {
      margin-right: 10px;
      width: 18px;
      text-align: center;
      position: relative;

      .fa-check, .minus {
        display: none;
        position: absolute;
        z-index: 1;
      }
      .fa-check {
        top: 3px;
      }
      .minus {
        left: 4px;
        top: 2px;
        font-size: 14px;
      }
    }

    i.fa-rss {
      margin-left: 8px;
      color: #8f979c;
      font-size: 13px;
    }

    .note {
      margin-left: 8px;
      font-size: 13px;
    }

    &.disabled {
      color: #777
    }

    &.selected {
      // background-color: #ffa07a;
      // color: #fff;

      // border-color: #ffa07a !important;

      .action-icon .fa-check {
        display: inline;
      }
    }

    &.partially-selected .action-icon {
      color: #8F979C;
    }
    &.partially-selected .action-icon .fa-check {
      display: inline;
      border-color: #ffa07a;
    }


    & .action-icon .check-box {
      display: inline-block;
      position: relative;
      top: 3px;
      border: 1px solid #dfdfdf;
      border-radius: 2px;
      height: 13px;
      width: 13px;
      background-color: #fff;
    }
  }
</style>
