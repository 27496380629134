<template>
	<span class="toggle-button-wrapper" :class="{ on: value, off: !value }">
		<span v-if="offLabel" v-html="offLabel" class="off-label" />
    <div class="toggle-button" :class="{ on: value, off: !value, disabled: disabled }" :style="style" @click="onToggle">
			<div class="track" :style="trackStyle"></div>
			<div class="handle" :style="handleStyle"></div>
    </div>
		<span v-if="onLabel" v-html="onLabel" class="on-label" />
	</span>
</template>

<script>
export default {
	props: {
		value: { type: Boolean, required: true },
		height: { type: Number, required: false, default: 17 },
		disabled: { type: Boolean, default: false },
		offLabel: String,
		onLabel: String
	},
	computed: {
		style () { return { height: this.height + 'px', width: this.height * 1.9 + 'px' } },
		trackStyle () { return { height: this.height / 2 + 'px', 'border-radius': this.height / 2 / 2 + 'px' } },
		handleStyle () { return { height: this.height + 'px', width: this.height + 'px', 'border-radius': this.height / 2 + 'px' } }
	},
	methods: {
		onToggle () {
			if (!this.disabled) this.$emit('toggled')
		}
	}
}
</script> 

<style lang="less" scoped>
	.toggle-button-wrapper {
		display: flex;
		align-items: center;

		.off-label {
			margin-right: 10px;
		}

		.on-label {
			margin-left: 10px;
		}

		.on-label, .off-label {
			color: #8E979C;
			font-size: 13px;
		}

		&.on .on-label {
			color: #3D464A;
		}

		&.off .off-label {
			color: #3D464A;
		}

		.toggle-button {
			display: flex;
			align-items: center;
			position: relative;
			cursor: pointer;

			.track {
				background-color: #C7C7C7;
				flex: 1;
			}

			.handle {
				position: absolute;
				top: 0;
				transition: all .2s ease-out;
			}

			&.on {
				padding-right: 0px;
				.handle {
					background-color: #019a84;
					// right: 0;
					left: 50%
				}

				.track {
					background-color: #99D7CE;
				}

				&.disabled {
					.handle {
						background-color: #99d7ce;
					}
				}
			}
			&.off {
				padding-left: 0px;
				.handle {
					background-color: #eee;
					left: 0;
				}
				&.disabled {
					.handle {
						background-color: #c8d5d3;
					}
				}
			}

			&.disabled {
				cursor: default;
				.track {
					background-color: #d6efeb;
				}
			}
		}
	}
</style>
