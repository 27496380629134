<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { TooltipOptions } from '../tooltips'
import { unwatchRect, watchRect } from '../util/watch-rect'
import { isOneOf } from '../util/type-guards'

const isPlacement = (v: unknown) => isOneOf<TooltipOptions['placement']>(v, [ 'auto', 'top', 'left', 'right', 'bottom' ])

@Component({})

export default class Tooltip extends Vue {
  @Prop({ type: HTMLElement })
  readonly el?: TooltipOptions['el']

  @Prop({ type: String, required: true })
  readonly text!: TooltipOptions['text']

  @Prop({ type: String, validator: isPlacement, default: 'auto' })
  readonly placement!: Exclude<TooltipOptions['placement'], undefined>

  @Prop({ type: [ Number, Object ], default: 4 })
  readonly offset: TooltipOptions['offset']

  @Prop({ type: Boolean })
  readonly overrideShow?: boolean

  mousedOver = false
  show = false
  boundsRect: DOMRect | null = null
  elRect: DOMRect | null = null

  get finalShow () {
    return this.overrideShow ?? this.show
  }

  get fOffset (): Record<'top' | 'left' | 'right' | 'bottom', number> {
    if (typeof this.offset === 'number') return { top: this.offset, left: this.offset, right: this.offset, bottom: this.offset }
    const { all, x, y, top, left, right, bottom } = this.offset ?? {}
    return {
      top: top ?? y ?? all ?? 4,
      left: left ?? x ?? all ?? 4,
      right: right ?? x ?? all ?? 4,
      bottom: bottom ?? y ?? all ?? 4,
    }
  }

  get style () {
    let placement = this.placement

    let maxWidth = 300
    let maxHeight: number | null = null
    const transforms: string[] = []

    if (this.elRect) {
      const rect = this.elRect

      const elXLeft = rect.left - this.fOffset.left
      const elXCenter = rect.left + rect.width / 2
      const elXRight = rect.left + rect.width + this.fOffset.right
      const elYTop = rect.top - this.fOffset.top
      const elYCenter = rect.top + rect.height / 2
      const elYBottom = rect.top + rect.height + this.fOffset.bottom

      if (this.boundsRect) {
        const bounds = {
          top: this.boundsRect.top,
          left: this.boundsRect.left,
          right: this.boundsRect.left + this.boundsRect.width,
          bottom: this.boundsRect.top + this.boundsRect.height,
        }

        const space = {
          xLeft: Math.min(elXLeft - bounds.left, maxWidth),
          xCenter: Math.min(Math.min(elXCenter - bounds.left, bounds.right - elXCenter) * 2, maxWidth),
          xRight: Math.min(bounds.right - elXRight, maxWidth),
          yTop: elYTop - bounds.top,
          yCenter: Math.min(elYCenter - bounds.top, bounds.bottom - elYCenter) * 2,
          yBottom: bounds.bottom - elYBottom,
        }

        if (placement === 'auto') {
          if (space.xCenter >= Math.max(space.xLeft, space.xRight)) {
            placement = space.yTop > space.yBottom ? 'top' : 'bottom'
          } else {
            placement = space.xLeft > space.xRight ? 'left' : 'right'
          }
        }

        switch (placement) {
          case 'top':
          case 'bottom':
            maxWidth = space.xCenter
            maxHeight = placement === 'top' ? space.yTop : space.yBottom
            break

          case 'left':
          case 'right':
            maxWidth = placement === 'left' ? space.xLeft : space.xRight
            maxHeight = space.yCenter
            break
        }
      }

      if (placement === 'auto') placement = 'bottom'

      switch (placement) {
        case 'top':
        case 'bottom':
          transforms.push(
            `translateX(${elXCenter}px)`,
            'translateX(-50%)',
            `translateY(${placement === 'top' ? elYTop : elYBottom}px)`,
          )
          if (placement === 'top') transforms.push('translateY(-100%)')
          break

        case 'left':
        case 'right':
          transforms.push(
            `translateX(${placement === 'left' ? elXLeft : elXRight}px)`,
            `translateY(${elYCenter}px)`,
            'translateY(-50%)',
          )
          if (placement === 'left') transforms.push('translateX(-100%)')
          break
      }
    }

    return {
      maxWidth: `${maxWidth}px`,
      maxHeight: maxHeight !== null ? `${maxHeight}px` : '',
      opacity: this.finalShow ? '1' : '0',
      transform: transforms.join(' '),
    }
  }

  mounted () {
    this.$watch(
      () => typeof this.overrideShow !== 'boolean' ? this.el : null,
      (newEl, oldEl) => {
        if (oldEl) {
          oldEl.removeEventListener('mouseenter', this.onElMouseEnter)
          oldEl.removeEventListener('mouseleave', this.onElMouseLeave)
        }

        if (newEl) {
          newEl.addEventListener('mouseenter', this.onElMouseEnter)
          newEl.addEventListener('mouseleave', this.onElMouseLeave)
        }

        this.mousedOver = false
        this.show = false
      },
      { immediate: true },
    )

    this.$watch(
      () => !!(this.finalShow && this.el),
      show => {
        if (!show) unwatchRect(this.$root.$el as HTMLElement, this.onBoundsRectChanged)
        else watchRect(this.$root.$el as HTMLElement, this.onBoundsRectChanged)
      },
      { immediate: true },
    )

    this.$watch(
      () => this.finalShow ? this.el : null,
      (newEl, oldEl) => {
        if (oldEl) unwatchRect(oldEl, this.onElRectChanged)
        if (newEl) watchRect(newEl, this.onElRectChanged)
      },
      { immediate: true },
    )
  }

  beforeDestroy () {
    unwatchRect(this.$root.$el as HTMLElement, this.onBoundsRectChanged)

    if (this.el) {
      this.el.removeEventListener('mouseenter', this.onElMouseEnter)
      this.el.removeEventListener('mouseleave', this.onElMouseLeave)
      unwatchRect(this.el, this.onElRectChanged)
    }
  }

  onBoundsRectChanged (rect: DOMRect) {
    this.boundsRect = rect
  }

  onElRectChanged (rect: DOMRect) {
    this.elRect = rect
  }

  onElMouseEnter () {
    this.mousedOver = true
    this.show = true
  }

  onElMouseLeave () {
    this.mousedOver = false
    requestAnimationFrame(() => {
      if (!this.mousedOver) this.show = false
    })
  }
}
</script>

<template>
  <div
    v-if="finalShow && el"
    class="lassox-portal__Tooltip"
    :style="style"
    v-text="text"
  />
</template>

<style lang="scss">
.lassox-portal__Tooltip {
  position: fixed;
  top: -100vh;
  left: -100vw;
  top: 0;
  left: 0;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  background-color: #616161;
  color: white;
  box-shadow: 0 2px 5px 0 rgba(black, 0.1);
  pointer-events: none;
  transition: opacity 100ms;
}
</style>
