<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import Icon from '@/components/Icon.vue'

@Component({
  components: {
    Icon,
  },
})

export default class SearchBarSuggestion extends Vue {
  @Prop({ type: Array })
  classes?: string[] | undefined

  @Prop({ type: String, required: true })
  text!: string

  @Prop({ type: String })
  supertext?: string

  @Prop({ type: String })
  subtext?: string

  @Prop({ type: String })
  secondarySubtext?: string

  @Prop({ type: String })
  leadingIcon?: string

  @Prop({ type: String })
  trailingIcon?: string
}
</script>

<template>
  <button
    class="lassox-portal__SearchBarSuggestion"
    :class="classes"
    v-on="$listeners"
  >
    <Icon
      v-if="leadingIcon"
      class="lassox-portal__SearchBarSuggestion-icon"
      :icon="leadingIcon"
      size="small"
    />

    <div class="lassox-portal__SearchBarSuggestion-text-wrapper">
      <div
        v-if="supertext"
        class="lassox-portal__SearchBarSuggestion-supertext"
        v-text="supertext"
      />

      <div
        class="lassox-portal__SearchBarSuggestion-text"
        v-text="text"
      />

      <div
        v-if="subtext || secondarySubtext"
        class="lassox-portal__SearchBarSuggestion-subtext-wrapper"
      >
        <div
          v-if="subtext"
          class="lassox-portal__SearchBarSuggestion-subtext"
          v-text="subtext"
        />

        <div style="margin: auto" />

        <div
          v-if="secondarySubtext"
          class="lassox-portal__SearchBarSuggestion-secondary-subtext"
          v-text="secondarySubtext"
        />
      </div>
    </div>

    <Icon
      v-if="trailingIcon"
      class="lassox-portal__SearchBarSuggestion-icon"
      :icon="trailingIcon"
      size="small"
    />
  </button>
</template>

<style lang="scss" scoped>
@import '@shared/style/global.scss';

.lassox-portal__SearchBarSuggestion {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 32px;
  padding: 0 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  appearance: none;
  border: none;
  background-color: white;

  &-icon {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    margin: -1px 0;
  }

  &-text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 0;
    flex-grow: 1;
    flex-shrink: 1;
    padding: 10px 0;
    white-space: nowrap;
  }

  &-icon + &-text-wrapper,
  &-text-wrapper + &-icon {
    margin-left: 12px;
  }

  &-text,
  &-supertext,
  &-subtext,
  &-secondary-subtext {
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }

  &-text,
  &-supertext,
  &-subtext-wrapper {
    &:not(:first-child) {
      margin-top: 4px;
    }
  }

  &-text {
    font-size: 12px;
    line-height: 12px;
  }

  &-supertext,
  &-subtext,
  &-secondary-subtext {
    font-size: 10px;
    line-height: 12px;
  }

  &-subtext-wrapper {
    display: flex;
  }

  &-subtext,
  &-secondary-subtext {
    min-width: 0;
  }

  &-subtext {
    flex-grow: 1;
    flex-shrink: 1;
  }

  &-secondary-subtext {
    flex-grow: 0;
    flex-shrink: 0;
  }

  &-subtext ~ &-secondary-subtext {
    margin-left: 8px;
  }
}

.lassox-portal__SearchBar-suggestion--active .lassox-portal__SearchBarSuggestion {
  background-color: getScaleColor('gray', 100);
  outline: none;
}

.lassox-portal__SearchBarSuggestion:active {
  background-color: getScaleColor('gray', 200);
}
</style>
