<script>
import NewsFeed from '@/components/news/NewsFeed'
import { EventBus } from '@/assets/js/helpers.js'
import HomeSimple from './HomeSimple.vue'
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Home',
  data () {
    return {
      loading: false,
      loadingNews: false,
      loadingMoreNews: false
    }
  },
  computed: {
    news () { return this.$store.getters.news('home') },
    simple () { return this.$store.state.readOnly && this.$store.state.productAccess }
  },
  methods: {
    load () {
      this.loading = true
      this.$store.dispatch('getListNews', { cacheKey: 'home', forceRefresh: false, page: 1 }).then(() => {
        this.loading = false
      })
    },
    onLoadMoreNews () {
      this.loadingMoreNews = true
      this.$store.dispatch('getListNews', { cacheKey: 'home', forceRefresh: false, page: this.news.page + 1 }).then(() => { this.loadingMoreNews = false })
    },
    onStorySelected (story) {
      EventBus.$emit('modal', { modalType: 'story', story: story })
    }
  },
  mounted () {
    this.load()
  },
  components: {
    NewsFeed
  }
}
</script>

<template>
  <HomeSimple v-if="simple" />
  <div
    v-else
    id="home"
    v-bar
    class="min-max-height dark dragger-wide"
  >
    <div class="min-max-height">
      <div
        v-if="!loading"
        style="padding: 0 0 50px 0"
      >
        <div
          class="welcome"
          v-if="$store.state.user"
        >
          <h2>
            <span v-if="$store.state.lastLogin">{{ translate($store.state.user.name ? 'welcome-back' : 'welcome-back-no-name', { name: $store.state.user.name ? $store.state.user.name.split(' ')[0] : undefined }) }}</span>
            <span v-else>{{ translate($store.state.user.name ? 'welcome' : 'welcome-no-name', {name: $store.state.user.name ? $store.state.user.name.split(' ')[0] : undefined }) }}</span>
          </h2>

          <p
            v-if="$store.state.followed && $store.state.followed.length"
            class="text-muted"
            v-lang.welcome-text
          />

          <p
            v-else
            class="text-muted"
            v-lang.welcome-no-followed
          />
        </div>

        <div class="news-wrapper">
          <news-feed
            v-if="news"
            :news="news"
            feedType="list"
            :loading="loadingNews || loadingMoreNews"
            @story-selected="onStorySelected"
            @load-more="onLoadMoreNews"
          />
        </div>
      </div>

      <div
        v-else
        class="loading-logo min-max-height"
      >
        <img src="../assets/img/x_transparent_64x64.png">
        <h1 v-lang.loading-home />
        <h2><span v-lang.please-wait />..</h2>
      </div>
    </div>
  </div>
</template>

<style lang="less">
  #home {
    display: flex;
    flex-direction: column;
    background-color: #fff;

    .loading-logo {
      flex: 1;
      width: 100%;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h1 {
        margin: 30px 0 0 0;
        font-size: 22px;
        font-weight: 500;
        color: #222;
      }

      h2 {
        font-size: 15px;
        color: #777;
        margin: 20px 0 0 0;
        font-weight: 400;
      }
    }

    .welcome {
      max-width: 1200px;
      margin: 30px auto 0 auto;
      padding: 0 15px;

      h2 {
        margin-top: -4px;
        margin-bottom: 16px - 4px;
        color: #212121;
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
      }

      p {
        margin: -4px 0;
      }

      @media only screen and (min-width: 797px) {
        padding: 0 30px;
      }

      @media only screen and (min-width: 992px) {
        padding: 0 15px;
      }

      @media only screen and (min-width: 1050px) {
        padding: 0 30px;
      }
    }

    .news-wrapper {
      padding: 30px 15px 0 15px;
      h2 {
        font-size: 22px;
        margin: 50px 0;
      }
    }
  }
</style>
