<script>
import { EventBus, Users } from '@/assets/js/helpers'
import TagList from '@/components/TagList'
import Icon from '@/components/Icon.vue'
import getAppIcon from '@shared/types/IconsMap.ts'
import DraggableApps from '@/components/draggables/DraggableApps.vue'
import windowSize from '@/window-size.ts'
import BaseButton from '@/components/BaseButton.vue'
import IconButton from '@/components/IconButton.vue'
import TagIcon from '@/components/TagIcon.vue'
import svgLogo from '../assets/img/svg/lasso_logo.svg'
import stripLeadingEmojiFromText from '@/util/stripLeadingEmojiFromText.ts'
import stringStartsWithEmoji from '@/util/stringStartsWithEmoji.ts'
import PremiumFeatureOverlay from './overlays/PremiumFeatureTrialOverlay.vue'
import { showTabOverlay } from '@/util/overlay.ts'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Sidebar',
  data () {
    return {
      lastChange: new Date().getTime(), // hack to listen to localStorage,
      hideExpiration: false,
      appsCollapsed: true,
      bookDemoHidden: false,
      EventBus: EventBus,
      draggableApps: [],
      draggableAppsPromise: null,
      showTagsSideBar: false,
      appTilesCollapsed: true,
      companyTagTilesCollapsed: true,
      personTagTilesCollapsed: true,
      svgLogo: svgLogo,
      scrolledTop: false,
      stripLeadingEmojiFromText: stripLeadingEmojiFromText,
      stringStartsWithEmoji: stringStartsWithEmoji,
    }
  },
  computed: {
    collapsed: {
      get () { return this.$store.state.sidebarCollapsed },
      set (value) {
        this.$store.state.sidebarCollapsed = value
      }
    },
    pinned: {
      get () { return this.$store.state.sidebarPinned },
      set (value) { this.$store.commit('setSidebarPinned', value) }
    },
    sidebarText: function () {
      if (this.isFreemium) return 'Lasso'
      if (this.$store.state.user.organization?.name) return this.$store.state.user.organization?.name
      return this.$store.state.user.name
    },
    contentWrapper: function () {
      return document.querySelector('#contentWrapper')
    },
    visibleApps: function () {
      return this.apps.visible
    },
    apps: function () {
      const newsApp = {
        name: 'Nyheder',
        uniqueName: 'news',
      }
      const freemiumApp = {
        name: 'Integrationer & API',
        uniqueName: 'integrations-api',
      }
      const exporterApp = {
        name: 'Dataudtræk',
        uniqueName: 'exporter',
      }

      const result = {
        visible: [],
        hidden: [],
        all: [],
      }

      const apps = [ newsApp, ...this.$store.getters.standaloneApps ]

      if (this.isFreemium) apps.push(...[ exporterApp, freemiumApp ])

      const appsMap = new Map(apps.map(app => [ app.uniqueName, app ]))

      const addedApps = new Set()

      for (const [ array, uniqueNames ] of [
        [ result.visible, this.$store.state.lassoSettings.settings.portal.appsOrder.visible ],
        [ result.hidden, this.$store.state.lassoSettings.settings.portal.appsOrder.hidden ],
      ]) {
        for (const uniqueName of uniqueNames) {
          if (addedApps.has(uniqueName)) continue

          const app = appsMap.get(uniqueName)
          if (!app) continue

          array.push(app)
          addedApps.add(uniqueName)
        }
      }

      for (const [ uniqueName, app ] of appsMap) {
        if (addedApps.has(uniqueName)) continue

        result.visible.push(app)
        this.$store.state.lassoSettings.settings.portal.appsOrder.visible.add(uniqueName)
      }

      result.all = [ ...result.visible, ...result.hidden ]
      return result
    },
    isFreemium () { return this.$store.getters.isFreemium },
    isPinnable () {
      return windowSize.width > windowSize.sizes.md
    },
    isCollapsable: function () {
      return (windowSize.width < windowSize.sizes.md || !this.pinned) && this.isMobile
    },
    isCollapsed: function () {
      return this.isCollapsable && this.collapsed
    },
    tags: function () { return this.$store.getters.tags || [] },
    companyTags: function () {
      const tags = this.tags.filter(t => t.type === 'Company')

      const result = {
        visible: [],
        hidden: [],
        all: [],
      }

      const tagsMap = new Map(tags.map(tag => [ tag.id, tag ]))

      const addedIds = new Set()

      for (const [ array, ids ] of [
        [ result.visible, this.$store.state.lassoSettings.settings.portal.tagsOrder.company.visible ],
        [ result.hidden, this.$store.state.lassoSettings.settings.portal.tagsOrder.company.hidden ],
      ]) {
        for (const id of ids) {
          if (addedIds.has(id)) continue

          const tag = tagsMap.get(id)
          if (!tag) continue

          array.push(tag)
          addedIds.add(id)
        }
      }

      for (const [ id, tag ] of tagsMap) {
        if (addedIds.has(id)) continue

        result.visible.push(tag)
        addedIds.add(id)
        this.$store.state.lassoSettings.settings.portal.tagsOrder.company.visible.add(id)
      }

      result.all = [ ...result.visible, ...result.hidden ]

      return result
    },
    personTags: function () {
      const tags = this.tags.filter(t => t.type === 'Person')

      const result = {
        visible: [],
        hidden: [],
        all: [],
      }

      const tagsMap = new Map(tags.map(tag => [ tag.id, tag ]))

      const addedIds = new Set()

      for (const [ array, ids ] of [
        [ result.visible, this.$store.state.lassoSettings.settings.portal.tagsOrder.person.visible ],
        [ result.hidden, this.$store.state.lassoSettings.settings.portal.tagsOrder.person.hidden ],
      ]) {
        for (const id of ids) {
          if (addedIds.has(id)) continue

          const tag = tagsMap.get(id)
          if (!tag) continue

          array.push(tag)
          addedIds.add(id)
        }
      }

      for (const [ id, tag ] of tagsMap) {
        if (addedIds.has(id)) continue

        result.visible.push(tag)
        addedIds.add(id)
        this.$store.state.lassoSettings.settings.portal.tagsOrder.person.visible.add(id)
      }

      result.all = [ ...result.visible, ...result.hidden ]

      return result
    },
    appLogoPath () {
      const path = !document.location.host.indexOf('localhost') ? 'https://dev.apps.lassox.com:54311' : !document.location.host.indexOf('staging') ? 'https://staging.apps.lassox.com' : 'https://apps.lassox.com'
      return path + '/appresources/'
    },
    inactive () {
      return !this.$store.state.user.organization.currentSolution.isActive
    },
    today () {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return today
    },
    isMobile () {
      return windowSize.width < windowSize.sizes.md
    },
    isNarrow () {
      return windowSize.width < windowSize.sizes.lg
    },
    modulesOpen: {
      get () {
        return this.$store.state.modulesOpen
      },
      set (value) {
        this.$store.commit('setModulesOpen', value)
        this.lastChange = new Date().getTime()
      }
    },
    companyTagsOpen: {
      get () {
        this.lastChange
        return localStorage.getItem('companyTagsOpen') === null ? true : (localStorage.getItem('companyTagsOpen') === 'true')
      },
      set (value) {
        localStorage.setItem('companyTagsOpen', value)
        this.lastChange = new Date().getTime()
      }
    },
    personTagsOpen: {
      get () {
        this.lastChange
        return localStorage.getItem('personTagsOpen') === null ? true : (localStorage.getItem('personTagsOpen') === 'true')
      },
      set (value) {
        localStorage.setItem('personTagsOpen', value)
        this.lastChange = new Date().getTime()
      }
    },
  },
  methods: {
    closeSidebar (event) {
      if (this.isCollapsable && !this.collapsed && !this.pinned && this.$refs.sidebar) {
        event.stopPropagation()
        this.collapsed = true
      }
    },
    openTagInNewTab (tag) {
      this.$store.dispatch('findMatchingTab', (tab) => tab.route?.name === 'list' && tab.route?.params?.listId == tag.id).then((tab) => {
        if (tab) {
          this.$store.state.activeTab = tab
        } else {
          this.$store.dispatch('openNewTab', this.$router.resolve({ name: 'list', params: { listId: tag.id }, query: this.$route.query }).route)
        }
      })
    },
    onAppClick (app) {
      if (app.uniqueName === 'news') {
        this.$store.dispatch('openNewTab', this.$router.resolve({ name: 'news', query: this.$route.query }).route)
      } else if (app.uniqueName === 'integrations-api') {
        showTabOverlay({ component: PremiumFeatureOverlay, source: 'sidebar_integrationer_api' })
      } else {
        if (this.isFreemium) {
          switch (app.uniqueName) {
            case 'exporter':
              showTabOverlay({ component: PremiumFeatureOverlay, source: 'sidebar_dataudtræk' })
              return
          }
        }
        this.$store.dispatch('openNewTab', this.$router.resolve({ name: 'app', params: { appName: app.uniqueName }, query: this.$route.query }).route)
      }
    },
    appIcon (app) {
      const icon = getAppIcon(app.uniqueName)
      if (typeof icon === 'object') {
        return icon.company
      }
      return icon
    },
    onRouteClick (routeName) {
      this.$store.dispatch('findMatchingTab', (tab) => tab.route?.name === routeName).then((tab) => {
        if (tab) {
          this.$store.state.activeTab = tab
        } else {
          this.$store.dispatch('openNewTab', this.$router.resolve({ name: routeName, query: this.$route.query }).route)
        }
      })
    },
    handleScroll (event) {
      if (event.target.scrollTop > 0) {
        this.scrolledTop = true
        this.$refs.scroll.style.marginTop = '0px'
        this.$refs.scrollChild.style.paddingTop = '0px'
        this.$el.querySelector('.sidebar-header').classList.add('sidebar-header-shadow')
        return
      }
      this.scrolledTop = false
      this.$refs.scroll.style.marginTop = '-24px'
      this.$refs.scrollChild.style.paddingTop = '24px'
      this.$el.querySelector('.sidebar-header').classList.remove('sidebar-header-shadow')
    },
    async hideBookDemo () {
      this.bookDemoHidden = true

      await Users.updateSettings({ bookDemoHidden: Date.now() })
    },
    refreshScrollbar () {
      this.$nextTick(() => {
        this.$vuebar.destroyScrollbar(this.$refs.scroll)
        this.$nextTick(() => this.$vuebar.initScrollbar(this.$refs.scroll, {}))
      })
    },
    onTogglePinned () {
      if (this.isPinnable) {
        this.pinned = !this.pinned
        this.collapsed = !this.pinned
      } else this.collapsed = !this.collapsed
    }
  },
  watch: {
    '$route' () { if (!this.pinned) this.collapsed = true },
    'isCollapsed': 'refreshScrollbar'
  },
  created () {
    if (this.$store.state.user.settings.bookDemoHidden) {
      const today = new Date()
      const timestamp = this.$store.state.user.settings.bookDemoHidden
      if (timestamp < today.setMonth(today.getMonth() - 1)) {
        this.bookDemoHidden = false
        Users.updateSettings({ bookDemoHidden: null })
      } else {
        this.bookDemoHidden = true
      }
    }
    // make sure that we are not showing the sidebar on a small screen, when opening the portal
    if (this.isNarrow && this.isCollapsable) this.collapsed = true
  },
  mounted () {
    EventBus.$on('toggleSidebar', () => {
      if (this.isCollapsable) this.collapsed = !this.collapsed
      this.pinned = false
    })

    if (this.contentWrapper) {
      this.contentWrapper.addEventListener('click', this.closeSidebar)
    }

    this.$store.dispatch('getTags')

    this.$refs.scrollChild.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy () {
    if (this.contentWrapper) {
      this.contentWrapper.removeEventListener('click', this.closeSidebar)
    }
  },
  components: {
    Icon,
    TagList,
    BaseButton,
    DraggableApps,
    IconButton,
    TagIcon,
  }
}
</script>

<template>
  <div
    id="sidebar"
    class="min-max-height"
    ref="sidebar"
    :class="{
      'collapsable': isCollapsable && isMobile,
      'collapsed': isCollapsed,
      'slim': !pinned && !isMobile,
      'narrow': isNarrow,
      'mobile': isMobile,
    }"
  >
    <div class="sidebar-inner">
      <div
        class="sidebar-header"
      >
        <template v-if="pinned || isMobile">
          <div class="logo">
            <img
              src="../assets/img/primary_x.png"
              alt="Lasso X logo"
            >
          </div>

          <div
            class="header-name"
            v-text="sidebarText"
          />
          <!-- :class="[($store.state.user.organization && $store.state.user.organization.name) && 'header-name_uppercase' ]" -->
        </template>

        <div
          v-else
          class="mini-logo"
        >
          <img
            src="../assets/img/primary_x.png"
            alt="Lasso X logo"
          >

          <IconButton
            icon="chevron_double_right_rounded"
            size="normal"
            buttonColor="peach"
            :buttonSize="36"
            @click="event => {
              event.stopPropagation()
              onTogglePinned()
            }"
          />
        </div>

        <div
          v-if="isMobile || pinned"
          class="pin-actions"
        >
          <IconButton
            class="pin-sidebar-icon"
            icon="chevron_double_left_rounded"
            size="normal"
            buttonColor="peach"
            :buttonSize="36"
            :style="{ opacity: isMobile ? 1 : undefined }"
            @click="event => {
              event.stopPropagation()
              onTogglePinned()
            }"
          />
        </div>
      </div>

      <div
        ref="scroll"
        class="content min-max-height dark"
        style="margin-top: -24px;"
        v-bar
      >
        <div
          ref="scrollChild"
          class="min-max-height"
          style="padding-top: 24px;"
        >
          <template v-if="!pinned && !isMobile">
            <div class="tiles">
              <BaseButton
                v-for="app in (appTilesCollapsed ? visibleApps : apps.all)"
                :key="app.uniqueName"
                class="tile app"
                v-tooltip="{ text: app.name, placement: 'right' }"
                @click="onAppClick(app)"
              >
                <Icon :icon="appIcon(app)" />
              </BaseButton>

              <IconButton
                v-if="visibleApps.length < apps.all.length"
                class="tiles-expand-button"
                :icon="appTilesCollapsed ? 'expand_more_rounded' : 'expand_less_rounded'"
                :iconSize="24"
                :buttonSize="36"
                @click="appTilesCollapsed = !appTilesCollapsed"
              />
            </div>

            <div class="tiles-separator" />

            <div class="tiles">
              <BaseButton
                v-for="tag in (companyTagTilesCollapsed ? companyTags.visible : companyTags.all)"
                :key="tag.id"
                class="tile tag"
                v-tooltip="{ text: `Firmaer: ${stripLeadingEmojiFromText(tag.name)}`, placement: 'right' }"
                @click="openTagInNewTab(tag)"
              >
                <TagIcon
                  :tag="tag"
                  type="company"
                  size="large"
                  :hideBorder="true"
                />
              </BaseButton>

              <IconButton
                v-if="companyTags.hidden.length"
                class="tiles-expand-button"
                :icon="companyTagTilesCollapsed ? 'expand_more_rounded' : 'expand_less_rounded'"
                :iconSize="24"
                :buttonSize="36"
                @click="companyTagTilesCollapsed = !companyTagTilesCollapsed"
              />
            </div>

            <div class="tiles-separator" />

            <div class="tiles">
              <BaseButton
                v-for="tag in (personTagTilesCollapsed ? personTags.visible : personTags.all)"
                :key="tag.id"
                class="tile tag"
                v-tooltip="{ text: `Personer: ${tag.name}`, placement: 'right' }"
                @click="openTagInNewTab(tag)"
              >
                <TagIcon
                  :tag="tag"
                  type="person"
                  size="large"
                  :hideBorder="true"
                />
              </BaseButton>

              <IconButton
                v-if="personTags.hidden.length"
                class="tiles-expand-button"
                :icon="personTagTilesCollapsed ? 'expand_more_rounded' : 'expand_less_rounded'"
                :iconSize="24"
                :buttonSize="36"
                @click="personTagTilesCollapsed = !personTagTilesCollapsed"
              />
            </div>
          </template>

          <template v-else>
            <div
              class="app-links"
              v-show="apps.all.length && !isMobile"
              :class="{ 'app-links-collapsed': !modulesOpen }"
              :data-title="$store.getters.isFreemium ? 'Værktøjer og lister' : 'Fleksibel værktøjsbar'"
              :data-intro="$store.getters.isFreemium
                ? 'I menuen til venstre kan du altid se et overblik over værktøjer og lister, og du kan folde den ud/ind efter behov.'
                : 'Lav din foretrukne rækkefølge ved at trække rundt på elementernes ikoner, og skjul dem du sjældent bruger.'"
              data-step="5"
              data-position="bottom"
            >
              <BaseButton
                class="toggle-btn"
                buttonColor="peach"
                @click="modulesOpen = !modulesOpen"
              >
                <span>VÆRKTØJER</span>
                <Icon :icon="modulesOpen ? 'expand_less_rounded' : 'expand_more_rounded' " />
              </BaseButton>

              <DraggableApps
                v-if="modulesOpen"
                :apps="apps.all"
                :onAppClick="onAppClick"
              />
            </div>

            <tag-list
              :class="{ 'tag-list-collapsed': !companyTagsOpen }"
              :tags="companyTags.all"
              tagType="company"
              :title="translate('companies').toUpperCase()"
              max-height="250px"
              :open="companyTagsOpen"
              @toggle-open="open => companyTagsOpen = open"
              :disableCreate="$store.state.readOnly"
            />

            <tag-list
              :class="{ 'tag-list-collapsed': !personTagsOpen }"
              :tags="personTags.all"
              tagType="person"
              :title="translate('people').toUpperCase()"
              max-height="250px"
              :open="personTagsOpen"
              @toggle-open="open => personTagsOpen = open"
              :disableCreate="$store.state.readOnly"
            />
          </template>
        </div>
      </div>

      <template v-if="!(!pinned && !isMobile)">
        <div
          id="inactive"
          v-if="inactive"
        >
          <h3>{{ translate('expired') }}</h3>
          <p v-lang.expired-description="{ subscriptionType: this.translate($store.state.user.organization.currentSolution.trial ? 'trial' : 'subscription'), timesince: $options.filters.timeSince($store.state.user.organization.currentSolution.toDate, translate) }" />
          <button
            class="btn btn-default"
            style="color: #616161"
            v-lang.activate-now
            @click="onAppClick({ uniqueName: 'lassosettings' })"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="less">
#sidebar {
  align-items: center;
  font-size: 14px;
  background-color: #f9f9f9;
  overflow: hidden;
  transition: width 200ms;

  &, .sidebar-inner {
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 100%;
  }

  &:hover {
    .sidebar-header .mini-logo {
      img {
        opacity: 0;
      }
    }
  }

  .content {
    flex: 1;
    position: relative;
    margin-top: 0px;
    overflow-y: auto;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .min-max-height {
    max-height: 100%;
    height: 100%;
  }

  // .vb-content {
  //   display: flex !important;
  //   justify-content: flex-start !important;
  //   flex-direction: column !important;
  // }

  .sidebar-header {
    display: flex;
    align-items: center;
    height: 56px;
    padding-left: 24px;
    padding-right: 24px;
    position: relative;
    z-index: 1;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.08);
    transition: box-shadow 100ms;

    &-shadow {
      box-shadow: 0 1px 10px 1px rgba(50, 50, 50, 0.10);
    }

    .logo {
      width: 36px;
      height: 36px;
      border-radius: 8px;
      overflow: hidden;
      flex-shrink: 0;

      > img {
        width: 100%;
        height: 100%;
      }
    }

    .header-name {
      padding-left: 16px;
      font-size: 14px;
      line-height: 20px;
      flex-grow: 1;
      color: #616161;
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: uppercase;
      white-space: nowrap;
      font-weight: 500;
    }

    .mini-logo {
      position: relative;
      z-index: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 36px;
      height: 36px;
      margin: auto;
      border-radius: 8px;
      overflow: hidden;
      // background-color: #FFF;

      img {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        opacity: 1;
        pointer-events: none;
        transition: opacity 100ms;
      }

      .lassox-portal__IconButton {
        position: relative;

        &_button {
          border-radius: 8px;

          .md-icon {
            color: #FFA07A;
          }

          &:hover,
          &:focus,
          &:active {
            .lassox-portal__BaseButton_outline {
              color: #FFA07A;
            }
          }
        }
      }
    }

    span.organization-name  {
      color: #262626;
      font-weight: 500;
      font-size: 14px;
      flex: 1 1 auto;
      max-width: 250px;
    }

    .pin-actions {
      .pin-sidebar-icon {
        margin: 6px;
        opacity: 0;

        .lassox-portal__IconButton_button {
          border-radius: 8px;

          .md-icon {
            color: #FFA07A;
          }

          &:hover,
          &:focus,
          &:active {
            .lassox-portal__BaseButton_outline {
              color: #FFA07A;
            }
          }
        }
      }

      .pin-sidebar-icon,
      .collapse-sidebar-icon {
        transition: color 100ms, opacity 100ms;
      }
    }
  }

  position: relative;
  left: 0;

  &:hover {
    .sidebar-header {
      .pin-actions {
        .pin-sidebar-icon,
        .collapse-sidebar-icon {
          opacity: 1;
        }
      }
    }
  }

  &.collapsable {
    min-height: 100%;
    position: absolute;
    min-height: 100%;
    z-index: 99;
    left: 0;
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.16);
    transition: left 250ms ease-in-out;

    &.collapsed {
      left: -320px;
    }
  }

  &.slim {
    position: relative;

    &, .sidebar-inner {
      width: 36px + (16px * 2);
    }

    .sidebar-header {
      padding: 0;
      width: unset;
    }

    .vb .vb-dragger {
      z-index: 0 !important;
      // right: 8px !important;
    }

    .book-demo-tile {
      width: 36px;
      height: 36px;
      margin: 0 auto;
      margin-bottom: 26px;
      border-radius: 8px;
      background: url('../assets/img/freemium-blob.png') no-repeat;
      background-size: 160%;
      background-position: top left;
      background-color: #FEF9F8;
      box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .md-icon {
        color: #262626;
      }
    }
  }

  &.mobile {
    .sidebar-header {
      height: auto;
      padding: 24px;
    }
  }

  .tiles {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    .tile {
      width: 36px;
      height: 36px;
      border-radius: 8px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      box-shadow: 0px 0px 36px rgba(0, 0, 0, 0.08);

      &.tag {
        .custom-icon {
          height: 20px;
          width: 20px;
          font-weight: 500;
          font-size: 18px;
          display: flex;
          justify-content: center;
          align-items: center;

          &.with-circle {
            font-size: 10px;
            border-radius: 50%;
            border: 2px solid #616161;
          }
        }
      }
    }
  }

  .tiles-expand-button {
    margin: 6px;

    .lassox-portal__IconButton_button {
      border-radius: 8px;
    }
  }

  .tiles .tile,
  .tiles-expand-button .lassox-portal__BaseButton {
    &:hover,
    &:focus,
    &:active {
      .lassox-portal__BaseButton_outline {
        color: #616161;
      }
    }
  }

  .tiles-separator {
    width: 36px;
    height: 1px;
    margin: 16px auto;
    background-color: #F1F1F1;
  }

  .tiles:last-child {
    margin-bottom: 16px;
  }

  .app-links, .tag-list {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .toggle-btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 32px;
      padding: 8px;
      margin: -8px;
      border-radius: 8px;
      font-weight: 500;

      .md-icon {
        color: #ffa07a;
      }
    }

    .lassox-portal__DraggableApps,
    .lassox-portal__DraggableTags {
      margin-top: 8px + 8px + 4px;
    }

    .title {
      display: flex;
      align-items: center;
      padding: 8px 28px;
      cursor: pointer;
      letter-spacing: 1px;
      justify-content: space-between;
      align-items: center;

      h4 {
        margin: 0;
        font-weight: 500;
        font-size: 14px;
        text-transform: uppercase;
      }
    }

    padding: 16px;
    margin: 24px;
    margin-top: 0;
    background: white;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
  }

  .show-more-button {
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #262626;
    padding: 8px;
    border-radius: 8px;
    margin-top: 6px;
    transition: background 150ms ease-in-out;
    background: transparent;

    &:hover {
      cursor: pointer;
      background: #F1F1F1;
    }

    > span {
      margin-right: 6px;
      font-weight: 500;
    }

    .md-icon {
      color: #262626;
    }
  }

  .book-demo-banner {
    width: calc(300px - 48px);
    margin: 24px;
    border-radius: 8px;
    color: #3a4146;
    position: relative;
    font-family: 'Roboto', sans-serif;
    padding: 0px 18px 20px 18px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    background: url('../assets/img/freemium-blob.png') no-repeat;
    background-size: 160%;
    background-position: top left;
    background-color: #FEF9F8;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);

    & .close {
      // flex-basis: 100%;
      margin-left: auto;
      margin-right: -13px;
      margin-bottom: -3px;
      margin-top: 5px;
    }

    & > h3 {
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: #262626;
      padding-bottom: 24px;
    }

    & #background-image {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      width: 100%;
    }

    & > *:not(#background-image) {
      z-index: 2;
      position: relative;
    }

    & button {
      font-size: 12px;
      line-height: 20px;
      font-weight: 500;
      margin-left: auto;
      border: none;
      outline: none;
      height: 32px;
      border-radius: 16px;
      padding: 6px 24px;
      background: #FFA07A;
      color: #FFF;
      &:hover {
        cursor: pointer;
      }
    }
  }

  #inactive, #expiring {
    // margin: 30px;
    padding: 30px;
    border-radius: 2px;
    background-color: #ffa07a;
    color: #fff;

    h3 {
      font-size: 16px;
      margin: 0 0 10px 0;
      font-weight: 500;

      i {
        margin-right: 10px;
      }
    }

    p {
      margin: 0;

      a {
        color: #fff;
        text-decoration: underline;
      }
    }

    .close {
      // right: 15px;
      top: -5px;
      position: relative;
    }

    .btn {
      margin-top: 25px;
    }
  }

  .app-links {
    .app-link {
      font-size: 14px;
      line-height: 16px;
      padding: 12px 0;
      // padding-left: 8px;
      display: flex;
      align-items: center;
      cursor: pointer;
      font-weight: 500;
      color: #262626;

      &.router-link-exact-active {
        // color: #8f979c !important;

        i.fa.fa-circle {
          display: inline-block;
        }
      }

      &:hover {
        color: #ffa07a;
      }

      i {
        margin-left: 15px;
        // color: white;
        font-size: 13px;

        &.fa.fa-circle {
          display: none;
          margin-right: 10px;
          margin-left: -14.3px;
          font-size: 5px;
          line-height: 14px;
        }
      }

      img {
        height: 20px;
        width: 20px;
        border-radius: 10px;
        margin-right: 12px;
      }
    }
  }

  // .vb > .vb-dragger {
  //   right: 13px !important;
  // }
}
</style>
