<script>
import Vue from 'vue'
import ExpandableResume from '@/components/news/ExpandableResume'
export default {
  name: 'PersonProfile',
  data () {
    return {
      resume: {
        content: 'Kenneth Douglas Plummer er registreret med sin første erhvervsrolle i et aktieselskab i 1996, hvor han i maj blev registreret i bestyrelsen for MATTEL SCANDINAVIA A/S, som er stiftet d. 01.07.1992. Kenneth blev indsat i bestyrelsen sammen med Wolf Salzer, Peter K Eichorn og William Layne Bracy, og han havde posten indtil d. 30.06.1999. I dag er firmaet registreret under navnet MATTEL NORTHERN EUROPE A/S.<br><br>' +
          'Kenneth er registreret som ejer af 2 virksomheder. Han ejer dem alle direkte (dvs. ikke gennem holdingfirmaer) - men er ikke registreret med 100% ejerskab på nogen af disse virksomheder. Et af hans firmaer er BHS BERLIN II A/S - som han ejer 10-15% af. Dette firma lavede i seneste regnskab (2016) et resultat på -10.000 kr. og har en egenkapital på 1,76 mio. kr.',
        shortVersion: true,
        firstName: 'Kenneth Douglas',
        lastName: 'Plummer',
        lassoId: 'CVR-3-4003997338'
      }
    }
  },
  computed: {
    name: function () {
      return this.resume.firstName + ' ' + this.resume.lastName
    },
    intials: function () {
      var allInitials = this.name.split(' ').map(s => (s.substring(0, 1))).join('').toUpperCase()
      if (allInitials.length > 3) return allInitials.slice(0, 1) + allInitials.slice(2)
      else return allInitials
    },
    profileComponent: function () {
      return { template: '<span>' + Vue.filter('linkify')(this.profile, false) + '</span>' }
    }
  },
  mounted: function () {
    // Entity.resume(this.lassoId, true).then(resume => { this.resume = resume })
  },
  components: {
    'expandable-resume': ExpandableResume
  }
}
</script>

<template>
  <div class="person-profile content-box">
    <div class="person-header">
      <div class="picture-wrapper">
        <div class="picture" />
      </div>
      <div class="title-name ellipsis">
        <h5 class="title text-red ellipsis">
          FREMHÆVET PERSON
        </h5>
        <h3
          class="name ellipsis"
          @click="$root.trackEvent({ eventCategory: 'Click', eventAction: 'Fremhævet person', eventLabel: name })"
        >
          <router-link :to="{ name: 'person', params: { id: resume.lassoId.substring(6) }}">
            {{ name }}
          </router-link>
        </h3>
      </div>
    </div>
    <div class="content">
      <expandable-resume
        v-if="resume"
        :resume="resume"
        :expandable="true"
        :goToByExpanding="true"
      />
    </div>
  </div>
</template>

<style lang="less" scoped>
  .person-profile {
    padding: 30px;
    .person-header {
      display: flex;
      .picture-wrapper {
        padding-right: 20px;
        .picture {
          // background-image: url(../assets/img/kenneth_douglas_plummer.jpg);
          background-size: contain;
          width: 70px;
          height: 70px;
          border-radius: 50%;
          // background: #f3f3f3;
          color: rgba(34, 34, 34, .4);
          font-size: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .title-name {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        h5 {
          margin: 0 0 5px 0;
        }
        h3 {
          margin: 0;
          font-size: 27px;
          a {
            color: #222;
            &:hover {
              text-decoration: none;
              color: #ffa07a;
            }
          }
        }
      }

    }
    .content {
      margin-top: 30px;
      p {
        line-height: 26px;
      }
    }
  }

</style>
