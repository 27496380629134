<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import Button from '@/components/Button.vue'
import { Wormhole } from 'portal-vue'
import DropdownMenu from './DropdownMenu.vue'
import type { DropdownMenuItems } from '@/models/dropdown-menu-items'
import type { CreateElement } from 'vue'

let lastPortalId = 0

@Component({
  inheritAttrs: false,

  components: {
    Button,
  },
})

export default class DropdownButton extends Vue {
  @Prop({ type: Array, default: () => [] })
  readonly menuItems!: DropdownMenuItems

  @Prop({ type: Number, default: 0 })
  readonly dropdownSpacing!: number

  @Prop({ type: Object })
  readonly dropdownStyle?: Record<string, string>

  @Prop({ type: String })
  readonly hAlign?: 'left' | 'right'

  @Prop({ type: String })
  readonly vAlign?:  | 'above' | 'below'

  @Prop({ type: String })
  readonly preferHAlign?: 'left' | 'right'

  @Prop({ type: String })
  readonly preferVAlign?:  | 'above' | 'below'

  @Prop({ type: Boolean, default: true })
  readonly borderRadiusEffect!: boolean

  @Prop({ type: String, default: 'default' })
  readonly submenuStyle!: 'default' | 'dots'

  portalId = `dropdown-button-${lastPortalId++}`
  buttonEl: HTMLElement | null = null

  get isOpen () {
    return !!this.buttonEl
  }

  openWormhole () {
    const { isOpen, onClick, toggle, open, close } = this
    const dropdownMenuSlot = this.$scopedSlots['dropdown-menu']

    Wormhole.open({
      to: 'menus',
      from: this.portalId,
      passengers: [
        () => this.buttonEl
          ? this.$createElement(
            DropdownMenu,
            {
              props: {
                items: this.menuItems,
                borderRadiusEffect: this.borderRadiusEffect,
                submenuStyle: this.submenuStyle,
                dropdownSpacing: this.dropdownSpacing,
                dropdownStyle: this.dropdownStyle,
                preferHAlign: this.preferHAlign,
                preferVAlign: this.preferVAlign,
                hAlign: this.hAlign,
                vAlign: this.vAlign,
                opener: this.buttonEl,
                close: () => this.buttonEl = null,
              },
              scopedSlots: {
                // Det giver nemlig ingen mening det her, fordi before-menu-items er ikke et scoped slot.. ..... men det er dropdown-menu? Så er det vel same same?
                // Don't worry about it.
                'before-menu-items': () => this.$slots['before-menu-items'],
              },
            },
            dropdownMenuSlot?.({ isOpen, onClick, toggle, open, close }),
          )
          : this.$createElement(),
      ],
    })
  }

  closeWormhole () {
    Wormhole.close({
      to: 'menus',
      from: this.portalId,
    })
  }

  created () {
    this.$watch(
      () => !!this.buttonEl,
      show => {
        if (show) this.openWormhole()
        else this.closeWormhole()
      },
      { immediate: true },
    )
  }

  // beforeUpdate () {
  //   this.openWormhole()
  // }

  beforeDestroy () {
    this.closeWormhole()
  }

  onClick (event: MouseEvent) {
    const buttonEl = event.currentTarget instanceof HTMLElement
      ? event.currentTarget
      : null

    if (!buttonEl) this.close()
    else this.toggle(buttonEl)
  }

  toggle (buttonEl: HTMLElement) {
    if (buttonEl === this.buttonEl) this.close()
    else this.open(buttonEl)
  }

  open (buttonEl: HTMLElement) {
    this.buttonEl = buttonEl
  }

  close () {
    this.buttonEl = null
  }

  render (h: CreateElement) {
    const { isOpen, onClick, toggle, open, close } = this

    const slot = this.$scopedSlots.default
    if (slot) return slot({ isOpen, onClick, toggle, open, close })

    return h(Button, {
      attrs: {
        trailingIcon: isOpen ? 'expand_less' : 'expand_more',
        ...this.$attrs,
      },
      on: {
        click: (e: MouseEvent) => {
          e.stopPropagation()
          onClick(e)
        },
        ...this.$listeners,
      }
    })
  }
}
</script>
