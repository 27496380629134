<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import BaseButton from './BaseButton.vue'

const types = new Set([ 'outlined', 'primary', 'flat', 'outlined-primary', 'flat-primary' ])

@Component({
  components: {
    BaseButton,
  },
})

export default class Button extends Vue {
  @Prop({ type: String, validator: (type: string) => types.has(type), default: 'outlined' })
  readonly type!: string

  @Prop({ type: String })
  readonly label?: string

  @Prop({ type: String })
  readonly icon?: string

  @Prop({ type: String })
  readonly leadingIcon?: string

  @Prop({ type: String })
  readonly trailingIcon?: string

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean
}
</script>

<template>
  <BaseButton
    :class="[
      'lassox-portal__Button',
      `lassox-portal__Button--type-${type}`,
    ]"
    v-bind="{ label, icon, leadingIcon, trailingIcon, disabled }"
    v-on="$listeners"
  />
</template>

<style lang="scss">
.lassox-portal__Button {
  min-width: 64px;
  height: 32px;
  padding: 0 16px;
  border-radius: 16px;

  --primary-color: #ffa07a;
  .design2024 & {
    padding: 0 20px;
    --primary-color: var(--dark-peach);
  }

  .lassox-portal__BaseButton {
    &_leading-icon {
      margin-left: -4px;
    }

    &_trailing-icon {
      margin-right: -4px;
    }
  }

  &--type {
    &-outlined .lassox-portal__BaseButton_outline {
      color: rgba(black, 0.12);
    }

    &-flat {
      color: #616161;
    }

    &-outlined-primary,
    &-flat-primary {
      color: var(--primary-color);

      .lassox-portal__BaseButton_overlay {
        background-color: rgba(#ff7138, 0.2);
      }
    }

    &-outlined-primary .lassox-portal__BaseButton_outline {
      color: var(--primary-color);
    }

    &-primary {
      color: white;
      background-color: var(--primary-color);

      .lassox-portal__BaseButton_overlay {
        background-color: rgba(#ff7138, 0.8);
      }
    }
  }
}
</style>
