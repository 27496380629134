export default {
    'cancel': 'Fortryd',
    'confirm': 'Bekræft',
    'save': 'Gem',
    'companies': 'firmaer',
    'company': 'firma',
    'the-company': 'firmaet',
    'the-companies': 'firmaerne',
    'people': 'personer',
    'person': 'person',
    'the-person': 'personen',
    'the-people': 'personerne',
    'companies-and-people': 'firmaer og personer',
    'loading': 'Indlæser',
    'loading-home': 'Indlæser nyheder',
    'home-page': 'forsiden',
    'welcome': 'Velkommen, {name}!',
    'welcome-no-name': 'Velkommen!',
    'welcome-back': 'Velkommen tilbage, {name}!',
    'welcome-back-no-name': 'Velkommen tilbage!',
    'welcome-text': 'Her er hvad der er sket for de firmaer og personer du overvåger, siden dit sidste besøg.',
    'welcome-no-followed': 'Overvåg personer og firmaer, og få dem vist i din nyhedsstrøm nedenfor.',
    'lead-of-the-day': 'Dagens emne',
    'lead-of-the-day-finding': 'Finder dagens emne',
    'please-wait': 'Vent venligst',
    'employees': 'ansatte',
    'add-to-list': 'Tilføj til liste',
    'add-to-the-list': 'Tilføj til listen',
    'add-to-lists': 'Tilføj til lister',
    'edit-lists': 'Rediger lister',
    'remove': 'Fjern',
    'latest-account': 'Seneste regnskab',
    'no-recent-news': 'Her vil det seneste regnskab blive vist - men der er ikke nogen nylige regnskaber i din nyhedsstrøm.',
    'currently-no-accounts': 'Ingen regnskaber i øjeblikket',
    'not-satisfying': 'Ikke tilfredsstillende',
    'satisfying': 'Tilfredsstillende',
    'good': 'Godt',
    'not-available': 'Ikke tilgængelig',
    'download-report': 'Hent regnskab',
    'network': 'netværk',
    'not-related-network': '{name} er ikke relateret til andre personer gennem sine roller i CVR registret',
    'selected-person': 'Udvalgt person fra den seneste nyhed',
    'connections-with': '{connections} forbindelser med ',
    'worked-with': 'Arbejdede sammen fra {year} i ',
    'follow': 'Overvåg',
    'unfollow': 'Overvåg ikke',
    'following': 'Overvåger',
    'not-following': 'Overvåger ikke',
    'roi': 'Afkastningsgrad',
    'solidity': 'Soliditetsgrad',
    'revenue': 'Omsætning',
    'grossProfitLoss': 'Bruttofortjeneste',
    'profitLossFromOrdinaryActivitiesBeforeTax': 'Resultat før skat',
    'profitLoss': 'Årets resultat',
    'equity': 'Egenkapital',
    'liabilitiesAndEquity': 'Balance',
    'company-status-warning': 'BEMÆRK! VIRKSOMHEDENS NUVÆRENDE STATUS ER',
    'share-with-others': 'DEL MED ANDRE',
    'select-lists-to-add': 'Vælg de lister som {entityType} skal tilføjes til.',
    'select-lists-to-add-remove': 'Vælg de lister som {entityType} skal tilføjes eller fjernes fra.',
    'edit-list-name': 'Rediger listens navn',
    'edit-list-name-description': 'Giv listen et nyt navn ved at angive det nedenfor',
    'list-name': 'Listens navn',
    'enter-a-name': 'Angiv et navn',
    'news-about': 'Nyheder om {name}',
    'no-news-about': 'Ingen nyheder om {name}',
    'loading-more-news': 'Henter flere nyheder',
    'no-more-news': 'Ikke flere nyheder',
    'show-more-news': 'Vis flere nyheder',
    'receive-notifications': 'Modtag notifikationer',
    'receive-emails': 'Modtag e-mails',
    'list-monitor-description': 'Modtag notifikationer og e-mails når der sker en hændelse for {entityType} på denne liste. Vælg de ønskede hændelser nedenfor.',
    'list-monitor-additional-actions': 'Der er opsat yderligere handlinger på denne liste vha. ',
    'list-no-additional-actions': 'Opsæt flere e-mails, webhooks og integrationer vha. ',
    'additional-actions': 'Yderligere handlinger',
    'webhooks-and-integrations': 'Webhooks og integrationer',
    'monitoring-teaser': 'Få flere muligheder',
    'the-monitor-module': 'overvågningsmodulet',
    'try-monitoring': 'Flere hændelser, webhooks og integrationer til dit CRM- eller dialer-system. Med overvågnings-modulet kan du meget mere! Kontakt os på tlf. 71747812 for at høre nærmere.',
    'follow-list': 'Overvåg listen',
    'follow-company-list-description': 'Overvåg denne liste af virksomheder og bliv notificeret når de sker relaterede opdateringer i CVR.',
    'follow-person-list-description': 'Overvåg denne liste af personer og bliv notificeret når der sker opdateringer i CVR på relaterede virksomheder.',
    'receive-notifications-description': 'Du modtager notifikationer og ser nyheder om listens {entityType} på ',
    'import-modal-description': 'Vi fandt {count} i fil(erne). Tilføj til listen \'{name}\' nu?',
    'upload-failed': 'Upload mislykkedes',
    'import-description': 'Upload en eller flere filer med CVR-numre. Kolonnen med CVR-numre skal have titlen \'cvr\'. ',
    'release-to-upload': 'Slip for at uploade',
    'drag-files-here': 'Træk filer herind eller',
    'or': 'eller',
    'select-files': 'Vælg filer',
    'file': 'fil',
    'files': 'filer',
    'file-no-cvrs': 'De valgte {fileOrFiles} indeholder ingen CVR-numre.',
    'list-limit-reached': 'Listenbegrænsning nået',
    'list-limit-description': 'De valgte filer indeholder desværre så mange {entityType}, at den samlede listestørrelse overstiger de tilladte {limit} {entityType}.',
    'import-confirm-description-single-file': 'Den valgte fil indeholder {count} {entityType}. Tilføj til listen \'{listname}\' nu?',
    'import-confirm-description-multiple-files': 'De valgte filer indeholder {count} {entityType}. Tilføj til listen \'{listname}\' nu?',
    'missing-cvr-column': 'Den valgte fil mangler kolonnen \'cvr\'',
    'file-not-valid-single': 'Den valgte fil var ikke gyldig.',
    'file-not-valid-multiple': 'En af de valgte filer var ikke gyldig.',
    'news': 'Nyheder',
    'follow-tag-follow-description': 'Denne liste indeholder dine fulgte {entityType}. Når du trykker "Overvåg" på {entityType2} i Lasso, vil de blive tilføjet til denne liste.',
    'follow-tag-share-description': 'Denne liste indeholder dine fulgte {entityType}, og kan ikke deles. Ønsker du at dele en liste med dine kollegaer, opret da en anden liste.',
    'not-shared': 'Ikke delt',
    'shared-list': 'Delt liste',
    'share-list': 'Del listen',
    'share-list-description': 'Dine kollegaer vil fremover kunne se og redigere denne liste. Er du sikker?',
    'stop-sharing': 'Stop deling',
    'stop-sharing-description': 'Dine kollegaer vil ikke længere kunne se og redigere denne liste. Er du sikker?',
    'edit': 'Rediger',
    'import': 'Importer',
    'export': 'Eksporter',
    'export-description': 'Vælg det udtræk du ønsker at lave for listen.',
    'no-person-exports': 'Du har ingen tilgængelige udtræk der virker for personer',
    'rename-list': 'Omdøb listen',
    'export-list': 'Eksporter listen',
    'export-list-description': 'Du er ved at eksportere listen \'{listname}\'. En eksport af typen \'{exportname}\' vil blive sendt til din e-mail.',
    'delete-list': 'Slet listen',
    'delete-list-description': 'Du er ved at slette listen \'{listname}\'. Denne handling kan ikke fortrydes.',
    'clear-list': 'Ryd listen',
    'clear-list-description': 'Du er ved at fjerne alle entiteter fra listen \'{listname}\'. Denne handling kan ikke fortrydes.',
    'list-deleted': 'Listen blev slettet',
    'empty-list': 'Listen er tom',
    'notifications': 'Notifikationer',
    'settings': 'Indstillinger',
    'settings-saved': 'Dine indstillinger blev gemt',
    'no-more-notifications': 'Ikke flere notifikationer',
    'more': 'flere',
    'searching-companies': 'Søger efter firmaer',
    'searching-people': 'Søger efter personer',
    'no-results': 'Ingen resultater',
    'unknown-address': 'Ukendt adresse',
    'search-lasso-long': 'Søg i Lasso efter firmaer og personer',
    'search-lasso-short': 'Søg i Lasso',
    'adding': 'Tilføjes',
    'removing': 'Fjernes',
    'followed-companies': 'Overvågede firmaer',
    'followed-people': 'Overvågede personer',
    'export-starting': 'Starter eksport..',
    'export-initializing': 'Forbereder eksport..',
    'export-in-progress': 'Eksport i gang..',
    'export-finishing': 'Klargører..',
    'export-finished': 'Eksport færdig. <b>Klik for at åbne!</b>',
    'export-failed': 'Fejl under eksport',
    'export-completion': 'Din eksport er {percentage}% færdig',
    'export-cancelled': 'Afbrudt',
    'export-cancelling': 'Stopper..',
    'no-lists': 'Du har endnu ingen {entityType}lister',
    'enter-list-name': 'Indtast navn',
    'create-new-list': 'Opret ny liste',
    'create-list': 'Opret liste',
    'create': 'Opret',
    'search-create-list': 'Søg efter eller opret ny liste',
    'creating': 'Opretter',
    'save-changes': 'Gem ændringer',
    'remove-from-all-tags': 'Fjern fra alle lister',
    'remove-from-all-tags-description': 'Dette fjerner de valgte {entityType} fra alle lister. Denne handling kan ikke fortrydes.',
    'changes-saved': 'Dine ændringer blev gemt',
    'no-lists-with-text': 'Ingen lister der indeholder teksten \'{text}\'',
    'enter-list-name-to-create': 'Indtast et navn ovenfor for at oprette en liste',
    'share-tag-title': 'Del listen med andre i din organisation',
    'share-with-organization': 'Del med organisation',
    'list-visible': 'Listen bliver synlig for alle brugere',
    'shared-by-other-user': 'Listen er delt af {name}, som administrerer deling',
    'share': 'Del',
    'shared': 'Delt',
    'another-user': 'en anden bruger',
    'visible-to-you': 'Listen er kun synlig for dig',
    'visible-to-colleagues': 'Listen er synlig for dine kollegaer',
    'address-copied': 'Adressen blev kopieret til din udklipsholder',
    'read-only': 'Din bruger har begrænsede beføjelser, og kan ikke foretage ændringer i Lasso.',
    'direct-link': 'Du benytter en kompakt version af Lasso. Åbn portalen for at se dine lister mm.',
    'direct-link-read-only': 'Du er logget ind anonymt med begrænsede beføjelser. Log ind med e-mail for at få se lister mm.',
    'open-portal': 'Åbn portalen',
    'log-in': 'Log ind',
    'profile': 'Profil',
    'account-settings': 'Kontoindstillinger',
    'sign-out': 'Log ud',
    'sign-out-description': 'Log ud af Lasso?',
    'sign-in-description': 'Log ind med e-mail?',
    'signed-out': 'Du blev logget ud',
    'signed-out-description': 'Du er blevet logget ud af Lasso via et andet vindue. Log venligst ind igen.',
    'sign-in-other-user': 'Logget ind med anden bruger',
    'sign-in-other-user-description': 'Du er logget ind i et andet vindue med en anden bruger. Tryk "Fortsæt" for at benytte denne bruger.',
    'continue': 'Fortsæt',
    'companies-were-added': '{count} firmaer blev tilføjet',
    'company-was-added': '{count} firma blev tilføjet',
    'export-queued': 'Eksport sat i kø',
    'export-queued-description': 'Eksporten sættes i gang om lidt..',
    'list-shared': 'Listen er nu delt',
    'list-not-shared': 'Listen er ikke længere delt',
    'now-following': 'Overvåger nu {name}',
    'no-longer-following-list': 'Overvåger ikke længere listen',
    'now-following-list': 'Overvåger nu listen',
    'list-renamed': 'Listen blev omdøbt',
    'remove-from-following': 'Fjern fra overvågning',
    'logged-in-elsewhere': 'Du blev logget ud',
    'logged-in-elsewhere-description': 'Din Lasso bruger er i brug et andet sted, og du logges derfor ud.<br><br>Kontakt Lasso på tlf. 71 74 78 12 hvis du ønsker flere licenser eller muligheden for samtidige logins.',
    'close-window': 'Luk vinduet',
    'log-in-again': 'Log ind igen',
    'list-not-found': 'Listen blev ikke fundet',
    'see-example': 'Hent eksempel.',
    'could-not-be-found': '{entity} kunne ikke findes',
    'actions': 'Handlinger',
    'remove-from-list': 'Fjern fra listen',
    'remove-from-list-description': 'Er du sikker på at du vil fjerne {count} {entityType} fra listen?',
    'expired': 'Dit abonnement er udløbet',
    'expired-description': 'Dit {subscriptionType} udløb for {timesince}. Aktiver dit abonnement for fortsat at bruge Lasso.',
    'expiring': 'Udløber om {days}',
    'expiring-description': 'Dit {subscriptionType} udløber om {days}. <a>Aktiver dit abonnement</a> for at undgå at miste din adgang til Lasso.',
    'second': 'sekund',
    'seconds': 'sekunder',
    'minute': 'minut',
    'minutes': 'minutter',
    'hour': 'time',
    'hours': 'timer',
    'day': 'dag',
    'days': 'dage',
    'month': 'måned',
    'months': 'måneder',
    'year': 'år',
    'years': 'år',
    'yesterday': 'I går',
    'ago': 'siden',
    'trial': 'prøveabonnement',
    'subscription': 'abonnement',
    'activate-now': 'Aktiver nu',
    'import-error': 'Import mislykkedes',
    'import-error-description-message': 'Det lykkedes ikke at importere din fil. Importen fejlede med beskeden: "{error}"',
    'invalid-number-provided': 'De følgende CVR-numre er ikke gyldige: {numbers}',
    'edit-notifications': 'Tilpas notifikationer',
    'no-account-reported': 'Regnskabet er ikke indberettet digitalt. Hent regnskabet for at se nøgletal.',
    'dpa-title': 'Accept af databehandleraftale',
    'dpa-description': 'For at du og dine kollegaer fortsat skal have adgang til Lassos portal, hvor du kan lave kundelister og overvåge personer i dansk erhvervsliv, skal du ifølge ny EU-lovgivning (General Data Protection Regulation - <b>GDPR</b>) acceptere vores databehandleraftale. Du kan læse vores generelle betingelser <a href="https://apps.lassox.com/assets/docs/terms_conditions.pdf" target="_blank">her</a>. Hvis du har spørgsmål, er du velkommen til at kontakte os på <a href="mailto:contact@lassox.com" target="_blank">contact@lassox.com</a>.',
    'i-accept-dpa-terms': 'Jeg har læst og accepterer databehandleraftalen',
    'save-accept': 'Gem accept',
    'ask-later': 'Spørg mig senere',
    'thank-you-terms': 'Tak - din accept er nu gemt!',
    'list-edit-disabled-description': "Vælg {entityType} for at overvåge og redigere deres lister",
    'use-search-bar': 'Brug søgebaren i toppen til at søge efter {entityTypes}',
    'enter-email': 'Indtast e-mail',
    'receive-email-notifications': 'Modtag notifikationer på e-mail',
    'enter-email-description': 'Når du følger personer og virksomheder kan du blive notificeret når der sker ændringer. Indtast din e-mail for at modtage notifikationer om adresseændringer, ejerskift, årsrapporter og meget mere.',
    'excel-exports': 'Exceludtræk'
}
