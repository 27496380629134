<script setup lang="ts">
import { store } from '@/assets/js/store'
import { removedUseCaseTrackNames, useCaseButtons } from '@/types/QuestionaireAnswers'
import windowSize from '@/window-size'
import { computed } from 'vue'

const layout = computed(() => {
  if (windowSize.width > 768) return 'desktop'
  return 'mobile'
})

const questionaire = computed(() => store.state.user?.settings?.questionaire ?? null)

const embedUrl = computed(() => {
  if (questionaire.value) {
    const { useCase, userType } = questionaire.value

    const useCaseTrackName = (
      useCaseButtons.find(({ trackName, label }) => useCase === trackName || useCase === label)?.trackName
      || removedUseCaseTrackNames.find(trackName => useCase === trackName)
    )

    const segments = [
      {
        // Lurkers
        embedUrl: 'https://app.opinionx.co/26e0bd8f-8bde-43c4-b265-92734f53f392',
        matches: (
          useCaseTrackName === 'Researche på personer og firmaer af nysgerrighed'
          || userType === 'Privat'
        )
      },
      {
        // Salg
        embedUrl: 'https://app.opinionx.co/045316ad-f5bb-4c60-bfbc-a5d67bcf144a',
        matches: (
          useCaseTrackName === 'Fylde min pipeline op med flere leads'
          || useCaseTrackName === 'Finde leads der matcher mine nuværende kunder'
          || userType === 'Salg & marketing'
        )
      },
      {
        // Finans
        embedUrl: 'https://app.opinionx.co/17e94f73-b3f3-4469-ac00-12a0cda6905c',
        matches: (
          useCaseTrackName === 'Finde og styre investeringer via performance og nøgletal'
          || userType === 'Finans & regnskab'
        )
      },
      {
        // Markedsdata
        embedUrl: 'https://app.opinionx.co/9991ce4f-8302-45f7-a8ef-ea81d7d9fe68',
        matches: (
          useCaseTrackName === 'Udforske relationer, netværk og nyheder'
          || useCaseTrackName === 'Få tillid til potentielle samarbejdspartnere'
          || userType === 'Research & analyse'
          || userType === 'Selvstændig eller konsulent'
        )
      },
    ]

    const matchingSegment = segments.find(({ matches }) => matches)
    if (matchingSegment) return matchingSegment.embedUrl
  }

  return 'https://app.opinionx.co/85fde8d4-8d58-4662-96d3-7dd7ea049898'
})
</script>

<template>
  <div
    :class="[
      'lassox-portal__BrandingSurveyOverlay',
      `lassox-portal__BrandingSurveyOverlay--layout-${layout}`,
    ]"
  >
    <h1 class="lassox-portal__BrandingSurveyOverlay-title">
      Et par hurtige spørgsmål
    </h1>

    <div class="lassox-portal__BrandingSurveyOverlay-embed-container">
      <iframe
        class="lassox-portal__BrandingSurveyOverlay-embed-iframe"
        :src="embedUrl"
      />
    </div>

    <div class="lassox-portal__BrandingSurveyOverlay-description">
      Vi arbejder på at forbedre Lasso. Dine svar på spørgsmålene er en stor hjælp, og vi takker mange gange for, at du vil tage dig tiden til at svare!
    </div>
  </div>
</template>

<style lang="scss">
.lassox-portal__BrandingSurveyOverlay {
  display: grid;
  grid-auto-rows: auto;
  width: 100%;
  min-height: 100%;

  & > * {
    grid-column: 2;
  }

  &-title {
    margin-top: auto;
    color: black;
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
  }

  &-embed-container {
    border: 1px solid #F1F1F1;
    border-radius: 16px;
    overflow: hidden;
  }

  &-embed-iframe {
    width: 100%;
    height: 100%;
    border: none;
  }

  &-description {
    margin-bottom: auto;
    color: #616161;
    font-size: 13px;
    line-height: 20px;
  }

  &--layout-mobile {
    grid-template-rows: auto minmax(480px, 515px) auto;
    grid-template-columns: auto 100% auto;
    padding-top: 91px;
    padding-inline: 16px;
    padding-bottom: 32px;
  }

  &--layout-mobile &-title {
    padding-inline: 17px;
    margin-bottom: 32px;
  }

  &--layout-mobile &-embed-container {
    padding-inline: 16px;
  }

  &--layout-mobile &-description {
    padding-inline: 16px;
    margin-top: 40px;
  }

  &--layout-desktop {
    grid-template-rows: auto 480px auto;
    grid-template-columns: auto min(100%, 700px) auto;
    padding-top: 103px;
    padding-inline: 32px;
    padding-bottom: 64px;
  }

  &--layout-desktop &-title {
    padding-inline: 4px;
    margin-bottom: 48px;
  }

  &--layout-desktop &-embed-container {
    padding-inline: 56px;
  }

  &--layout-desktop &-description {
    padding-inline: 15px;
    margin-top: 40px;
  }
}
</style>
