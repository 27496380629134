import Vue from 'vue'
import { isOneOf, isRecord } from './util/type-guards'

export interface TooltipOptions {
  el?: HTMLElement
  text: string
  placement?: TooltipPlacement
  offset?: TooltipOffset
  overrideShow?: boolean
}

export type TooltipPlacement = 'auto' | 'top' | 'left' | 'right' | 'bottom'

export type TooltipOffset = number | {
  all?: number
  x?: number
  y?: number
  top?: number
  left?: number
  right?: number
  bottom?: number
}

export interface TooltipRootStore {
  tooltipsSet: Set<TooltipOptions>
  tooltips: TooltipOptions[]
  add: (tooltip: TooltipOptions) => void
  remove: (tooltip: TooltipOptions) => void
}

const stores = new Map<Vue, TooltipRootStore>()

export const getStore = (vueRoot: Vue) => {
  const cached = stores.get(vueRoot)
  if (cached) return cached

  const store: TooltipRootStore = Vue.observable({
    tooltipsSet: new Set,
    tooltips: [],
    add: tooltip => {
      if (store.tooltipsSet.has(tooltip)) return

      store.tooltipsSet.add(tooltip)
      store.tooltips.push(tooltip)
    },
    remove: tooltip => {
      if (!store.tooltipsSet.has(tooltip)) return

      store.tooltipsSet.delete(tooltip)

      const index = store.tooltips.indexOf(tooltip)
      if (index === -1) return

      store.tooltips.splice(index, 1)
    },
  })

  stores.set(vueRoot, store)
  return store
}

export const isPlacement = (v: unknown): v is TooltipPlacement => isOneOf<TooltipPlacement>(v, [ 'auto', 'top', 'left', 'right', 'bottom' ])

export const isOffset = (v: unknown): v is TooltipOffset => {
  if (typeof v === 'number') return true

  if (isRecord<keyof Exclude<TooltipOffset, number>>(v)) {
    for (const key of [ 'all', 'x', 'y', 'top', 'left', 'right', 'bottom' ] as const) {
      const value = v[key]
      if (value !== undefined && typeof value !== 'number') return false
    }
    return true
  }

  return false
}
