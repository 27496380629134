<script lang="ts">
import { Vue, Component, Prop, Model } from 'vue-property-decorator'
import Button from './Button.vue'

interface Tab {
  id: string
  label: string
}

@Component({
  components: {
    Button,
  }
})
export default class TabBar extends Vue {
  @Prop({ type: Array, required: true })
  readonly tabs!: Tab[]

  @Model('change', { type: String, default: undefined })
  readonly activeTabId!: string

  get tabsMap () {
    return new Map(this.tabs.map(tab => [ tab.id, tab ]))
  }

  get activeTab () {
    return this.tabsMap.get(this.activeTabId ?? '')
  }

  set activeTab (tab: Tab | undefined) {
    if (tab?.id === this.activeTabId) return
    this.$emit('change', tab?.id)
  }
}
</script>

<template>
  <div class="lassox-portal__TabBar-wrapper">
    <Button
      v-for="tab in tabs"
      :key="tab.id"
      class="lassox-portal__TabBar-wrapper-button"
      :class="{
        'lassox-portal__TabBar-wrapper-button--active': tab === activeTab
      }"
      :type="tab === activeTab ? 'primary' : 'flat'"
      :label="tab.label"
      @click="activeTab = tab"
    />
  </div>
</template>

<style lang="scss">
.lassox-portal__TabBar-wrapper {
  padding: 2px;
  background-color: #F1F1F1;
  border-radius: 32px;
  display: flex;
  gap: 8px;
  position: relative;
  height: 36px;

  &-button {
    flex-grow: 1;
    flex-shrink: 0;
    border-radius: 32px;

    .lassox-portal__BaseButton_label {
      font-size: 14px;
      line-height: 16px;
    }

    &.lassox-portal__Button--type-flat .lassox-portal__BaseButton_label {
      color: #B7B7B7;
    }

    // color: #B7B7B7;

    // &--active {
    //   background-color: #FFA07A;
    //   color: white;
    // }
  }
}

</style>
