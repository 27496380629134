<script lang="ts">
import { unwatchRect, watchRect } from '@/util/watch-rect'
import { Vue, Component, Model, Ref, Prop } from 'vue-property-decorator'
import LassoDraggable from './LassoDraggable.vue'
import Icon from '@/components/Icon.vue'
import { Group, Item } from '@/models/draggable-items'

interface CGroup {
  type: 'group'
  originalGroup: Group
  id: string
  text: string
  order: number
  emptyPlaceholderText?: string
  isEmpty: boolean
  height: number
  top: number
  screenTop: number
  dragTop: number | null
  hideLabel?: boolean
  hidden?: boolean
}

interface CItem {
  type: 'item'
  originalItem: Item
  originalGroup: Group | null
  id: string
  text: string
  deletable: boolean
  onClick?: () => void
  icon?: string
  value: any
  order: number
  height: number
  top: number
  screenTop: number
  dragTop: number | null
  hidden?: boolean
}

@Component({
  components: {
    LassoDraggable,
    Icon,
  },
})

export default class Draggables extends Vue {
  //#region props
  @Model('change', { type: [ Array, Object ], default: () => [] })
  readonly value!: Group[] | { items: Item[] }

  @Prop({ type: Boolean, default: false })
  readonly readOnly!: boolean

  @Prop({ type: Number, default: 0 })
  readonly spacing!: number

  @Prop({ type: Boolean, default: true })
  readonly reserveSpaceAfterGroups!: boolean

  @Prop({ type: Boolean, default: false })
  readonly showOnlyGroupOnDrag!: boolean
  //#endregion

  @Ref()
  readonly wrapperEl!: HTMLElement

  //#region data
  currentValue: Draggables['value'] | null = null

  wrapperRect: DOMRect | null = null

  cItems: (CGroup | CItem)[] = []
  // itemEls: HTMLElement[] = []
  draggedItem: CItem | null = null
  initialWrapperTop = 0
  initialMouseY = 0
  initialDraggedItemTop = 0
  mouseY = 0
  disableTransitions = false
  ignoreClick = false

  itemHeight = 32
  //#endregion

  //#region getters
  get isGrouped () {
    return Array.isArray(this.value)
  }

  get sortedItems () {
    return this.cItems.slice().sort((a, b) => a.order - b.order)
  }

  get itemTops () {
    const tops: number[] = []
    let top = 0
    for (const [ i, item ] of this.sortedItems.entries()) {
      tops.push(top)

      if (item.hidden) continue
      if (item.type === 'group' && item.hideLabel) continue

      // if (top) {
      //   tops[i] += this.spacing
      //   top += this.spacing
      // }

      if (this.reserveSpaceAfterGroups && item.type === 'group' && top) {
        top += this.itemHeight + this.spacing
        tops[i] = top
      }

      top += item.height + this.spacing

      if (!this.reserveSpaceAfterGroups && item.type === 'group' && item.isEmpty && !item.hidden && !item.hideLabel) {
        top += this.itemHeight + this.spacing
      }

      // if (this.sortedItems[i + 1]?.type === 'group') top += this.itemHeight
    }
    return tops
  }

  get firstItem () {
    return this.sortedItems.length ? this.sortedItems[0] : null
  }

  get lastItem () {
    let lastItem: CGroup | CItem | null = null
    if (this.sortedItems.length) {
      for (let i = this.sortedItems.length - 1; i >= 0; i--) {
        const item = this.sortedItems[i]
        if (item.hidden) continue
        if (item.type === 'group' && item.hideLabel) continue
        lastItem = item
        break
      }
    }
    return lastItem
  }

  get totalItemHeights () {
    let total = 0

    if (this.lastItem) {
      total += this.lastItem.top + this.lastItem.height
      if (this.lastItem.type === 'group') {
        total += this.itemHeight + this.spacing
      }
    }

    return total
  }

  get wrapperHeight () {
    let height = this.totalItemHeights
    if (this.isGrouped && this.reserveSpaceAfterGroups && this.lastItem?.type !== 'group') height += this.itemHeight + this.spacing
    return height
  }

  get minDragTop () {
    let minDragTop = 0
    if (this.firstItem?.type === 'group' && !this.firstItem.hidden && !this.firstItem.hideLabel) {
      minDragTop += this.firstItem.top + this.firstItem.height + this.spacing
    }
    return minDragTop
  }

  get maxDragTop () {
    return this.totalItemHeights - (this.draggedItem?.height ?? 0)
  }

  get mouseYDelta () {
    const wrapperTopDelta = this.wrapperRect ? this.wrapperRect.top - this.initialWrapperTop : 0
    return this.mouseY - this.initialMouseY - wrapperTopDelta
  }

  get dragTop () {
    return this.initialDraggedItemTop + this.mouseYDelta
  }
  //#endregion

  created () {
    this.$watch(
      () => this.value,
      () => {
        if (this.value === this.currentValue) return

        this.currentValue = this.value

        if (this.draggedItem) {
          for (const item of this.cItems) {
            item.dragTop = null
          }

          this.draggedItem = null
        }

        const vm = this

        const getItemTop = (order: number) => this.itemTops[order] ?? 0
        const getItemScreenTop = (order: number) => (this.wrapperRect?.top ?? 0) + getItemTop(order)

        const mapItems = (items: Item[], group?: Group): CItem[] => items.map((item, order): CItem => {
          return {
            type: 'item',
            originalItem: item,
            originalGroup: group ?? null,
            get id () { return `item-${this.originalItem.id}` },
            get text () { return this.originalItem.text ?? this.id },
            get icon () { return this.originalItem.icon },
            get deletable () { return this.originalItem.deletable ?? true },
            get onClick () {
              const onClick = this.originalItem.onClick
              return onClick && (() => !vm.ignoreClick && onClick())
            },
            get value () { return this.originalItem.value },
            order,
            get height () { return vm.itemHeight },
            get top () { return getItemTop(this.order) },
            get screenTop () { return getItemScreenTop(this.order) },
            dragTop: null,
            get hidden () { return this.originalGroup?.hidden ?? false },
          }
        })

        if (!Array.isArray(this.value)) {
          this.cItems = mapItems(this.value.items)
          return
        }

        const getIsEmpty = (group: CGroup) => this.sortedItems[group.order + 1]?.type !== 'item'

        this.cItems = this.value.flatMap<CGroup | CItem>(group => {
          const cGroup: CGroup = {
            type: 'group',
            originalGroup: group,
            get id () { return `group-${this.originalGroup.id}` },
            get text () { return this.originalGroup.text ?? this.originalGroup.id },
            get emptyPlaceholderText () { return this.originalGroup.emptyPlaceholderText },
            order: 0,
            get hidden () { return this.originalGroup.hidden ?? false },
            get hideLabel () { return this.originalGroup.hideLabel },
            get isEmpty () { return getIsEmpty(this) },
            get height () { return vm.itemHeight },
            get top () { return getItemTop(this.order) },
            get screenTop () { return getItemScreenTop(this.order) },
            dragTop: null,
          }

          return [
            cGroup,
            ...mapItems(group.items, group),
          ]
        }).map((item, order) => {
          item.order = order
          return item
        })
      },
      { immediate: true },
    )

    this.$watch(
      () => this.draggedItem,
      (item, oldItem) => {
        if (item) {
          if (!oldItem) {
            window.addEventListener('mousemove', this.onDragMove)
            window.addEventListener('mouseup', this.onDragStop)
          }
        } else {
          window.removeEventListener('mousemove', this.onDragMove)
          window.removeEventListener('mouseup', this.onDragStop)
        }
      },
    )

    this.$watch(
      () => [ this.draggedItem, this.dragTop, this.minDragTop, this.maxDragTop ] as const,
      ([ draggedItem, dragTop, minDragTop, maxDragTop ]) => {
        if (!draggedItem) return

        draggedItem.dragTop = dragTop

        if (dragTop < minDragTop) draggedItem.dragTop = minDragTop
        else if (dragTop > maxDragTop) draggedItem.dragTop = maxDragTop

        const oldOrder = draggedItem.order
        let newOrder = oldOrder

        if (dragTop < draggedItem.top) {
          for (const item of this.sortedItems) {
            if (dragTop <= item.top) {
              newOrder = item.order
              break
            }
          }
        } else {
          for (const item of this.sortedItems) {
            if (item.order <= draggedItem.order) continue
            if (dragTop >= item.top) newOrder = item.order
            else break
          }
        }

        if (this.isGrouped) newOrder = Math.max(newOrder, 1)

        if (newOrder !== oldOrder) {
          const items = this.sortedItems.slice()
          items.splice(newOrder, 0, ...items.splice(oldOrder, 1))
          for (const [ index, item ] of items.entries()) item.order = index
        }
      },
    )
  }

  mounted () {
    watchRect(this.wrapperEl, this.onWrapperRectChanged)

    // const itemElUnwatchers: (() => void)[] = []

    this.$watch(
      () => this.cItems,
      () => {
        // for (const unwatch of itemElUnwatchers.splice(0)) unwatch()

        this.disableTransitions = true

        this.$nextTick(() => {
          // this.itemEls = [ ...this.wrapperEl.children ] as HTMLElement[]

          // for (const [ index, el ] of this.itemEls.entries()) {
          //   const item = this.cItems[index]
          //   const { unwatch } = watchRect(el, rect => item.height = rect.height)
          //   itemElUnwatchers.push(unwatch)
          // }

          requestAnimationFrame(() => this.disableTransitions = false)
        })
      },
      { immediate: true },
    )
  }

  beforeDestroy () {
    unwatchRect(this.wrapperEl, this.onWrapperRectChanged)

    this.cItems = []
    window.removeEventListener('mousemove', this.onDragMove)
    window.removeEventListener('mouseup', this.onDragStop)
  }

  onWrapperRectChanged (rect: DOMRect) {
    this.wrapperRect = rect
  }

  onDragStart (item: CItem, e: MouseEvent) {
    this.draggedItem = item
    this.initialWrapperTop = this.wrapperRect?.top ?? 0
    this.initialMouseY = e.clientY
    this.initialDraggedItemTop = item.top
    this.mouseY = e.clientY
    this.$emit('dragStart', item, e)
  }

  onDragMove (e: MouseEvent) {
    if (!this.draggedItem) return

    this.ignoreClick = true

    this.mouseY = e.clientY
  }

  onDragStop () {
    if (!this.draggedItem) return

    const item = this.draggedItem

    const draggedItemOrder = item.order
    const draggedItemIndex = this.cItems.indexOf(item)
    const moved = draggedItemOrder !== draggedItemIndex

    for (const item of this.cItems) {
      item.dragTop = null
    }

    this.draggedItem = null

    if (moved) this.updateItems()

    this.$emit('dragStop')

    requestAnimationFrame(() => {
      this.ignoreClick = false
    })

    if (moved) {
      let cancelUpdate = false

      const unwatch = this.$watch(
        () => [ this.cItems, this.draggedItem ],
        () => cancelUpdate = true,
      )

      setTimeout(() => {
        unwatch()
        if (cancelUpdate) return
        this.cItems = this.sortedItems.slice()
      }, 500)
    }
  }

  deleteItem (item: CItem) {
    const index = this.cItems.indexOf(item)
    if (index === -1) return

    this.cItems.splice(index, 1)
    this.cItems = this.sortedItems.slice()
    this.updateItems()
  }

  updateItems () {
    const value = this.value

    if (Array.isArray(value)) {
      const newValue: typeof value = []

      let currentCGroup: CGroup | null = null
      let currentGroupCItems: CItem[] = []

      const startGroup = (cGroup: CGroup) => {
        currentCGroup = cGroup
        currentGroupCItems = []
      }

      const endGroup = () => {
        if (currentCGroup) {
          const newGroup: Group = {
            ...currentCGroup.originalGroup,
            items: currentGroupCItems.map(item => item.originalItem),
          }

          currentCGroup.originalGroup = newGroup

          for (const item of currentGroupCItems) {
            item.originalGroup = newGroup
          }

          newValue.push(newGroup)
        }
      }

      for (const item of this.sortedItems) {
        if (item.type === 'group') {
          endGroup()
          startGroup(item)
          continue
        }

        currentGroupCItems.push(item)
      }

      endGroup()

      this.currentValue = newValue
      this.$emit('change', newValue)
    } else {
      const newValue: typeof value = { items: [] }

      for (const item of this.sortedItems) {
        if (item.type === 'item') newValue.items.push(item.originalItem)
      }

      this.currentValue = newValue
      this.$emit('change', newValue)
    }
  }
}
</script>

<template>
  <div
    :class="[
      'lassox-portal__Draggables',
      readOnly && 'lassox-portal__Draggables--read-only',
    ]"
  >
    <div
      ref="wrapperEl"
      class="lassox-portal__Draggables-wrapper"
      :style="{
        height: `${wrapperHeight}px`,
        margin: cItems.length ? '-8px' : '',
      }"
    >
      <template v-for="(item) in cItems">
        <div
          v-if="item.type === 'group' && !item.hidden && !item.hideLabel"
          :key="`group-${item.id}`"
          class="lassox-portal__Draggables-group"
          :style="{
            transform: `translateY(${item.dragTop !== null ? item.dragTop : item.top}px)`,
            transition: disableTransitions ? 'none' : '',
          }"
        >
          <span v-if="(showOnlyGroupOnDrag && draggedItem) || !showOnlyGroupOnDrag && item.text">{{ item.text }}</span>

          <div
            class="lassox-portal__Draggables-group-empty"
            :class="{ 'lassox-portal__Draggables-group-empty--hidden': !item.isEmpty }"
            :style="`transform: translateY(${spacing}px)`"
          >
            {{ item.emptyPlaceholderText ? item.emptyPlaceholderText : `Træk hertil for at tilføje til '${item.text}'` }}
          </div>
        </div>

        <LassoDraggable
          v-else-if="item.type === 'item' && !item.hidden"
          :key="`item-${item.id}`"
          :text="item.text"
          :icon="item.icon"
          :id="item.id"
          :draggable="!readOnly"
          :clickable="!!item.onClick"
          :deletable="item.deletable"
          :dragging="item === draggedItem"
          :style="{
            transform: `translateY(${item.dragTop !== null ? item.dragTop : item.top}px)`,
            transition: disableTransitions ? 'none' : '',
          }"
          @mousedown="e => onDragStart(item, e)"
          @click="() => item.onClick && item.onClick()"
          @deleteClicked="() => deleteItem(item)"
        >
          <template #leading="value">
            <slot
              name="leading"
              v-bind="{ value, item }"
            />
          </template>

          <template #content="value">
            <slot
              name="content"
              v-bind="value"
            />
          </template>
        </LassoDraggable>
      </template>
    </div>
  </div>
</template>

<style lang="scss">
@import '@shared/style/global.scss';

.lassox-portal__Draggables {
  display: flex;
  flex-direction: column;
  user-select: none;

  &-wrapper {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height .2s ease-in-out;

    & > .lassox-portal__Draggable {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;

      // margin: 0 -4px;

      // &:first-child {
      //   margin-top: -4px;
      // }

      // &:last-child {
      //   margin-bottom: -4px;
      // }
    }
  }

  &-group {
    @include typography('body2');
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    min-height: 32px;
    padding: 8px;
    white-space: nowrap;
    text-align: center;
    color: #B7B7B7;
    transition: transform 100ms;

    & .lassox-portal__Draggables-group-empty {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      height: 32px;
      // border: 2px dashed #F1F1F1;
      // border: 1px dashed #EFEFEF;
      // border: 2px dotted #EFEFEF;
      border-radius: 8px;
      text-align: center;
      font-size: 12px;
      color: #D4D4D4;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: opacity 100ms;
      opacity: 1;

      &.lassox-portal__Draggables-group-empty--hidden {
        // display: none;
        opacity: 0;
      }
    }

    &:before,
    &:after {
      content: '';
      flex: 1;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      border-bottom-color: #EFEFEF;
    }

    &:has(> span) {
      &:before {
        margin-right: 8px;
      }

      &:after {
        margin-left: 8px;
      }
    }
  }

  // &:hover &-group:not(:first-child) {
  //   &:before,
  //   &:after {
  //     border-bottom-style: dashed !important;
  //   }
  // }
}
</style>
