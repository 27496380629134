<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import BaseButton from './BaseButton.vue'
import Icon from '@/components/Icon.vue'

const sizes = {
  small: {
    iconSize: 18,
    buttonSize: 32,
  },
  normal: {
    iconSize: 24,
    buttonSize: 40,
  },
  large: {
    iconSize: 36,
    buttonSize: 48,
  }
}

@Component({
  components: {
    BaseButton,
    Icon,
  },
})

export default class IconButton extends Vue {
  @Prop({ type: String, required: true })
  readonly icon!: string

  @Prop({ type: String, default: 'small', validator: (preset: string) => preset in sizes })
  readonly size!: keyof typeof sizes

  @Prop({ type: Number })
  readonly iconSize?: number

  @Prop({ type: Number })
  readonly buttonSize?: number

  @Prop(String)
  readonly buttonColor?: 'peach' | 'default' | 'solid-peach'

  @Prop({ type: String, validator: (type: string) => [ 'solid', 'transparent' ].includes(type), default: 'transparent' })
  readonly type!: string

  get finalSize () {
    return sizes[this.size]
  }

  get finalIconSize () {
    return this.iconSize ?? this.finalSize.iconSize
  }

  get finalButtonSize () {
    return this.buttonSize ?? this.finalSize.buttonSize
  }
}
</script>

<template>
  <div
    class="lassox-portal__IconButton"
    :style="{
      width: `${finalIconSize}px`,
      height: `${finalIconSize}px`,
    }"
  >
    <BaseButton
      class="lassox-portal__IconButton_button"
      :style="{
        width: `${finalButtonSize}px`,
        height: `${finalButtonSize}px`,
      }"
      :buttonColor="buttonColor"
      v-on="$listeners"
      :class="[type === 'solid' ? 'lassox-portal__IconButton--type-solid' : 'lassox-portal__IconButton--type-transparent']"
    >
      <Icon
        class="lassox-portal__IconButton_button-icon"
        :icon="icon"
        :style="{
          width: `${finalIconSize}px`,
          height: `${finalIconSize}px`,
        }"
      />
    </BaseButton>
  </div>
</template>

<style lang="scss">
.lassox-portal__IconButton {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;

  &_button {
    flex-grow: 0;
    flex-shrink: 0;
    border-radius: 50%;

    &-icon {
      margin: auto;

      &.md-icon > svg {
        width: inherit !important;
        height: inherit !important;
      }
    }

    &.lassox-portal__IconButton--type-solid {
      background-color: var(--dark-peach);
      .lassox-portal__IconButton_button-icon {
        color: white !important;
      }
    }
  }

  .design2024 &_button {
    background-color: initial;
    background-image: linear-gradient(180deg, #ffa07a, #ff8e61);
    transition-property: color, opacity, transform;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0, 0, .2, 1);
    will-change: color, opacity, transform;

    &:hover {
      transform: scale(0.9);
    }
  }

}
</style>
