<script lang="ts">
import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import InputBase from './InputBase.vue'

@Component({
  components: {
    InputBase,
  },
})
export default class TextInput extends Vue {
  @Ref() readonly input!: HTMLInputElement

  @Prop({ type: String, required: false }) readonly label: string | undefined
  @Prop({ type: Boolean, default: () => true }) readonly floatingLabel!: boolean

  @Prop({ type: String, required: false }) readonly leadingIcon: string | undefined
  @Prop({ type: String, required: false }) readonly trailingIcon: string | undefined
  @Prop({ type: Function, required: false }) readonly trailingIconOnClick: Function | undefined

  @Prop({ type: String, required: false }) readonly value: string | undefined

  @Prop({ type: String, required: false }) readonly helperText: string | undefined
  @Prop({ type: String, required: false }) readonly errorText: string | undefined

  @Prop({ type: Number, required: false }) readonly rows: number | undefined
  @Prop({ type: String, required: false }) readonly placeholder: string | undefined

  isFocused: boolean = false
}
</script>

<template>
  <InputBase
    class="text-input"

    :label="label"
    :floatingLabel="floatingLabel"

    :leadingIcon="leadingIcon"
    :trailingIcon="trailingIcon"
    :trailingIconOnClick="trailingIconOnClick"

    :helperText="helperText"
    :errorText="errorText"

    :isFocused="isFocused"
    :hasValue="!!value"
  >
    <textarea
      v-if="rows && rows > 1"
      ref="input"
      class="input"
      type="text"
      :value="value"
      :rows="rows"
      @input="$emit('input', $event.target.value)"
      @focus="isFocused = true"
      @blur="isFocused = false"
      />
      <input
      v-else
      ref="input"
      class="input"
      :placeholder="placeholder"
      type="text"
      :value="value"
      @input="$emit('input', $event.target.value)"
      @focus="isFocused = true; $emit('focus', $event.target.value)"
      @blur="isFocused = false; $emit('blue', $event.target.value)"
      @change="$emit('change', $event.target.value)"
      @keydown="$emit('keydown', $event)"
     />
  </InputBase>
</template>

<style lang="scss" scoped>
@import "@shared/style/global.scss";

.text-input {
  .input {
    display: block;
    width: 100%;
    border: 0;
    margin: 0;
    color: inherit;
    background-color: transparent;

    &:focus {
      outline: none;
    }
  }

  textarea {
    resize: none
  }
}
</style>
