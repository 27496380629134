<script setup lang="ts">
import { store } from '@/assets/js/store'
import { User } from '@/types/User'
import TextInput from '@shared/vue-lasso-core/components/TextInput.vue'
import { Ref, ref } from 'vue'
import Button from '../Button.vue'
import CompanyField, { SearchCompany } from '../CompanyField.vue'
import axios from '@/axios'
import trialApi from '@/util/trialApi'
import { EventBus } from '@/assets/js/helpers'

const user: Ref<User> = ref(store.state.user)

const intialCompany = { name: store.state.user.organization.name, lassoId: store.state.user.organization.lassoId }
const searchCompany: Ref<typeof intialCompany |  SearchCompany> = ref(intialCompany)

const emit = defineEmits([ 'close' ])

const showConfirm = ref(false)

const requestContact = async () => {
  EventBus.$emit('june:trackEvent', { event: 'Contact Me clicked', traits: { trigger: 'premium feature' } })
  await trialApi.requestContact(user.value, searchCompany.value.lassoId)
  showConfirm.value = true
  setTimeout(() => { emit('close') }, 3000)
}

const openLink = (url: string, event: string) =>  {
  EventBus.$emit('june:trackEvent', { event })
  open(url, '_blank')
  emit('close')
}
</script>

<template>
  <section class="post-trial-overlay-root post-trial-overlay">
    <Transition mode="out-in">
      <div
        v-if="!showConfirm"
        class="post-trial-overlay"
      >
        <h1 class="title typography-title">
          Få fuld adgang igen
        </h1>
        <p class="subtext typography-headline">
          Ønsker du at få fuld adgang til Lasso igen, fortæller vi gerne om dine muligheder.<br>
          Vælg selv om vi skal kontakte dig, eller book et møde, når det passer i din kalender:
        </p>
        <form class="contact-form">
          <TextInput
            label="Navn"
            v-model="user.name"
          />
          <TextInput
            label="E-mail"
            v-model="user.email"
          />
          <TextInput
            label="Tlf-nr."
            v-model="user.organization.contactPhone"
          />
          <CompanyField
            v-model="searchCompany"
            label="Firma"
            name="job-company"
          />
          <div class="trial-options">
            <Button
              label="Jeg vil gerne kontaktes"
              type="primary"
              class="wide-button typography-headline"
              :disabled="!user.organization.contactPhone || user.organization.contactPhone.length < 8"
              @click.prevent="requestContact"
            />
            <p class="typography-headline">
              eller
            </p>
            <Button
              label="Book et møde her"
              type="outlined-primary"
              class="wide-button typography-headline "
              :disabled="!user.organization.contactPhone || user.organization.contactPhone.length < 8"
              @click.prevent="() => { openLink('https://lassox.com/book-demo', 'Book demo page opened'); }"
            />
          </div>
        </form>
      </div>
      <h1
        v-else
        class="title typography-title"
      >
        Tak! Du hører fra os
      </h1>
    </Transition>
  </section>
</template>

<style scoped>
.post-trial-overlay-root {
  padding-block: 64px;
  padding-inline: 32px;
}

.post-trial-overlay {
  min-height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.subtext {
  text-align: center;
  margin-top: 8px;
}

.contact-form {

  margin-top: 70px;
  max-width: 400px;
  width: 100%;
  flex-direction: column;
  display: flex;
  gap: 32px;
}

.trial-options {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.wide-button {
  width: 100%;
}
</style>
