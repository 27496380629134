<script>
import { EventBus } from '@/assets/js/helpers.js'
export default {
  name: 'NewsLoadFooter',
  props: {
    news: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
    }
  },
  computed: {
    label: function () {
      return this.loading ? (this.translate('loading-more-news') + '..') : this.news.endReached ? this.translate('no-more-news') : this.translate('show-more-news')
    }
  },
  methods: {
    // load: function () {
    //   EventBus.$emit('loadNews')
    // }
  }
}
</script>

<template>
  <div
    class="news-load-footer width-constraint"
    :class="{ 'end-reached': news.endReached }"
  >
    <hr>
    <div
      v-show="news.items.length"
      class="content-box"
      @click="$emit('load-more')"
    >
      {{ label.toUpperCase() }}
    </div>
    <hr>
  </div>
</template>

<style lang="less" scoped>
@import '@style/utilities/mixin';
  .news-load-footer {
    padding: 50px 5px 0 !important;
    display: flex;
    align-items: center;
    .min-mediaquery(@sm, {
      padding: 50px 15px 0 15px !important;
    });

    hr {
      flex: 1;
      margin: 0;
      border-color: #e2e2e2;
    }
    div.content-box {
      border: 1px solid #e2e2e2;
      min-width: 320px;
      cursor: pointer;
      text-align: center;
      margin: 0 15px;
      font-weight: bold;
      font-size: 11px;
      line-height: 35px;
      color: #ffa07a;
      .min-mediaquery(@sm, {
        margin: 0 30px;
      });
    }

    &.end-reached {
      div.content-box {
        border: none;
        cursor: auto;
        color: #777;
        &:hover {
          color: #777;
        }
      }
    }
  }

  #app.xxs {
    div.content-box {
      min-width: 200px;
    }
  }
</style>
