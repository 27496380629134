<script lang="ts">
import { Vue, Component, Prop, Model, Watch } from 'vue-property-decorator'
import SearchBar from './SearchBar.vue'
import SearchBarSuggestion from './SearchBarSuggestion.vue'
import { apiUrl } from '@/assets/js/helpers.js'

type EntitySearchResponse = Record<'companies' | 'people', {
  results: EntitySearchResult[]
} | undefined>

interface EntitySearchResult {
  score: number
  type: "company" | "person"
  status?: string
  lassoId: string
  name: string
  address1: string | null
  postalCode: number | null
  city: string | null
}

@Component({
  components: {
    SearchBar,
    SearchBarSuggestion,
  },

  inheritAttrs: false,
})

export default class EntitySearchBar extends Vue {
  @Prop({ type: String })
  readonly type?: 'all' | 'company' | 'person'
  @Prop({ type: Function, required: false })
  readonly excludeFilter?: (v: EntitySearchResult) => EntitySearchResult[]
  @Prop({ type: Boolean, default: false })
  readonly keepSuggestionsOpen!: boolean
  @Prop({ type: Function, required: false })
  readonly onFilterClick?: () => void

  @Model('change', { type: String }) modelInputText!: string
  // @Model('change', { type: Boolean }) readonly includeInactive!: boolean

  inputText = ''

  isIncludingInactive = false

  activeCompanyStatuses: string[] = [
    'aktiv',
    'normal',
    'slettes',
    'underkonkurs',
    'underreassumering',
    'underrekonstruktion',
    'undertvangsopløsning',
  ]

  getClasses (entity: EntitySearchResult) {
    if (entity.type !== "company") return undefined
    if (!entity.status || typeof entity.status !== "string") return undefined

    if (!this.activeCompanyStatuses.includes(entity.status.toLowerCase())) {
      return [ 'inactive' ]
    }
    return undefined
  }

  formatAddress (entity: EntitySearchResult) {
    let address = entity.address1 ?? ''

    const postalCode = entity.postalCode?.toString() ?? ''
    const city = entity.city ?? ''

    if (postalCode || city) {
      if (address) address += ','

      if (postalCode) {
        if (address) address += ' '
        address += `${postalCode}`
      }

      if (city) {
        if (address) address += ' '
        address += city
      }
    }

    return address || 'Ingen adresse registreret i CVR'
  }

  @Watch('inputText', { immediate: true })
  onInputTextChanged () {
    this.$emit('change', this.inputText)
  }

  // @Watch('includeInactive', { immediate: true })
  // onIncludeActiveValueChanged (newVal: boolean) {
  //   this.isIncludingInactive = newVal
  // }
}
</script>

<template>
  <SearchBar
    class="lassox-portal__EntitySearchBar"
    ref="searchBar"
    v-bind="$attrs"
    v-model="inputText"
    :keepSuggestionsOpen="keepSuggestionsOpen"
    :excludeFilter="excludeFilter"
    :debounce="true"
    :suggestionsMaxHeight="48 * 5"
    :onFilterClick="onFilterClick"
    v-on="$listeners"
  >
    <template
      v-for="(_, slot) in $slots"
      #[slot]
    >
      <slot :name="slot" />
    </template>
  </SearchBar>
</template>

<style lang="scss" scoped>
@import '@shared/style/global.scss';
.lassox-portal__EntitySearchBar {
  &::v-deep {
    .lassox-portal__SearchBar-suggestions {
      .lassox-portal__SearchBar-suggestion {
        .lassox-portal__SearchBarSuggestion.inactive {
          color: getScaleColor('gray', 500);
        }
      }
    }
  }
}
</style>
