<script>
import { debounce, values } from 'lodash'
import Icon from '@/components/Icon.vue'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Notifications',
  props: {
    visible: Boolean,
    notifications: {
      type: Array,
      required: true
    },
    noMore: Boolean
  },
  data () {
    return {
      onScrolled: debounce(this.scrolled, 300)
    }
  },
  computed: {
    updates () {
      return values(this.$store.updates)
    }
  },
  methods: {
    scrolled () {
      if (this.$refs.scrollable.scrollHeight - this.$refs.scrollable.scrollTop < this.$refs.scrollable.clientHeight + 10) this.$emit('loadMore')
    },
    refreshScrollbar () {
      window.setTimeout(() => {
        this.$vuebar.destroyScrollbar(this.$refs['scroll'])
        this.$nextTick(() => this.$vuebar.initScrollbar(this.$refs['scroll'], {}))
      }, 0)
    },
    notificationClick (notification) {
      this.$emit('notificationClick')
      if (notification.type === 'ExportCompletion') this.$store.commit('openNotification', notification)
      // Hacky, but Emil says there's no better way to distinguish the tutorial link
      if (this.$store.getters.isFreemium && notification.url && notification.url.includes('velkommen-til-lasso.pdf')) {
        let widget = window.groove ? Object.values(window.groove.loadedWidgets)[0] : null
        widget.replaceRoute('/kb/articles/2024982188')
        if (!widget.isOpen) widget.open()
      } else
        notification.onClick()
    },
    onScanClick (scan) {
      this.$emit('notificationClick')
      this.$store.dispatch('openNewTab', this.$router.resolve({ name: 'entity', params: { lassoId: scan.externalId, appName: 'contacts', appParams: scan, update: true } }).route)
    }
  },
  components: {
    Icon,
  },
  beforeDestroy () {
    if (this.notifications.length) this.$store.dispatch('updateLastNotificationCheck')
  }
}
</script>

<template>
  <div
    id="notifications"
    @click="$event => $emit('click', $event)"
  >
    <!-- <div class="top">
      <span class="header" v-lang.notifications></span>
    </div> -->
    <div
      class="notifications-wrapper dark"
      v-bar
      ref="scroll"
    >
      <div
        @scroll="scrolled"
        ref="scrollable"
        style="max-height: 250px"
      >
        <div
          v-for="scan in $store.getters.websiteScans"
          :key="scan.externalId"
          class="update notification"
          :class="{ unread: scan.unread }"
          @click="onScanClick(scan)"
        >
          <div class="read-indicator">
            <img
              src="@/assets/img/svg/person-search.svg?inline"
              style="height: 16px; width: 16px"
              class="website-scanner"
              alt=""
            >
          </div>
          <div class="description">
            <div class="headline">
              <div v-if="scan.status !== 'finished'">
                Indhenter kontaktpersoner for {{
                  $store.getters.entity(scan.externalId).name }}
              </div>
              <div v-else-if="scan.status === 'finished'">
                <span v-if="scan.data.contacts.length">Kontaktpersoner indhentet for {{
                  $store.getters.entity(scan.externalId).name }}</span>
                <span v-else-if="scan.data.emails.length || scan.data.phoneNumbers.length">Kontaktoplysninger
                  fundet for {{ $store.getters.entity(scan.externalId).name }}</span>
                <span v-else>Ingen kontaktpersoner- eller oplysninger fundet for {{
                  $store.getters.entity(scan.externalId).name }}</span>
              </div>
              <template v-if="scan.status == 'finished'">
                <div
                  v-html="(scan.data.contacts.length || scan.data.emails.length || scan.data.phoneNumbers.length) ? 'Klik for at se dem' : ''"
                />
                <div class="time-since">
                  {{ scan.timestamp | timeSince(translate) }}
                </div>
              </template>
              <div
                v-else
                class="time-since ellipsis"
              >
                {{ scan.path || scan.website.replace('http://',
                                                     '').replace('https://', '').replace('www.', '').replace(/\/$/, '') }}
              </div>
            </div>
          </div>
        </div>
        <div
          v-for="update in $store.getters.updates"
          :key="update.id"
          class="update notification"
          :class="{ unread: update.unread }"
        >
          <div class="read-indicator">
            <img
              :src="null"
              class="export-completion"
              alt=""
            >
          </div>
          <div class="description">
            <div class="headline d-flex justify-content-between align-items-center">
              <div v-html="update.title" />
              <div
                v-if="update.progress != null"
                class="update-progress"
              >
                {{ update.progress }}%
              </div>
            </div>
            <div class="footer-text">
              {{ update.name }}
            </div>
          </div>
        </div>
        <div
          v-for="notification in notifications"
          :key="notification.id"
          @click="notificationClick(notification)"
          class="notification"
          :class="{ unread: notification.unread, unopen: notification.opened === false }"
        >
          <div class="read-indicator">
            <!-- <i class="read-icon fa" :class="notification.icon"></i> -->
            <!-- <div :class="notification.icon"></div> -->
            <Icon
              :icon="notification.unread ? 'notifications_active_outline_rounded' : 'notification_outline_rounded'"
              size="small"
            />
          </div>
          <div class="description">
            <div class="headline">
              <span
                v-if="notification.description.indexOf('translate-') == 0"
                v-html="translate(notification.description.replace('translate-', ''))"
              />
              <span
                v-else
                v-html="notification.description"
              />
              <!-- <span v-if="notification.type !== 'ExportCompletion'">{{notification.description}}</span>
              <span v-else>{{translate('export-in-finished')}} <b>{{translate('export-in-finished-bold')}}</b></span> -->
            </div>
            <div
              class="time-since"
              v-if="notification.type !== 'ExportCompletion' || !notification.meta"
            >
              {{ notification.time | timeSince(translate) }}
            </div>
            <div
              class="time-since"
              v-if="notification.type === 'ExportCompletion' && notification.meta"
              v-html="notification.meta.name"
            />
          </div>
        </div>
        <div
          class="no-more-notifications"
          v-if="noMore"
        >
          <span v-lang.no-more-notifications />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
@import "@oldShared/lasso/style/utilities/mixin";

#notifications {
  display: flex;
  flex-direction: column;
  margin: -8px 0;
  flex: 1 1;
  user-select: none;

  @media only screen and (min-width: 544px) {
    width: 280px;
  }

  .top {
    // flex: 0 0 auto;
    font-size: 12px;
    // height: 10px;
    // border: 1px solid #dfdfdf;
    padding: 8px;
    // border-width: 1px 0;
    cursor: initial;

    .header {
      font-weight: 500;
      color: #222;
    }

    a {
      color: #ffa07a;
    }
  }

  .notifications-wrapper {
    // border-bottom: 1px solid #dfdfdf;
    flex: 1 1 auto;

    .vb-content {
      padding: 8px 0;
    }

    .update {
      font-size: 13px;

      // background-color: #F9F9F9;
      .update-progress {
        font-size: 11px;
        color: #B9B9B9;
      }
    }

    .notification {
      // line-height: 1.428571429;
      display: flex;
      cursor: pointer;

      // color: #000;
      &.unopen {
        .read-indicator {
          div {
            &.export-completion {
              .svg-png-retina('export_completion_red', 10px, 13px)
            }
          }
        }
      }

      &.unread {
        .read-indicator {
          .md-icon {
            color: #ffa07a;
          }
          // &.announcement {
          // 	.svg-png-retina('announcement_red', 13px, 10px)
          // }

          // &.export-completion {
          // 	.svg-png-retina('export_completion_red', 10px, 13px)
          // }

          // &.news {
          // 	.svg-png-retina('news_red', 13px, 12px)
          // }

          // &.patch-note {
          // 	.svg-png-retina('patch_note_red', 11px, 11px)
          // }

          // &.service-message {
          // 	.svg-png-retina('announcement_red', 13px, 10px)
          // }
        }
      }

      &:hover {
        background-color: #F1F1F1;
      }

      &:not(:last-child) {
        border-bottom: 1px solid #dfdfdf;
      }

      .read-indicator {
        min-width: 50px;
        font-size: 16px;
        color: #EBEBEB;
        display: flex;
        align-items: center;
        justify-content: center;

        div {
          &.announcement {
            .svg-png-retina('announcement_black', 13px, 10px)
          }

          &.export-completion {
            .svg-png-retina('export_completion_black', 10px, 13px)
          }

          &.news {
            .svg-png-retina('news_black', 13px, 12px)
          }

          &.patch-note {
            .svg-png-retina('patch_note_black', 11px, 11px)
          }

          &.service-message {
            .svg-png-retina('announcement_black', 13px, 10px)
          }
        }
      }

      .description {
        flex: 1 0;
        min-width: 0; // to make truncated text (ellipsis) work: https://css-tricks.com/flexbox-truncated-text/
        padding: 8px 25px 8px 0;
        color: #616161;

        .headline {
          .title {
            color: #ffa07a;
          }

          font-size: 12px;
          line-height: 16px;
          font-weight: 400;
          margin: 0;
        }

        .time-since {
          margin-top: 5px;
          color: #B7B7B7;
          font-size: 10px;
          line-height: 12px;
          font-weight: 500;
        }

        .footer-text {
          margin-top: 5px;
          color: #B9B9B9;
          font-size: 11px;
          line-height: 13px;
          font-weight: 500;
        }

      }
    }

    .no-more-notifications {
      font-size: 12px;
      text-align: center;
      padding: 8px;
      cursor: initial;
    }
  }
}
</style>
