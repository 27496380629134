export default function buildQueryString (entries: [string, { toString: () => string } | null][]) {
  let result = ''

  for (const [ name, value ] of entries) {
    if (value !== null) {
      result += (
        (!result ? '?' : '&')
        + `${name}=${encodeURIComponent(value.toString())}`
      )
    }
  }

  return result
}
