const viewportEl = document.createElement('div')

Object.assign(viewportEl.style, {
  position: 'fixed',
  top: '0',
  left: '0',
  right: '0',
  bottom: '0',
  zIndex: '-9999',
})

document.body.appendChild(viewportEl)

const getViewport = () => viewportEl.getBoundingClientRect()

export default getViewport
