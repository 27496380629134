<script lang="ts">
import { Vue, Component, Prop, InjectReactive } from 'vue-property-decorator'
import Dialog from '@/components/Dialog.vue'
import TabBar from '@/components/TabBar.vue'
import Draggables from '@/components/Draggables.vue'
import LassoSettings from '../util/LassoSettings'
import Button from '@/components/Button.vue'

type GroupedActions = Record<'company' | 'person' | 'address', { id: 'visible' | 'dropdown' | 'hidden', text: 'Synlige genveje' | 'Genveje i drop-down' | 'Skjulte genveje', items: { id: string, text: string, tag: 'icon' | 'span' | { template: string, data?: () => Record<string, any> }, tagAttrs?: Record<string, any>, deletable: false }[] }[]>

@Component({
  components: {
    Dialog,
    Button,
    TabBar,
    Draggables,
  }
})
export default class ActionsDialog extends Vue {
  @Prop(Object)
  readonly groupedActions!: GroupedActions
  @Prop({ type: String, required: false })
  readonly presetActiveTab?: 'company' | 'person' | 'address'
  @Prop(Boolean)
  readonly show!: boolean

  @InjectReactive('lassoSettings')
  lassoSettings!: LassoSettings

  // #region data
  innerShow = false
  tabs = [
    {
      id: 'company',
      label: 'Firmaer'
    },
    {
      id: 'person',
      label: 'Personer'
    },
    {
      id: 'address',
      label: 'Adresser'
    },
  ]
  actionDialogActiveTab: 'company' | 'person' | 'address' = 'company'
  // #endregion

  // #region methods
  closeActions () {
    this.innerShow = false
    this.$emit('close')
  }

  saveActions () {
    // eslint-disable-next-line no-console
    if (!this.lassoSettings) console.warn('Cannot save actions order, no settings available!')

    if (this.groupedActions) {
      const newActionsOrder: Record<'company' | 'person' | 'address', Record<'visible' | 'dropdown' | 'hidden', string[]>> = {
        company: {
          visible: [],
          dropdown: [],
          hidden: [],
        },
        person: {
          visible: [],
          dropdown: [],
          hidden: [],
        },
        address: {
          visible: [],
          dropdown: [],
          hidden: [],
        },
      }

      for (const type of [ 'company', 'person', 'address' ] as const) {
        this.groupedActions[type].forEach(v => {
          newActionsOrder[type][v.id] = v.items.map(i => i.id)
        })
      }

      this.lassoSettings?.updateActionsOrder(newActionsOrder)

      this.lassoSettings?.updateSettings()
    }

    this.closeActions()
  }
  // #endregion

  // #region hooks
  created () {
    this.$watch(() => this.show, state => {
      if (this.presetActiveTab) {
        this.actionDialogActiveTab = this.presetActiveTab
      }
      this.innerShow = state
    }, { immediate: true })
  }
  // #endregion
}
</script>
<template>
  <Dialog
    v-if="innerShow"
    class="actions-modal"
    title="Genveje"
    size="5x"
    dismissible
    scrollable
    :onClose="() => closeActions()"
  >
    <template #content>
      <div>
        <TabBar
          v-model="actionDialogActiveTab"
          :tabs="tabs"
        />

        <template v-if="groupedActions">
          <template v-for="(_, key) in groupedActions">
            <Draggables
              v-if="key === actionDialogActiveTab"
              :key="key"
              style="margin-top: 32px"
              v-model="groupedActions[key]"
            />
          </template>
        </template>
      </div>
    </template>

    <template #actions>
      <div style="width: 100%; display: flex; justify-content: flex-end; gap: 8px">
        <Button
          label="Luk"
          type="outlined"
          @click="closeActions"
        />
        <Button
          label="Gem"
          type="primary"
          @click="saveActions"
        />
      </div>
    </template>
  </Dialog>
</template>
<style lang="scss">
</style>
