<script>
import directives from '@/assets/js/directives.js'
import { EventBus } from '@/assets/js/helpers.js'
export default {
  name: 'SocialButtons',
  props: {
    dropdown: {
      type: Boolean,
      default: false
    },
    url: { type: String, required: true },
    title: { type: String, required: false }
  },
  computed: {
    emailUrl: function () {
      return 'mailto:false?body=' + encodeURIComponent(this.title) + '%0A' + encodeURIComponent(this.url)
    }
  },
  methods: {
    showAlert: function (text) {
      this.$notify({ text })
    }
  },
  directives: {
    'social-sharer': directives.socialSharer,
    'clipboard': directives.clipboard
  },
  components: {
  }
}
</script>

<template>
  <div class="social-buttons">
    <a
      :href="emailUrl"
      target="_blank"
      class="email"
    ><i class="fa fa-envelope-o" /></a>
    <a
      v-social-sharer="{sharer: 'linkedin', url: url }"
      class="linkedin"
    ><i class="fa fa-linkedin" /></a>
    <a
      v-social-sharer="{sharer: 'facebook', url: url }"
      class="facebook"
    ><i class="fa fa-facebook" /></a>
    <a
      v-social-sharer="{sharer: 'twitter', url: url }"
      class="twitter"
    ><i class="fa fa-twitter" /></a>
    <a
      @click="showAlert(translate('address-copied'))"
      v-clipboard
      :data-clipboard-text="url"
      class="link"
    ><i class="fa fa-link" /></a>
  </div>
</template>

<style lang="less">
  .share-dropdown {
    .button-content {
      display: flex;
      align-items: center;
    }
    .dropdown-menu {
      border-radius: 2px;
      padding: 0;
      li {
        a {
          i {
            width: 40px;
            text-align: center;
            color: #b2b2b2;
          }
          padding: 10px 15px 10px 0;
          color: #ffa07a;
        }
        &:not(:first-child) a {
          border-top: 1px solid #ededed;
        }
      }
    }
  }

  .social-buttons {
    a {
      display: inline-block;
      width: 30px;
      height: 30px;
      border-radius: 2px;
      border: none;
      color: #fff !important;
      text-align: center;
      padding-top: 4px;
      cursor: pointer;
      &:not(:last-child) {
        margin-right: 8px;
      }

      &.facebook {
        background-color: #3b5999;
      }

      &.linkedin {
        background-color: #0077b5;
      }

      &.twitter {
        background-color: #55acee;
      }

      &.email {
        background-color: #ffa07a;
      }

      &.link {
        background-color: #404040;
      }
    }
  }
</style>
