export default function formatNumber (number: number | string, decimalCount: number = 0) {
  if (typeof number === 'string') number = parseFloat(number)

  const parts = number.toFixed(decimalCount).split('.')

  return (
    parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    + (parts[1] ? `,${parts[1]}` : '')
  )
}
