import axios from '@/axios'
import { CompaniesResult } from '@/types/EntitySearchResponse'
import normalizeUrl from 'normalize-url'
import Vue from 'vue'

export default Vue.observable<{ cache: Record<string, string>, getFavicon (company: CompaniesResult): Promise<string | null> }>({
  cache: {},
  async getFavicon (company: CompaniesResult) {
    // Check if we have cached favicon, return that if we do
    const cached = this.cache[company.lassoId]
    if (cached) return cached

    // If company has websites to iterate through, do it
    if (company.websites) {
      // Go through each website until we have a favicon. When we do, cache it and return it
      for (const website of company.websites) {
        try {
          const base64 = await axios.get<ArrayBuffer>('utilities/favicon?' + (new URLSearchParams({ domain: normalizeUrl(website, { forceHttp: true }) })).toString(), { responseType: 'arraybuffer' })
            .then(response => {
              return `data:${response.headers['content-type']};base64,${btoa(String.fromCharCode(...new Uint8Array(response.data)))}`
          })

          if (base64) {
            this.cache[company.lassoId] = base64
            return base64
          }
        } catch (error) { continue }
      }
    }
    // There were no good websites, feelsbadman :c
    return null
  }
})
