export default function Deferred<T = void> () {
  let resolve = null as unknown as (value: T | PromiseLike<T>) => void
  let reject = null as unknown as (reason?: any) => void

  const promise = new Promise<T>((_resolve, _reject) => {
    resolve = _resolve
    reject = _reject
  })

  return { promise, resolve, reject }
}
